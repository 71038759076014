const modules = [
    {
        key: "home",
        label: "Início",
        icon: "faHome",
        path: "/",
    },
    {
        key: "inbox",
        label: "Caixa de Entrada",
        icon: "faInbox",
        path: "/inbox",
    },
    {
        key: "myFlows",
        label: "Minhas Solicitações",
        icon: "faSitemap",
        path: "/my-flows",
    },
    {
        key: "myFiles",
        label: "Meus arquivos",
        icon: "faFile",
        path: "/my-files",
    },
    {
        key: "weather",
        label: "Clima",
        icon: "faCloudSunRain",
        path: "/weather",
    },
    {
        key: "calendar",
        label: "Agenda",
        icon: "faCalendar",
        path: "/calendar",
    },
];

export default modules;
export { modules };
