"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _app["default"];
  }
});
var _app = _interopRequireDefault(require("firebase/app"));
require("firebase/storage");
require("firebase/firestore");
require("firebase/auth");
var firebaseConfig = {
  apiKey: 'AIzaSyAuTijrxdZUV7VlhvT_iZYwCsKgYBA5JME',
  authDomain: 'taugor-appcenter.firebaseapp.com',
  databaseURL: 'https://taugor-appcenter.firebaseio.com',
  projectId: 'taugor-appcenter',
  storageBucket: 'taugor-appcenter.appspot.com',
  messagingSenderId: '970445245374',
  appId: '1:970445245374:web:c6ac862d6a43e4e68547ef',
  measurementId: 'G-BTSY0HJ1VY'
};
var firebaseAppcenter = _app["default"].initializeApp(firebaseConfig, 'appcenter');
var _default = firebaseAppcenter;
exports["default"] = _default;