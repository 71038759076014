import styles from "./formDocument.module.scss";
import { useAsyncEffect, useStore } from "@hooks";
import React, { useEffect, useMemo, useState } from "react";

import lacunaPki from "@services/lacunaPki";
import { workfluxApi } from "@apis";
import { history, services } from "@store";
import { dokia as dokiaApi } from "zerocode";
import { downloadFile, formatBytes, generateUid, toDate } from "@functions";
import Icon from "@custom/Icon";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import EntityFieldsForm from "@custom/EntityFieldsForm";
import CertificatesSelector from "@custom/CertificatesSelector";
import i18n, { format } from "@i18n";
import { ButtonLoading } from "components/buttons/buttonLoading";
import { serviceExecutedDocuments } from "@services/executedDocument";

const TASKS_STATUS_VIEW = ["completed", "review", "removed"];

export function FormDocument({ task, clearTask }) {
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [executedDocuments, setExecutedDocuments] = useState([]);
    const [fileDescriptions, setFileDescriptions] = useState([]);
    const [selectedCertificate, setSelectedCertificate] = useState("");
    const [classification, setClassification] = useState({
        uid: "",
        title: "",
        fields: {},
    });
    const [dokiaDocument, setDokiaDocument] = useState({
        meta: {
            code: generateUid(),
            fields: {},
        },
        name: "",
        fields: [],
        size: 0,
    });

    const { auth } = useStore();

    const hasPermission = useMemo(() => {
        return task.agent === auth.user.uid;
    }, []);

    const signatureRequired = useMemo(() => {
        if (!task) return false;

        if (task.action.isSigningRequired) {
            return true;
        }

        return false;
    }, [task]);

    const isMultipleFiles = useMemo(() => {
        return !!task.action.isMultiple;
    }, [task]);

    const dokiaReaction = useMemo(() => {
        if (!task) return "";

        for (const phase of task?.workflowObject?.phases) {
            for (const reaction of phase.reactions) {
                if (reaction.originDocument === task.action.uid) {
                    if (reaction.type.type === "dokia" && !reaction.deleted) {
                        return reaction;
                    }
                }
            }
        }

        return "";
    }, [task]);

    useEffect(() => {
        if (signatureRequired) {
            lacunaPki.init().then(console.log).catch(console.log);
        }
        return () => {};
    }, [signatureRequired]);

    useAsyncEffect(async () => {
        if (!TASKS_STATUS_VIEW.includes(task.status) || !task?.action?.uid)
            return;

        const documentsExecuteds = await serviceExecutedDocuments.findByAction({
            actionUid: task.action.uid,
            executionFlow: task.executionFlow,
        });

        if (typeof documentsExecuteds === "string") {
            setExecutedDocuments([]);
        } else {
            setExecutedDocuments(documentsExecuteds);
        }
    }, [task]);

    /** @param {File} file */
    const sendAndSignDoc = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async (event) => {
                if (typeof event.target.result !== "string") return resolve();

                try {
                    const { data } =
                        await workfluxApi.tasks.signedDocumentInput.start({
                            fileBase64: event.target.result,
                        });

                    const token = await lacunaPki.signWithRestPki({
                        token: data.token,
                        thumbprint: selectedCertificate,
                    });

                    const { mediaLink } =
                        await workfluxApi.tasks.signedDocumentInput
                            .complete({
                                token,
                            })
                            .then((resp) => resp.data);

                    await workfluxApi.tasks.signedDocumentInput.saveReference({
                        task: task,
                        location: mediaLink,
                        ged17Fields: [],
                    });
                } catch (error) {
                    reject(error);
                } finally {
                    resolve();
                }
            };

            reader.readAsDataURL(file);
        });
    };

    const sendFiles = async () => {
        for (const file of files) {
            const index = files.indexOf(file);
            const newTask = {
                ...task,
                currentDocumentDescription: fileDescriptions[index],
            };

            await workfluxApi.tasks.documentInput({
                task: isMultipleFiles ? newTask : task,
                file,
                ged17Fields: [],
            });

            if (dokiaReaction) {
                // @ts-ignore
                file.meta = {
                    fields: dokiaDocument.fields,
                };
                await dokiaApi.document.save({
                    hierarchy: [
                        dokiaReaction.dokiaInfo.organization,
                        dokiaReaction.dokiaInfo.company,
                        dokiaReaction.dokiaInfo.subsidiary,
                        ...dokiaReaction.dokiaInfo.classification,
                    ],
                    file: file,
                    onProgressUpdate: () => {},
                });
            }
        }
    };

    const sendDocument = async () => {
        if (loading) return;
        if (!files?.length) services.toast.error("selecione o arquivo!");

        const successMessage = () => {
            services.message.show({
                title: "Tarefa concluída",
                description: "Sua tarefa foi concluída com sucesso.",
            });
        };

        setLoading(true);
        if (signatureRequired) {
            try {
                for (const file of files) {
                    await sendAndSignDoc(file);
                }
                successMessage();
            } catch (error) {
                services.message.show({
                    title: "Falha ao assinar documento",
                    description:
                        "Não foi possível assinar seu documento. Por favor verifique o certificado",
                });
            }
        } else {
            try {
                await sendFiles();
                successMessage();
            } catch (error) {
                services.message.show({
                    title: "Falha ao enviar documento",
                    description:
                        "Não foi possível enviar seu documento. Por favor entre em contato com suporte.",
                });
            }
        }

        await services.tasks.updateTask({
            taskUid: task?.uid,
        });
        await services.tasks.list({
            company: auth.company.uid,
            user: auth.user.uid,
        });

        setLoading(false);

        history.push("/inbox/");
        clearTask();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (loading) return;

        if (event.dataTransfer.items) {
            const transferFiles = [...event?.dataTransfer?.items]
                .map((item) => {
                    if (item.kind === "file") {
                        const file = item.getAsFile();
                        return file;
                    }
                    return null;
                })
                .filter((item) => item instanceof File);

            setFiles(isMultipleFiles ? transferFiles : transferFiles[0]);
        } else {
            const dataFiles = event?.dataTransfer?.files;
            setFiles(isMultipleFiles ? dataFiles : dataFiles[0]);
        }
    };

    const handleUpdateEntity = (entity) => (updates) => {
        setClassification((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [entity.uid]: { ...entity, ...updates },
            },
        }));
    };

    const handleDeleteFile = (filename) => {
        const newFiles = files.filter((file) => file.name !== filename);
        setFiles(newFiles);
    };

    const handleDownloadFile = async (document) => {
        services.spinner.show();
        const defaultFilename = generateUid("DOC-");
        const filename = document?.action?.instructions || defaultFilename;
        const [fileUrl] = document?.location.split("?");

        const parts = fileUrl.split(".");
        const extension = parts[parts.length - 1];

        try {
            const { url } = await workfluxApi.document.open(document.uid);
            await downloadFile(url, `${filename}.${extension}`);
        } catch (err) {
            console.error("err", err);
            services.message.error({
                title: "Erro ao abrir documento",
                description: err.message,
            });
        } finally {
            services.spinner.hide();
        }
    };

    useAsyncEffect(async () => {
        if (!dokiaReaction) return;
        const { classification: classificationDokia } = dokiaReaction.dokiaInfo;
        const { uid } = classificationDokia[classificationDokia.length - 1];

        const classificationResult = await dokiaApi.oi.classification.get(uid);

        setClassification(classificationResult);
        setDokiaDocument((prevState) => ({
            ...prevState,
            fields: classificationResult.fields,
        }));
    }, [dokiaReaction]);

    if (TASKS_STATUS_VIEW.includes(task.status)) {
        return (
            <>
                {executedDocuments.map((executedDocument) => {
                    return (
                        <div className={styles.ExecutedDocument}>
                            <div>
                                <p>
                                    <strong>Nome: </strong>
                                    {executedDocument.action.instructions}
                                </p>
                                <p>
                                    <strong>Descrição: </strong>
                                    {
                                        executedDocument.action
                                            .documentInputDescription
                                    }
                                </p>
                                <p>
                                    <strong>Criado por: </strong>
                                    {executedDocument?.action?.agentName}
                                </p>
                                <p>
                                    <strong>Envio realizado em: </strong>
                                    {format.datetime(
                                        toDate(executedDocument.createdAt)
                                    )}
                                </p>
                            </div>

                            <div className={styles.buttons}>
                                <a
                                    href={executedDocument.location}
                                    target="_blank"
                                >
                                    <Button variant="contained" size="small">
                                        <Icon icon="faEye" />
                                    </Button>
                                </a>

                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() =>
                                        handleDownloadFile(executedDocument)
                                    }
                                >
                                    <Icon icon="faDownload" />
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    if (!hasPermission) {
        return (
            <div className={styles.TaskDocument}>
                <p>Você não pode enviar este documento.</p>
            </div>
        );
    }

    return (
        <div className={styles.TaskDocument}>
            <div
                className={styles.DropZone}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
            >
                <p>
                    {i18n("Arraste e solte, ou")}
                    <input
                        type="file"
                        id="select-file"
                        multiple={isMultipleFiles}
                        onChange={(event) => {
                            if (loading) return;
                            setFiles(Array.from(event.target.files));
                        }}
                    />
                    <label htmlFor="select-file">
                        {" "}
                        {i18n("escolha o arquivo")}
                    </label>{" "}
                    {i18n("para upload")}
                </p>
            </div>

            {!!files?.length &&
                files.map((file, index) => (
                    <div className={styles.File}>
                        <div className={styles.FileTop}>
                            <div className={styles.FileTopLeft}>
                                <Icon icon="faFile" />
                                <div>
                                    <p>{file.name}</p>
                                    <strong>
                                        {formatBytes(file.size, "bytes")}
                                    </strong>
                                </div>
                            </div>

                            <div
                                className={styles.FileTopRight}
                                onClick={() => handleDeleteFile(file.name)}
                            >
                                <Icon icon="faX" />
                            </div>
                        </div>

                        {task?.action?.isMultiple && (
                            <TextField
                                label={`Descrição de Envio do arquivo '${file.name}'`}
                                onChange={(e) => {
                                    let descriptions = fileDescriptions;
                                    descriptions[index] = e.target.value;
                                    setFileDescriptions(descriptions);
                                }}
                            />
                        )}
                    </div>
                ))}

            {!!task?.action?.isMultiple && !!files?.length && (
                <FormControlLabel
                    style={{ width: "100%" }}
                    label={"Concluir atividade, no envio"}
                    className="checkbox-control"
                    control={
                        <Checkbox
                            checked={task.finishUpload}
                            onChange={(e) => {
                                task.finishUpload = e.target.checked;
                            }}
                        />
                    }
                />
            )}

            {dokiaReaction && !!classification.uid && (
                <div>
                    <h5>{classification.title} </h5>
                    {Object.values(classification.fields).map((entity) => {
                        return (
                            <EntityFieldsForm
                                document={dokiaDocument}
                                entity={entity}
                                handleUpdateEntity={handleUpdateEntity(entity)}
                            />
                        );
                    })}
                </div>
            )}

            {signatureRequired && (
                <CertificatesSelector
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                />
            )}

            {hasPermission && (
                <ButtonLoading
                    loading={loading}
                    disabled={!files?.length || loading}
                    startIcon={!loading && <Icon icon="faUpload" />}
                    variant="contained"
                    onClick={sendDocument}
                >
                    {i18n("Enviar documento")}
                </ButtonLoading>
            )}
        </div>
    );
}
