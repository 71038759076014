import React from "react";

import Topbar from "@custom/Topbar";
import NewsList from "./NewsList";
import Workflows from "./Workflows";

import { services } from "@store";
import { useAsyncEffect, useStore } from "@hooks";

import styles from "./styles.module.scss";
// import { QuickActions } from "./components/quickActions";

function Home({ match }) {
    const { auth, news } = useStore();
    /** @type {string} */
    const openNewsUid = match.params.newsUid;

    useAsyncEffect(async () => {
        if (
            !auth.company?.uid ||
            !auth.user?.uid ||
            !auth.isAuthenticated ||
            !!news.length
        )
            return;

        services.spinner.show();

        await services.news.list({ company: auth.company.uid });
        await services.workflows.list({ company: auth.company.uid });

        services.spinner.hide();
    }, [auth.company, auth.user]);

    return (
        <div className={styles.Home}>
            <Topbar />
            <NewsList openNewsUid={openNewsUid} />
            {/* <QuickActions /> */}
            <Workflows />
        </div>
    );
}

export default Home;
