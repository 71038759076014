import { createExecutedDocument } from "@models";
import { executedDocumentCollection } from "@firebase-config/collections";

/**
 * @typedef ExecutedDocument
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * Function to listen for changes in all documents of company
 * @param {{
 *      list: ExecutedDocument[];
 *      callback: (documents: ExecutedDocument[]) => void;
 * }} params
 */
export function watchExecutedDocuments({ list, callback }) {
    const executedDocuments = [];

    for (const item of list) {
        executedDocumentCollection.doc(item?.uid).onSnapshot((snapshot) => {
            const executedDocument = createExecutedDocument(snapshot.data());
            executedDocuments.push(executedDocument);
            callback([executedDocument]);
        });
    }

    callback(executedDocuments);
}
