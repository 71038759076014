// @ts-nocheck
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import Thunk from "redux-thunk";

/**
 * @typedef Params
 * @type {{
 *     company: string;
 *     user?: string;
 *     group?: string;
 *     lastDoc?: string;
 *     limit?: number;
 *     groupsParticipant?: string[]
 *     userGroups?: any
 * }}
 */

import auth from "./auth";
import spinner from "./spinner";
import pageSpinner from "./pageSpinner";
import message from "./message";
import modal from "./modal";
import language from "./language";
import news from "./news";
import documents from "./documents";
import workflows from "./workflows";
import executionFlows from "./executionFlows";
import toast from "./toast";
import tasks from "./tasks";
import ipLocal from "./ipLocal";
import reset from "./reset";
import companyArea from './companyArea'
import filters from './filterTaskDateAndType'
import theme from "./theme"

const RESET_STORE = "RESET_STORE_REDUX";

const reducers = {
    auth: auth.reducer,
    spinner: spinner.reducer,
    pageSpinner: pageSpinner.reducer,
    message: message.reducer,
    modal: modal.reducer,
    language: language.reducer,
    news: news.reducer,
    documents: documents.reducer,
    workflows: workflows.reducer,
    companyArea: companyArea.reducer,
    filters: filters.reducer,
    tasks: tasks.reducer,
    executionFlows: executionFlows.reducer,
    toast: toast.reducer,
    ipLocal: ipLocal.reducer,
    theme: theme.reducer
};

const history = createBrowserHistory();
reducers.router = connectRouter(history);

const initialState = {};
const createReducers = (asyncReducers) => {
    return combineReducers({
        ...reducers,
        ...asyncReducers,
    });
};

const rootReducer = (state, action) => {
    const appReducer = createReducers();

    if (action.type === RESET_STORE) {
        return appReducer(initialState, action);
    }

    return appReducer(state, action);
};

const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(Thunk, routerMiddleware(history)))
);

store.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducers(store.asyncReducers));
};

const { dispatch, getState } = store;

const services = {
    auth: auth.service(dispatch, getState),
    spinner: spinner.service(dispatch),
    pageSpinner: pageSpinner.service(dispatch),
    message: message.service(dispatch),
    modal: modal.service(dispatch),
    language: language.service(dispatch),
    news: news.service(dispatch),
    documents: documents.service(dispatch),
    workflows: workflows.service(dispatch),
    companyArea: companyArea.service(dispatch),
    tasks: tasks.service(dispatch),
    executionFlows: executionFlows.service(dispatch),
    toast: toast.service(dispatch),
    ipLocal: ipLocal.service(dispatch),
    reset: reset.service(dispatch),
    filters: filters.service(dispatch),
    theme: theme.service(dispatch)
};

export { reducers, services, store, history };
