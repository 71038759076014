"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _yopenLogo = _interopRequireDefault(require("../@assets/images/yopen/yopenLogo.svg"));
var _appcenterLogo = _interopRequireDefault(require("../@assets/images/appcenter/appcenterLogo.svg"));
var _dokiaLogo = _interopRequireDefault(require("../@assets/images/dokplus/dokiaLogo.png"));
var _workfluxLogo = _interopRequireDefault(require("../@assets/images/workflux/workfluxLogo.png"));
var _workfluxPortalLogo = _interopRequireDefault(require("../@assets/images/workflux/workfluxPortalLogo.svg"));
var logosImages = {
  collectame: {
    logo: _yopenLogo["default"],
    logoAlt: 'Logo Yopen',
    coverAlt: 'Yopen cover'
  },
  'appcenter-customer': {
    logo: _appcenterLogo["default"],
    logoAlt: 'Logo Appcenter',
    coverAlt: 'Appcenter cover'
  },
  dokia: {
    logo: _dokiaLogo["default"],
    logoAlt: 'Logo Dok+',
    coverAlt: 'Dok+ cover'
  },
  workflux: {
    logo: _workfluxLogo["default"],
    logoAlt: 'Logo Workflux',
    coverAlt: 'Workflux cover'
  },
  'workflux-portal': {
    logo: _workfluxPortalLogo["default"],
    logoAlt: 'Logo Workflux Portal',
    coverAlt: 'Workflux Portal cover'
  }
};
var _default = logosImages;
exports["default"] = _default;