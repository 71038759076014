import React, { useMemo, useState } from "react";
import { Avatar, Box, Stack, Typography, styled, Button as ButtonMui } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FormContainer } from "react-hook-form-mui";

import { appcenterApi } from "@apis";
import { getNameInitials } from "@functions";
import { workfluxStorage } from "@firebase-config";
import { useStore } from "@hooks";
import i18n from "@i18n";

import styles from "./styles.module.scss";
import { services } from "@store";
import { Button, InputFieldElement } from "components";

const MAX_FILE_SIZE = 1024 * 1024; // 1 MB

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

// TODO: add some titles 8n
export function ProfileForm() {
    const {
        auth: { user, company },
        theme: { theme }
    } = useStore();
    const [selectedPhotoFile, setSelectedFile] = useState(null);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const defaultValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        companyName: user.currentCompany?.fantasyName,
        phone: user.phone,
        address: user.addresses[0],
        birthdate: user.birthdate
    };

    const userInitials = useMemo(() => {
        const fullName = `${user.firstName} ${user.lastName}`;
        return getNameInitials(fullName);
    }, [user]);

    const displayPhotoUrl = useMemo(() => {
        return selectedPhotoFile
            ? URL.createObjectURL(selectedPhotoFile)
            : user?.photo;
    }, [user?.photo, selectedPhotoFile]);

    const uploadProfilePhoto = async () => {
        if (!selectedPhotoFile) return user.photo;

        const ref = workfluxStorage.ref(`photos/${selectedPhotoFile.name}`);
        await ref.put(selectedPhotoFile);

        return await ref.getDownloadURL();
    };

    const handleFormSubmit = async (data) => {
        try {
            setIsSubmiting(true);

            const photoUrl = await uploadProfilePhoto();

            const { address, ...restData } = data;

            const parsedUser = {
                ...user,
                ...restData,
                name: `${user.firstName} ${user.lastName}`,
                addresses: [address],
                photo: photoUrl,
            };
            delete parsedUser.currentCompany;

            await appcenterApi.users.update(company.uid, parsedUser);

            services.toast.error(i18n("Alterações salvas com sucesso"));
        } catch (error) {
            console.error(error);
            services.toast.error(i18n("Algum erro inesperado ocorreu"));
        } finally {
            setIsSubmiting(false);
        }
    };

    const handlePhotoChange = (event) => {
        try {
            const uploadedFile = event.target.files[0];
            if (!uploadedFile) return;

            if (uploadedFile.size > MAX_FILE_SIZE) {
                services.toast.error(
                    i18n("O tamanho máximo permitido é de @tamanhoEmMb MB.", {
                        tamanhoEmMb: MAX_FILE_SIZE / 1024 / 1024,
                    })
                );
                event.target.value = null;
                return;
            }

            setSelectedFile(uploadedFile);
        } catch (error) {
            console.error(error);
            services.toast.error(i18n("Algum erro inesperado ocorreu"));
        }
    };

    return (
        <FormContainer
            defaultValues={defaultValues}
            onSuccess={handleFormSubmit}
        >
            <Box className={styles.Form}>
                <Typography color="textPrimary" variant="h6" pt={4} pl={4}>
                    <strong
                        style={{
                            color: theme === "dark" ? "#FFF" : ""
                        }}
                    >{i18n("Detalhes do perfil")}</strong>
                </Typography>

                <Stack
                    className={styles.FormHeader}
                    direction="row"
                    useFlexGap
                    justifyContent={{ xs: "center", md: "flex-start" }}
                >
                    <Avatar
                        alt={i18n("Foto de perfil")}
                        src={displayPhotoUrl}
                        sx={{ width: 100, height: 100 }}
                        variant="rounded"
                    >
                        {userInitials}
                    </Avatar>

                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <ButtonMui component="title" variant="contained">
                                {i18n("Carregar nova foto")}
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={handlePhotoChange}
                                    accept="image/png, image/jpeg"
                                />
                            </ButtonMui>
                        </Stack>

                        <Typography color="GrayText" variant="body1">
                            {i18n(
                                "PNG ou JPEG permitido. Tamanho máximo de 1MB."
                            )}
                        </Typography>
                    </Stack>
                </Stack>

                <Grid container p={4} spacing={3}>
                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="firstName"
                            title={"Primeiro nome"}
                            required

                        />
                    </Grid>

                    <Grid container xs={12} md={6}>
                        <Grid xs={12} md={6}>
                            <InputFieldElement
                                name="lastName"
                                title={"Sobrenome"}
                                required
                            />

                        </Grid>
                        <Grid xs={12} md={6}>
                            <InputFieldElement
                                name="birthdate"
                                title="Data de Nascimento"
                                required
                            />
                        </Grid>

                    </Grid>

                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="email"
                            title="E-mail"
                            required
                            type="email"
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="companyName"
                            title="Empresa"
                            required
                            disabled
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="phone"
                            title={"Telefone"}
                            type="tel"
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="address.postalCode"
                            title={"CEP"}

                            type="number"
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="address.city"
                            title={"Cidade"}

                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <InputFieldElement
                            name="address.state"
                            title={"Estado"}

                        />
                    </Grid>

                    <Grid xs={12} md={9}>
                        <InputFieldElement
                            name="address.street"
                            title={"Logradouro"}

                        />
                    </Grid>

                    <Grid xs={12} md={3}>
                        <InputFieldElement
                            name="address.streetNumber"
                            title={"Número"}

                            type="number"
                        />
                    </Grid>

                    <Grid xs={12}>
                        <InputFieldElement
                            name="address.district"
                            title={"Bairro"}

                        />
                    </Grid>
                </Grid>

                <Stack className={styles.FormFooter}>
                    <Button
                        type="submit"
                        loading={isSubmiting}
                        title="Salvar Alterações"
                    />
                </Stack>
            </Box>
        </FormContainer>
    );
}
