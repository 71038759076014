import { executedDocumentCollection } from "@firebase-config/collections";
import { createExecutedDocument } from "@models";

/**
 * @typedef ExecutedDocument
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * Function to list all executedDocuments of company by status
 * @param {{
 *      companyUid: string;
 *      userUid: string;
 *      lastDocUid?: string;
 *      limit?: number;
 * }} params
 * @returns {Promise<ExecutedDocument[] | string>}
 */
export async function listExecutedDocument({
    companyUid,
    userUid,
    lastDocUid,
    limit = 20,
}) {
    if (!companyUid || typeof companyUid !== "string") {
        return "invalid-param-companyUid";
    }

    try {
        let query = executedDocumentCollection
            .where("company", "==", companyUid)
            // .where("security", "==", "public")
            .where("deleted", "==", false)
            .orderBy("createdAt", "desc")
            .limit(limit);

        if (userUid) {
            query = query.where("agent", "==", userUid);
        }

        if (lastDocUid) {
            const doc = await executedDocumentCollection.doc(lastDocUid).get();
            query = query.startAfter(doc);
        }

        const { docs } = await query.get();
        const allExecutedDocuments = docs.map((doc) => doc.data());

        return allExecutedDocuments.map(createExecutedDocument);
    } catch (error) {
        console.log("ERROR in listExecutedDocument: >=>", error);
        return "error-list-executedDocument";
    }
}
