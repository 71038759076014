import { taskCollection } from "@firebase-config/collections";
import { createTask } from "@models";

/**
 * @typedef Task
 * @type {import("@models/task").Task}
 */

/**
 * Function to find task by taskUid
 * @param {{
 *      taskUid?: string;
 *      taskBanner?:{
 *          executionFlowUid: string,
 *          actionBanner: string
 *      }
 * }} params
 * @returns {Promise<string | Task>}
 */
export async function findTask({ taskUid, taskBanner }) {
    if (!taskUid && !taskBanner) {
        return "invalid-param-taskUid";
    }
    try {

        let query = taskCollection.where("deleted", "==", false)
            .orderBy("createdAt", "desc")

        if (taskBanner) {
            query = query
            .where("action.uid", "==", taskBanner.actionBanner)
            .where("executionFlow", "==", taskBanner.executionFlowUid)
        } else {
            query = query.where("uid", "==", taskUid)
        }

        const { docs } = await query.get();

        const tasks = docs.map((doc) => doc.data());

        if (!tasks.length) return "not-found-task";

        return createTask(tasks[0]);
    } catch (error) {
        console.log("ERROR in findTask: >=>", error);
        return "error-find-task";
    }
}
