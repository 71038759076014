import React, { useState } from "react";
import i18n from "@i18n";

import Topbar from "@custom/Topbar";
import FileList from "../components/FileList";
import FileFilters from "../components/FileFilters";

import { services } from "@store";
import { Button } from "@mui/material";
import { useAsyncEffect, useStore } from "@hooks";

import styles from "./styles.module.scss";

function MyFiles() {
    const [filterIsVivible, setFilterIsVisible] = useState(false);
    const { auth, documents } = useStore();

    useAsyncEffect(async () => {
        if (
            !auth.company?.uid ||
            !auth.user?.uid ||
            !auth.isAuthenticated ||
            !!documents.length
        )
            return;

        services.spinner.show();

        await services.documents.list({
            company: auth.company.uid,
            user: auth.user.uid,
        });

        services.spinner.hide();
    }, [auth.company, auth.user]);
    return (
        <div className={styles.myFilesContainer}>
            <Topbar />
            <div>
                <h2>
                    {i18n(
                        "Bem-vindo à sua lista de arquivos para controle de fluxos"
                    )}
                </h2>
            </div>
            <div className={styles.filterContent}>
                <div className={styles.filterHeader}>
                    <Button
                        fullWidth
                        color={filterIsVivible ? "error" : "primary"}
                        variant="contained"
                        onClick={() => setFilterIsVisible(!filterIsVivible)}
                    >
                        {filterIsVivible
                            ? i18n("Fechar Filtro")
                            : i18n("Abrir Filtro")}
                    </Button>
                </div>
                <div
                    className={styles.filter}
                    visible-filter={filterIsVivible ? "active" : "disable"}
                >
                    <FileFilters />
                </div>
            </div>
            <FileList />
        </div>
    );
}

export default MyFiles;
