import React, { useState } from "react";
import { Link } from "@mui/material";

import i18n from "@i18n";
import { history } from "@store";
import { useStore } from "@hooks";

import styles from "./styles.module.scss";

import { Button } from "@mui/material";
import Icon from "@custom/Icon";

/**
 * @typedef News
 * @type {import("@models/news").News}
 */

/**
 *
 * @param {{
 *     openNews: News;
 * }} props
 */
function NewsPanel({ openNews }) {
    const { news: newsList, theme: { theme } } = useStore();
    const otherNews = newsList.filter((news) => news.uid !== openNews.uid);

    const positionInitial = -350;
    const [positionPanel, setPositionPanel] = useState(positionInitial);

    return (
        <div className={`${styles.NewsPanel} ${styles[theme]}`}>
            <section className={styles.detailsNew}>
                <img src={openNews.bannerUrl} alt={openNews.title} />
                <div
                    className={styles.NewsPanelArticleContent}
                    dangerouslySetInnerHTML={{ __html: openNews.text }}
                />
            </section>
            <div className={styles.panelOtherNews} style={{
                marginRight: positionPanel
            }} >
                <div className={styles.cardOtherNews}>
                    <button
                        className={styles.buttonPanelOtherNews}
                        onClick={() => setPositionPanel(positionPanel === positionInitial ? 0 : positionInitial)}
                        style={{
                            left: positionPanel === 0 ? 0 : null
                        }}
                    >
                        {positionPanel === positionInitial ?
                            <Icon icon="faArrowLeft" /> :
                            <Icon icon="faArrowRight" />

                        }

                    </button>
                    {otherNews.map((news) => (
                        <div key={news.uid} className={styles.newsPanelCard}>
                            <div className={styles.banner}>
                                <img src={news.bannerUrl} alt={news.title} />
                            </div>
                            <div className={styles.cardInfo}>
                                <div>
                                    <p className={styles.cardTitle}>
                                        {news.title}
                                    </p>
                                    <div
                                        className={styles.cardSummary}
                                        dangerouslySetInnerHTML={{
                                            __html: news.summary,
                                        }}
                                    />
                                </div>
                                <Link
                                    className={styles.NewsPanelCardSeeMore}
                                    underline="hover"
                                    color="primary"
                                    onClick={() => {

                                        history.push(`/news/${news.uid}`)
                                        setPositionPanel(-350)
                                    }}
                                >
                                    {i18n("Ver mais")}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
                <Link
                    underline="hover"
                    color="primary"
                    onClick={() => history.push("/")}
                >
                    {i18n("Ver mais notícias no portal Taugor")}
                </Link>
            </div>
        </div >
    );
}

export default NewsPanel;
