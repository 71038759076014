import React from "react";

import i18n, { format } from "@i18n";
import { toDate } from "@functions";

import styles from "./actionHistory.module.scss";
import { Modal } from "components";
import { useStore } from "@hooks";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * @param {{
 *     open: boolean;
 *     close: () => void;
 *     executionFlow: ExecutionFlow;
 * }} params
 */
export function HistoryFlow({ open, close, executionFlow }) {

  const { theme: { theme } } = useStore()

  const steps = [
    {
      id: -1,
      type: "execution.view",
      title: null,
    },
    {
      id: 0,
      type: "execution.start",
      title: i18n("Fluxo Iniciado"),
    },
    {
      id: 1,
      type: "form.filled",
      title: i18n("Formulário Preenchido"),
    },
    {
      id: 2,
      type: "executed.reproval",
      title: i18n("Reprovação Realizada"),
    },
    {
      id: 3,
      type: "executed.reproval.forbids.forwarding",
      title: i18n(
        "Reprovação - Retornou a fase anterior para rever as tarefas"
      ),
    },
    {
      id: 4,
      type: "document.uploaded",
      title: i18n("Arquivo Enviado"),
    },
    {
      id: 5,
      type: "execution.phase.jumped",
      title: i18n("Pulou de Fase"),
    },
    {
      id: 6,
      type: "execution.forwarded",
      title: i18n("Fluxo Encaminhado"),
    },
  ];

  return (
    <Modal open={open} close={close} title="Histórico de ações">
      <div className={`${styles.modalContent} ${styles[theme]}`}>
        {executionFlow?.history
          ?.filter((history) => history?.type !== steps[0].type)
          .map((history, i) => (
            <div key={i} className={styles.step}>
              {steps
                .filter((step) => step.type === history?.type)
                .map((step) => (
                  <strong>{step.title}</strong>
                ))}

              {history.code &&
                executionFlow.managementProtocolCode && (
                  <p>
                    {executionFlow.managementProtocolCode}
                    -ENC-{history.code}
                  </p>
                )}

              {history.instructions && (
                <p>{history.instructions}</p>
              )}
              {history.description && (
                <p>{history.description}</p>
              )}
              {history.taskDescription && (
                <p>{history.taskDescription}</p>
              )}
              {history.oldAgent && history.newAgent && (
                <p>
                  {history.oldAgent.agentName}
                  {" -> "}
                  {history.newAgent.agentName}
                </p>
              )}
              {history.url && <p>{history.url}</p>}
              {history.media && <p>{history.media}</p>}
              {history.phaseName && <p>{history.phaseName}</p>}
              {history.formTitle && <p>{history.formTitle}</p>}
              {history.agentName && <p>{history.agentName}</p>}
              {history.user && (
                <p>
                  {history.user.name} ({history.user.email})
                </p>
              )}
              {history.local && (
                <p>
                  {history.local.city}, {history.local.region}
                  , {history.local.country_code} (
                  {history.local.latitude}º O{" "}
                  {history.local.longitude}º S)
                </p>
              )}
              <p>{format.datetime(toDate(history?.date))}</p>
            </div>
          ))}
      </div>
    </Modal>
  );
}