import { changeLanguage, getCurrentLanguage, getLanguageByDevice } from "@i18n";

const LANGUAGE_CHANGE = "LANGUAGE_CHANGE";

const service = (dispatch) => ({
    /** @param {string} newLanguage */
    changeLanguage: (newLanguage) => {
        changeLanguage(newLanguage);
        dispatch({
            type: LANGUAGE_CHANGE,
            payload: { newLanguage },
        });
    },
    getCurrentLanguage: () => {
        return getCurrentLanguage();
    },
    getLanguageByDevice: () => {
        return getLanguageByDevice();
    },
});

const INIT_STATE = {
    current: getCurrentLanguage().language,
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LANGUAGE_CHANGE:
            return {
                ...state,
                current: action.payload.newLanguage,
            };

        default:
            return { ...state };
    }
};

export default { reducer, service };
