import React from "react";
import axios from "axios";
import { isLocal } from "@functions";
import { services, store } from "@store";
import { SYSTEM_HASH } from "@constants";
import Relogin from "@custom/Relogin";
import i18n from "@i18n";

const defaultTimeout = 0;

/**
 * @typedef AxiosInstance
 * @type {import("axios").AxiosInstance}
 */

/** @param {AxiosInstance} api */
const successInterceptor = (api) => (/** @type {any} */ response) => response;

/** @param {AxiosInstance} api */
const errorInterceptor = (api) => async (/** @type {any} */ error) => {
    const { config, response } = error;
    const { status } = response || {};
    const originalRequest = config;

    if (status === 418) {
        return await new Promise((resolve) => {
            /** @param {string} token */
            const callback = (token) => {
                services.message.hide();

                originalRequest.headers["firebase-token"] = token;
                api.defaults.headers.common["firebase-token"] = token;

                return resolve(axios.request(originalRequest));
            };

            services.spinner.hide()

            function handleLogin() {
                services.message.hide();
                services.auth.logout()
            }

            services.message.error({
                title: i18n("Sua seção expirou, faça login novamente!"),
                buttons: [
                    {
                        onClick: handleLogin,
                        children: i18n("Fazer Login"),
                    },
                ]
            });
        });
    }

    return Promise.reject(error);
};

/** @param {AxiosInstance} api */
const addInterceptors = (api) => {
    api.interceptors.response.use(
        successInterceptor(api),
        errorInterceptor(api)
    );

    api.interceptors.request.use((request) => {
        const storeState = store.getState();

        if (!request.data) request.data = {};

        request.data.ipLocal = storeState.ipLocal;
        request.data.origin = window.location.origin;
        request.data.isPortal = true;

        return request;
    });

    return api;
};

export const workflux = addInterceptors(
    axios.create({
        baseURL: isLocal()
            ? "http://localhost:5005/bpmn-taugor/us-central1/webApi/api/v1/"
            : "https://us-central1-bpmn-taugor.cloudfunctions.net/webApi/api/v1",
        timeout: defaultTimeout,
    })
);

export const appcenter = addInterceptors(
    axios.create({
        baseURL: isLocal()
            ? "http://localhost:5001/taugor-appcenter/us-central1/customer_api/"
            : "https://us-central1-taugor-appcenter.cloudfunctions.net/customer_api/",
        timeout: defaultTimeout,
        headers: {
            "system-token": SYSTEM_HASH,
        },
    })
);

export const ipLocal = addInterceptors(
    axios.create({
        baseURL:
            "https://ipapi.co/json/?key=cOVrnQqvw2erSYXGgTE1VO6Tb38Z3F1EUe2v4x03wVkbNzbm6c",
        timeout: defaultTimeout,
    })
);
