"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
/** @returns {{ [key: string]: string }} */
function getQueryParams() {
  var queryStr = window.location.href.split("?")[1];
  if (!queryStr) return {};
  return queryStr.split("&").reduce(function (result, keyValuePair) {
    var _keyValuePair$split = keyValuePair.split("="),
      _keyValuePair$split2 = (0, _slicedToArray2["default"])(_keyValuePair$split, 2),
      key = _keyValuePair$split2[0],
      value = _keyValuePair$split2[1];
    result[key] = value;
    return result;
  }, {});
}
var _default = getQueryParams;
exports["default"] = _default;