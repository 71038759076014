import { listExecutionFlow } from "./list";
import { findExecutionFlow } from "./find";
import { watchExecutionFlows } from "./watch";
import { listExecutionFlowByType } from "./listByType";
import { updateExecutionFlow } from "./update";

export const serviceExecutionFlow = {
    list: listExecutionFlow,
    find: findExecutionFlow,
    watch: watchExecutionFlows,
    listByType: listExecutionFlowByType,
    update: updateExecutionFlow,
};
