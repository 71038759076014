import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useStore } from "@hooks";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function UserSelect(props) {
    const { allUsers } = useStore();
    const { fieldConfig, updateFormValues, formValues, formFilled } = props;
    const field = fieldConfig.name;
    const value = formValues[field];

    /** @param {React.ChangeEvent<HTMLInputElement>} evt */
    const handleUserChange = (evt) => {
        updateFormValues(field, evt.target.value);
    };

    if (formFilled) {
        return (
            <>
                <p>
                    <strong>{field}</strong>
                </p>
                <p>{value}</p>
            </>
        );
    }

    return (
        <FormControl fullWidth className={styles.UserSelect}>
            <InputLabel>{fieldConfig.name}</InputLabel>
            <Select
                variant="outlined"
                onChange={handleUserChange}
                value={value}
            >
                {allUsers.map((user) => (
                    <MenuItem
                        key={user.uid}
                        className={styles.UserSelectOption}
                        value={`${user.firstName}; ${user.lastName}; ${user.email}; ${user.uid}`}
                    >
                        <p>
                            <strong>
                                {user.firstName + " " + user.lastName}
                            </strong>
                            <br />
                            {user.email}
                        </p>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default UserSelect;
export { UserSelect };
