import AVI from "@assets/images/AVI.svg";
import CSS from "@assets/images/CSS.svg";
import Excel from "@assets/images/Excel.svg";
import Gif from "@assets/images/GIF.svg";
import HTML from "@assets/images/HTML.svg";
import JPG from "@assets/images/JPG.svg";
import MOV from "@assets/images/MOV.svg";
import PDF from "@assets/images/PDF.svg";
import PNG from "@assets/images/PNG.svg";
import PowerPoint from "@assets/images/Power Point.svg";
import RSR from "@assets/images/Rsr.svg";
import TXT from "@assets/images/Texto.svg";
import Word from "@assets/images/Word.svg";
import Zip from "@assets/images/Zip.svg";
import ArquivoGenerico from "@assets/images/Arquivo Genérico.svg";

/** @param {string} extension */
function extensionToIcon(extension) {
    extension = extension.toLowerCase();

    switch (extension) {
        case "avi":
            return AVI;
        case "scss":
        case "sass":
        case "css":
            return CSS;
        case "xlsx": // Pasta de trabalho do Excel
        case "xlsm": // Pasta de trabalho do Excel (código)
        case "xlsb": // Pasta de Trabalho Binária do Excel
        case "xltx": // Modelo
        case "xltm": // Modelo (código)
        case "xls": // Pasta de Trabalho do Excel 97- Excel 2003
        case "xlt": // Modelo do Excel 97- Excel 2003
        case "xls": // Pasta de trabalho do Microsoft Excel 5.0/95
        case "xml": // Planilha XML 2003
        case "xml": // XML Data
        case "xlam": // Suplementos do Excel
        case "xla": // Suplemento do Excel 97-2003
            return Excel;
        case "gif":
            return Gif;
        case "htm":
        case "phtml":
        case "html":
            return HTML;
        case "jpeg":
        case "jpg":
            return JPG;
        case "pptx": // Apresentação do PowerPoint
        case "pptm": // Apresentação de Macro-Enabled do PowerPoint
        case "ppt": // Apresentação do PowerPoint 97-2003
        case "potx": // Modelos de design do PowerPoint
        case "ppsx": // PowerPoint Show
        case "ppam": // Add-In do PowerPoint
            return PowerPoint;
        case "mov":
            return MOV;
        case "pdf":
            return PDF;
        case "png":
            return PNG;
        case "rsr":
            return RSR;
        case "txt":
            return TXT;
        case "doc": // Documento do Word 97-2003
        case "docm": // Documento do Word habilitado para macro
        case "docx": // Documento do Word
        case "dot": // Modelo do Word 97-2003
        case "dotm": // Modelo do Word habilitado para macro
        case "dotx": // Modelo do Word
            return Word;
        case "zip": // ZIP Extensão de arquivo
        case "7z": // 7Z
        case "gzip": // GZIP
        case "bzip2": // BZIP2
        case "tar": // TAR
            return Zip;
        default:
            return ArquivoGenerico;
    }
}

export default extensionToIcon;
export { extensionToIcon };
