import { executionFlowCollection } from "@firebase-config/collections";
import { createExecutionFlow } from "@models";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * @typedef ListType
 * @type {'responsible' | 'creator' | 'participate' | 'archived' | "iCanSee"}
 */

/**
 * Function to list all executionFlow of company by type
 * @param {{
 *      type: ListType;
 *      companyUid: string;
 *      userUid: string;
 *      lastDocUid?: string;
 *      limit?: number;
 * }} params
 * @returns {Promise<ExecutionFlow[] | string>}
 */
export async function listExecutionFlowByType({
    companyUid,
    userUid,
    type,
    lastDocUid,
    limit = 20,
}) {
    if (!type || typeof type !== "string") {
        return "invalid-param-type";
    }
    if (!userUid || typeof userUid !== "string") {
        return "invalid-param-userUid";
    }
    if (!companyUid || typeof companyUid !== "string") {
        return "invalid-param-companyUid";
    }

    let query = executionFlowCollection
        .where("company", "==", companyUid)
        .where("deleted", "==", false)
        .where("workflowObject.security", "==", "public")
        .orderBy("createdAt", "desc")
        .limit(limit);

    if (type === "creator") {
        query = query.where("createdBy", "==", userUid);
    }

    if (type === "participate") {
        query = query.where("participants", "array-contains", userUid);
    }

    if (type === "responsible") {
        query = query.where("agentObject.uid", "==", userUid);
    }

    try {
        if (lastDocUid) {
            const doc = await executionFlowCollection.doc(lastDocUid).get();
            query = query.startAfter(doc);
        }

        const { docs } = await query.get();

        const allByType = docs.map((doc) => doc.data());

        return allByType.map(createExecutionFlow);
    } catch (error) {
        return "error-list-executionFlow-by-type";
    }
}
