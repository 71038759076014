import { useStore } from "@hooks"
import i18n from "@i18n"
import React from "react"
import { TextFieldElement } from "react-hook-form-mui"

/**
 * 
 * @param {{
 *  title: string
 *  name: string
 *  required?: boolean
 *  type?: string  
 *  disabled?: boolean
 * }} param0 
 * @returns 
 */

export function InputFieldElement({ title, name, required, type, disabled }) {

  const { theme: { theme } } = useStore()

  return (
    <TextFieldElement
      name={name}
      label={i18n(title)}
      size="small"
      required={required}
      type={type}
      disabled={disabled}
      fullWidth
      InputLabelProps={{
        style: { color: theme === 'dark' ? '#f5f5f599' : '', }
      }}
      inputProps={{
        style: {
          color: theme === 'dark' ? '#fff' : '',
        }
      }}
      InputProps={{
        sx: {
          color: theme === 'dark' ? '#fff' : '',
          '& fieldset': {
            borderColor: theme === 'dark' ? '#f5f5f599' : '',
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme === 'dark' ? 'white' : '',
          },
          "&.Mui-disabled": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: theme === 'dark' ? '#f5f5f599' : '',
            },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: theme === 'dark' ? '#f5f5f599' : '',
          },


        },
      }}
    />
  )
}