import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import styles from "./styles.module.scss";

/**
 * @typedef SpinnerProps
 * @type {{
 *     loading: boolean;
 *     fullPage?: boolean;
 * }}
 */

/** @param {SpinnerProps} props */
const Spinner = ({ loading, fullPage = false }) => (
    <div
        className={styles.Spinner}
        data-full-page={fullPage}
        data-loading={loading}
    >
        <PulseLoader color="#00679d" loading={loading} size={30} />
    </div>
);

export default Spinner;
