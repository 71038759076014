import { signingCollection } from "@firebase-config/collections";
import { createSigning as createSigningModel } from "@models";

/**
 * @typedef Signing
 * @type {import("@models/signing").Signing}
 */

/**
 * Function to create signing
 * @param  {Partial<Signing>} data
 * @returns {Promise<string | Signing>}
 */
export async function createSigning(data) {
    try {
        const doc = signingCollection.doc();
        const signing = createSigningModel({
            ...data,
            createdAt: new Date(),
            deleted: false,
            uid: doc.id,
        });

        await doc.set(signing);

        return signing;
    } catch (error) {
        console.log("ERROR in createSigning: >=>", error);
        return "error-find-signing";
    }
}
