

const CHANGE_FILTER_TASK_DATE_SELECTED = "CHANGE_FILTER_TASK_DATE_SELECTED";
const CHANGE_FILTER_TASK_TYPE_SELECTED = "CHANGE_FILTER_TASK_TYPE_SELECTED";
const service = (dispatch) => ({

  changeDateSelected: (date) => {
    dispatch({
      type: CHANGE_FILTER_TASK_DATE_SELECTED,
      payload: { dateSelected: date },
    })
  },
  changeTypeSelected: (value) => {
    dispatch({
      type: CHANGE_FILTER_TASK_TYPE_SELECTED,
      payload: { typeSelected: value},
    })
  }
});

const INIT_STATE = {
  dateSelected: "",
  typeSelected: {
    form: false,
    approval: false,
    document: false,
  }
}

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_FILTER_TASK_DATE_SELECTED:
      return { ...state, dateSelected: action.payload.dateSelected }
    case CHANGE_FILTER_TASK_TYPE_SELECTED:
      return { ...state, typeSelected: action.payload.typeSelected }
    default:
      return { ...state };
  }
};

export default { reducer, service };
