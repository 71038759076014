import { workflux } from "./endpoints";
import { workfluxStorage } from "@firebase-config";
import { signingCollection } from "@firebase-config/collections";
import { createSigning } from "@models";
import { generateUid } from "@functions";

/**
 * @typedef Workflow
 * @type {import('../@models/workflow').Workflow}
 */

/**
 * @typedef Task
 * @type {import('../@models/task').Task}
 */
/**
 * @typedef User
 * @type {import('../@hooks/useStore').User}
 */

/**
 * @typedef Signing
 * @type {import('../@models/signing').Signing}
 */

const workfluxApi = {
    document: {
        /**
         * @param {string} documentUid
         * @returns {Promise<{ path: string, url: string }>}
         */
        open: (documentUid) => {
            return workflux
                .post(`/open/document/${documentUid}`)
                .then((result) => result.data);
        },
    },
    executionFlow: {
        /**
         * @param {{ workflow: Workflow }} params
         * @returns {Promise<import("@models").ExecutionFlow>}
         */
        create: ({ workflow }) => {
            return workflux
                .post("workflow/execution", { workflow })
                .then(({ data }) => data.executionFlow);
        },
        /**
         * @param {{ id: string; cancelMessage: string; user: User; }} params
         * @returns {Promise<string>}
         */
        cancel: ({ id, cancelMessage, user }) => {
            return workflux.post(`/workflow/execution/${id}/cancel`, {
                id,
                cancelMessage,
                canceledBy: {
                    user: `${user.firstName} ${user.lastName}`,
                    userUid: user.uid
                }
            });
        },
    },
    tasks: {
        signedDocumentInput: {
            /** @param {{ fileBase64: string }} params */
            start: ({ fileBase64 }) => {
                return workflux.post(`/signature/start`, {
                    file: fileBase64,
                });
            },
            /** @param {{ token: string }} params */
            complete: ({ token }) => {
                return workflux.post(`/signature/complete`, {
                    token,
                });
            },
            /** 
             * @param {{ 
             *     task: Task; 
             *     location: string;
             *     ged17Fields?: any;
             * }} params 
             */
            saveReference: ({ task, location, ged17Fields }) => {
                return workflux.post(
                    `/workflow/execution/task/documentInput/${task.uid}`,
                    {
                        task,
                        location,
                        ged17Fields,
                    }
                );
            },
        },
        /**
         * @param {string} task 
         * @param {Task["agentObject"]} agent 
         */
        updateTaskOwner: (task, agent) => {
            return workflux.post(`/task/${task}/update/responsible`, { agent });
        },
        documentInput: ({ task, file, ged17Fields }) => {
            const formData = new FormData();
            formData.append("document", file);

            const rootRef = workfluxStorage.ref();
            const docRef = rootRef.child(
                `executionFlow/${task.executionFlow}/task/${task.uid
                }/${generateUid()}.${file.name.split(".").pop()}`
            );

            return docRef.put(file).then((snapshot) => {
                return snapshot.ref.getDownloadURL().then((location) => {
                    return workflux.post(
                        `/workflow/execution/task/documentInput/${task.uid}`,
                        {
                            task,
                            location,
                            file: {
                                name: file.name,
                                size: file.size,
                            },
                            ged17Fields,
                        }
                    );
                });
            });
        },
        approve: (id, approved, reason, signingUid) => {
            return workflux.post(`/workflow/execution/task/approval/${id}`, {
                approved,
                reason,
                signingUid,
            });
        },
        confirmReview: ({ task }) => {
            return workflux.post(
                `/workflow/execution/task/confirmReview/${task}`
            );
        },
        /**
         * @param {{
         *     task: Task;
         *     form: {
         *         [field: string]: any;
         *     };
         *     thumbprint?: string;
         * }} params
         * @returns {Promise<Signing | undefined>}
         */
        formInput: async ({ task, form, thumbprint }) => {
            let signingUid = undefined;

            if (thumbprint) {
                const doc = signingCollection.doc();
                await doc.set(
                    createSigning({
                        uid: doc.id,
                        status: "pending",
                        certificateThumbprint: thumbprint,
                    })
                );
                signingUid = doc.id;
            }

            return workflux
                .post(`/workflow/execution/task/formInput/${task.uid}`, {
                    form,
                    signingUid,
                })
                .then((resp) => resp.data)
                .then((data) => {
                    if (thumbprint) {
                        return signingCollection
                            .doc(signingUid)
                            .get()
                            .then((doc) => doc.data());
                    }
                    return data;
                });
        },
    },
};

export default workfluxApi;
export { workfluxApi };
