import { executionFlowCollection } from "@firebase-config/collections";
import { createExecutionFlow } from "@models";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * Function to find executionFlow
 * @param {{
 *      executionFlowUid: string;
 * }} params
 * @returns {Promise<string | ExecutionFlow>}
 */
export async function findExecutionFlow({ executionFlowUid }) {
    if (!executionFlowUid || typeof executionFlowUid !== "string") {
        return "invalid-param-executionFlowUid";
    }

    try {
        const doc = await executionFlowCollection.doc(executionFlowUid).get();
        const data = doc.data();

        if (!data?.uid) return "not-found-executionFlow";

        return createExecutionFlow(data);
    } catch (error) {
        console.log("ERROR in findExecutionFlow: >=>", error);
        return "error-find-executionFlow";
    }
}
