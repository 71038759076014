import { serviceExecutedDocuments } from "@services/executedDocument";

const LIST_COMPANY_DOCUMENTS = "LIST_COMPANY_DOCUMENTS";
const COMPANY_DOCUMENTS_CHANGE = "COMPANY_DOCUMENTS_CHANGE";
const LIST_MORE_COMPANY_DOCUMENTS = "LIST_MORE_COMPANEY_DOCUMENTS";
const ADD_DOCUMENTS = "ADD_DOCUMENTS";
/**
 * @typedef ExecutedDocument
 * @type {import("@models/executedDocument.js").ExecutedDocument}
 */

/**
 * @typedef Params
 * @type {import("./index.js").Params}
 */

const service = (dispatch) => ({
    /** @param {Params} params */
    list: async ({ company, user }) => {
        const result = await serviceExecutedDocuments.list({
            companyUid: company,
            userUid: user,
        });
        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceExecutedDocuments.watch({
            list,
            callback: (executedDocumentsList) => {
                dispatch({
                    type: COMPANY_DOCUMENTS_CHANGE,
                    payload: { list: executedDocumentsList },
                });
            },
        });

        dispatch({
            type: LIST_COMPANY_DOCUMENTS,
            payload: { company, list },
        });
    },

    /** @param {Params} params */
    listMore: async ({ group, user, company, lastDoc, limit }) => {
        const result = await serviceExecutedDocuments.list({
            companyUid: company,
            userUid: user,
            lastDocUid: lastDoc,
            limit,
        });

        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceExecutedDocuments.watch({
            list,
            callback: (executedDocumentsList) => {
                dispatch({
                    type: COMPANY_DOCUMENTS_CHANGE,
                    payload: { list: executedDocumentsList },
                });
            },
        });

        dispatch({
            type: LIST_MORE_COMPANY_DOCUMENTS,
            payload: { company, list },
        });
    },
    /** @param {ExecutedDocument[]} executedDocument */
    addToList: (executedDocument) => {
        dispatch({
            type: ADD_DOCUMENTS,
            payload: { list: executedDocument },
        });
    },
});

const INIT_STATE = {
    company: "",
    list: [],
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_COMPANY_DOCUMENTS:
            return {
                ...state,
                company: action.payload.company,
                list: action.payload.list,
            };

        case COMPANY_DOCUMENTS_CHANGE:
            return {
                ...state,
                list: state.list.map((flow) => {
                    const updated = action.payload.list.find(
                        (item) => item.uid === flow.uid
                    );

                    return updated || flow;
                }),
            };

        case ADD_DOCUMENTS:
        case LIST_MORE_COMPANY_DOCUMENTS:
            const allDocuments = [...state.list, ...action.payload.list];

            return {
                ...state,
                company: action.payload.company,
                list: allDocuments.reduce((list, flow) => {
                    const alreadyAdded = !!list.find(
                        (item) => item.uid === flow.uid
                    );
                    if (alreadyAdded) return list;
                    list.push(flow);
                    return list;
                }, []),
            };

        default:
            return { ...state };
    }
};
export default { reducer, service };
