/**
 * Get diference in days of week
 * @param {Date} date
 * @returns {number}
 */
function getDiffDaysWeek(date = new Date()) {
    const dateDay = date.getDay();
    let diffDays = dateDay - 1;

    if (diffDays === 0) {
        diffDays = 0;
    }
    if (diffDays === -1) {
        diffDays = 6;
    }

    return diffDays;
}

export default getDiffDaysWeek;
export { getDiffDaysWeek };
