import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { FormContainer } from "react-hook-form-mui";

import { ButtonLoading } from "components/buttons/buttonLoading";
import { useStore } from "@hooks";
import { appcenterApi } from "@apis";
import i18n from "@i18n";

import styles from "./styles.module.scss";
import { services } from "@store";
import { Checkbox } from "components";

// TODO: add some labels on i18n
export function DeleteAccount() {
    const {
        auth: { user, company },
        theme: { theme }
    } = useStore();
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [deletedAccount, setDeletedAccount] = useState(false)

    const logout = async () => {
        services.pageSpinner.show();
        await services.auth.logout();
        setTimeout(() => services.pageSpinner.hide(), 3000);
    };

    const handleFormSubmit = async () => {
        if (!deletedAccount) return
        try {
            setIsSubmiting(true);

            await appcenterApi.users.disable(company.uid, [user]);
            await logout();
        } catch (error) {
            console.error("error", error);
            setIsSubmiting(false);
        }
    };

    return (
        <Box className={styles.Form}>
            <Typography color="textPrimary" variant="h6" mb={2}>
                <strong
                    style={{
                        color: theme === "dark" ? "#FFF" : ""
                    }}
                >{i18n("Deletar conta")}</strong>
            </Typography>

            <FormContainer onSuccess={handleFormSubmit}>
                <Stack spacing={2}>
                    <Checkbox
                        label="Confirmo deletar minha conta permanentemente"
                        checked={deletedAccount}
                        onChange={() => setDeletedAccount(!deletedAccount)}
                    />

                    <Stack className={styles.FormFooter}>
                        <ButtonLoading
                            type="submit"
                            color="error"
                            size="medium"
                            loading={isSubmiting}
                            fullWidth={false}
                            style={{ alignSelf: "flex-start" }}
                        >
                            Deletar conta
                        </ButtonLoading>
                    </Stack>
                </Stack>
            </FormContainer>
        </Box>
    );
}
