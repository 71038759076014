import { workfluxFirestore } from './index';

export const absenseCollection = workfluxFirestore.collection('absense');
export const applicationCollection =
    workfluxFirestore.collection('application');
export const calendarCollection = workfluxFirestore.collection('calendar');
export const categoryNewsCollection =
    workfluxFirestore.collection('categoryNews');
export const companyAreaCollection =
    workfluxFirestore.collection('companyArea');
export const executedApprovalCollection =
    workfluxFirestore.collection('executedApproval');
export const executedDocumentCollection =
    workfluxFirestore.collection('executedDocument');
export const executedFormCollection =
    workfluxFirestore.collection('executedForm');
export const executedQueryUidCollection =
    workfluxFirestore.collection('executedQueryUid');
export const executionFlowCollection =
    workfluxFirestore.collection('executionFlow');
export const externalUserCollection =
    workfluxFirestore.collection('externalUser');
export const managementProtocolCollection =
    workfluxFirestore.collection('managementProtocol');
export const newsCollection = workfluxFirestore.collection('news');
export const recurrenceCollection = workfluxFirestore.collection('recurrence');
export const signingCollection = workfluxFirestore.collection('signing');
export const taskCollection = workfluxFirestore.collection('task');
export const userKanbanConfigCollection =
    workfluxFirestore.collection('userKanbanConfig');
export const workflowCollection = workfluxFirestore.collection('workflow');
export const workflowHistoryCollection =
    workfluxFirestore.collection('workflowHistory');

export const filterCompanyAreaCollection = workfluxFirestore.collection("filterCompanyArea")
