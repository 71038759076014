import styles from "./styles.module.scss"
import Icon from '@custom/Icon'
import i18n from "@i18n"
import React from 'react'

export function StatusCanceled({ status }) {
  if (status !== "canceled" && status !== "removed") return <></>

  return (
    <div className={styles.cancelIndicator}>
      <span className={styles.icon}><Icon icon="faInfo" /></span>
      <p>{i18n("Solicitação Cancelada")}</p>
    </div>
  )
}