import React from "react";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Title(props) {
    const { fieldConfig } = props;

    return <h3 className={styles.Title}>{fieldConfig.name}</h3>;
}

export default Title;
export { Title };
