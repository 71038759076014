import React, { useEffect, useState } from "react";
import { FormApproval } from "../formApproval";
import { FormForm } from "../formForm";
import { FormDocument } from "../formDocument";
import i18n from "@i18n";
import { Modal } from "components";
import { serviceTask } from "@services/task";

export function TaskBanner({
    executionFlowUid = "",
    actionBanner = "",
    close,
    open,
}) {
    const [task, setTask] = useState(null);

    useEffect(() => {
        async function getTask() {
            const response = await serviceTask.find({
                taskBanner: {
                    executionFlowUid,
                    actionBanner,
                },
            });
            if (response === "invalid-param-taskUid") return;
            setTask(response);
        }

        getTask();
    }, [executionFlowUid]);

    if (!task) return <></>;

    const typesTask = {
        approval: {
            component: (
                <FormApproval task={task} clearTask={() => setTask(null)} />
            ),
            title: i18n("Aprovação de fluxos"),
        },
        formInput: {
            component: <FormForm task={task} clearTask={() => setTask(null)} />,
            title: i18n("Preenchimento de Formulário"),
        },
        documentInput: {
            component: (
                <FormDocument task={task} clearTask={() => setTask(null)} />
            ),
            title: i18n("Envio de documento"),
        },
    };

    return (
        <Modal
            close={close}
            open={open}
            title={typesTask[task?.action?.type?.type].title}
        >
            {typesTask[task?.action?.type?.type].component}
        </Modal>
    );
}
