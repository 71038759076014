import React, { useMemo } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import { FreeMode, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import i18n from "@i18n";
import { useStore, useWindowSize } from "@hooks";
import { history } from "@store";

import NewsPanel from "../NewsPanel";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import styles from "./styles.module.scss";

/**
 * @typedef News
 * @type {import("@models/news").News}
 */

/**
 * @param {{
 *     openNewsUid: string;
 * }} props
 */
function NewsList({ openNewsUid }) {
    const { news: newsList, theme:{theme} } = useStore();
    const openNews = newsList.find((news) => news.uid === openNewsUid);
    const [screenWidth] = useWindowSize();

    const slidesPerView = useMemo(() => {
        if (screenWidth <= 700) {
            return 1;
        } else if (screenWidth > 700 && screenWidth < 1000) {
            return 2;
        } else {
            return 4;
        }
    }, [screenWidth]);

    const summaryTextOnly = useMemo(() => {
        if (!openNews) return "";
        const span = document.createElement("span");
        span.innerHTML = openNews.summary;
        return span.innerText;
    }, [openNews]);

    /** @param {News} news */
    const handleSelectNews = (news) => () => {
        history.push(`/news/${news.uid}`);
    };

    return (
        <section className={`${styles.News} ${styles[theme]}`}>
            <Breadcrumbs className={styles.Breadcrumbs}>
                <Link
                    underline="hover"
                    color="primary"
                    onClick={() => history.push("/")}
                >
                    {i18n("Página inicial")}
                </Link>
                {openNews ? (
                    <Typography color="textPrimary" className={styles.titles}>
                        <strong>{openNews.title} -</strong> {summaryTextOnly}
                    </Typography>
                ) : (
                    <Typography color="textPrimary">
                        <strong>{i18n("Portal de notícias")}</strong>{" "}
                    </Typography>
                )}
            </Breadcrumbs>
            {openNews ? (
                <NewsPanel openNews={openNews} />
            ) : newsList.length === 0 ? (
                <div className={styles.NewsNothingFound}>
                    <p>{i18n("Nenhuma notícia encontrada!")}</p>
                </div>
            ) : (
                <Swiper
                    className={styles.NewsSlider}
                    freeMode
                    navigation
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    modules={[FreeMode, Navigation]}
                >
                    {newsList.map((news) => (
                        <SwiperSlide
                            key={news.uid}
                            className={styles.NewsCard}
                            onClick={handleSelectNews(news)}
                        >
                            <div className={styles.NewsCardBanner}>
                                <img
                                    src={news.bannerUrl}
                                    alt={news.title}
                                />
                            </div>
                            <div className={styles.NewsCardInfo}>
                                <p className={styles.NewsCardTitle}>
                                    {news.title}
                                </p>
                                <div
                                    className={styles.NewsCardSummary}
                                    dangerouslySetInnerHTML={{
                                        __html: news.summary,
                                    }}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </section>
    );
}

export default NewsList;
