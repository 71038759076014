import { toDate } from "@functions";

const userModel = {
  email: "",
  name: "",
  uid: ""
}

const eventModel = {
  company: "",
  createdAt: new Date(),
  createdBy: "",
  deleted: false,
  endDate: new Date(),
  initialDate: new Date(),
  text: "",
  title: "",
  uid: "",
  user: userModel
};

/**
 * @typedef Event
 * @type {typeof eventModel}
 */

function createEvent(data) {
  if (!data) data = {};

  return {
    ...eventModel,
    ...data,
    deleted: !!data.delete,
    createdAt: toDate(data.createdAt),
    initialDate: toDate(data.initialDate),
    endDate: toDate(data.endDate),

  };
}

export { eventModel, createEvent };