const SET_SPINNER_STATUS = "LOADING_SET_SPINNER_STATUS";

const service = dispatch => ({
	show: () => {
		dispatch({
			type: SET_SPINNER_STATUS,
			show: true
		});
	},
	hide: () => {
		dispatch({
			type: SET_SPINNER_STATUS,
			show: false
		});
	}
});

const INIT_STATE = {
	show: false
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_SPINNER_STATUS:
			return {
				...state,
				show: action.show
			};

		default:
			return { ...state };
	}
};
export default { reducer, service };
