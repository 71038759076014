import React from "react";

import i18n from "@i18n";
import { useMyFlows } from "./useMyFlows";

import { InputSearch } from "components/inputs";
import { StepIcon } from "components/stepIcon";
import { DetailsFlows } from "components/detailsFlow";
import { SelectCompanyArea, TableFlows } from "./modules";

import Topbar from "@custom/Topbar";
import Icon from "@custom/Icon";

import styles from "./myFlows.module.scss";
import { Button } from "@mui/material";
import { useStore } from "@hooks";
import { Select } from "components";
import { services } from "@store";

export default function MyFlows() {

    const { companyArea, companyAreaSelected, typeCompanyAreaSelected, theme: { theme } } = useStore()

    const {
        filterFlows,
        filterStep,
        selectedType,
        selectedStep,
        openModalDetails,
        executionFlow,
        filterText,
        flowsFilterText,
        typeWorkflow,
        filterWorkflows,
        setTypeWorkflow,
        setFilterText,
        handleChangeType,
        handleChangeStep,
        handleModalDetails,
        handleArchiveFlow,
        handleUnarchiveFlow,
        renderQuantityFlowsStep,
        getListFlows,
        handleChangeBusinessArea
    } = useMyFlows();

    return (
        <main className={styles.container}>
            <Topbar />
            <div className={`${styles.content} ${styles[theme]}`}>
                <h2>{i18n("Bem-vindo ao controle geral de solicitações")}</h2>

                <div className={styles.selectBusinessArea}>

                    <Select
                        title="Area de Negócio"
                        invalidOption
                        onChange={handleChangeBusinessArea}
                        value={companyAreaSelected}
                        itens={companyArea.map(option => ({
                            title: option.name,
                            value: option.uid
                        }))}
                    />

                    <div className={styles.areaSelectButton}>

                        <Select
                            title="Tipo"
                            onChange={(e) =>
                                services.companyArea.changeTypeCompanyAreaSelected(e)}
                            value={typeCompanyAreaSelected}

                            itens={filterWorkflows.map(option => ({
                                title: option.document.title || "Titulo não informado",
                                value: option.uid
                            }))}
                        />

                        <Button
                            onClick={() => getListFlows({ companyAreaUid: companyAreaSelected, type: selectedType })}
                            variant="contained"
                            endIcon={<Icon icon="faSearch" />}
                        />
                    </div>
                </div>

                <InputSearch
                    placeholder={i18n("Busca rápida")}
                    value={filterText}
                    setValue={setFilterText}
                />
                <h3>{i18n("Filtro de solicitações")}</h3>
                <div className={styles.filterType}>
                    {filterFlows.map((flowType) => (
                        <div
                            key={flowType.id}
                            className={`${styles.cardFilterType} ${selectedType === flowType.type && styles.active
                                }`}
                            onClick={() => handleChangeType(flowType.type)}
                        >
                            <span>
                                <Icon icon={flowType.icon} />
                            </span>
                            <p>
                                {i18n("Solicitações")}
                                <strong>{flowType.title}</strong>
                            </p>
                        </div>
                    ))}
                </div>

                <div className={styles.filterStep}>
                    {filterStep.map((step) => (
                        <div
                            key={step.key}
                            className={`${styles.cardStep} ${selectedStep === step.key && styles.active
                                }`}
                            onClick={() => handleChangeStep(step.key)}
                        >
                            <StepIcon icon step={step.key} />
                            <p>
                                {i18n("Solicitações")}{" "}
                                <strong>{step.step}</strong>
                            </p>

                            <strong>{renderQuantityFlowsStep(step.key)}</strong>
                        </div>
                    ))}
                </div>


                <TableFlows
                    currentCompanyAreaUid={companyAreaSelected}
                    openDetails={handleModalDetails}
                    flows={flowsFilterText}
                    selectedType={selectedType}
                    step={selectedStep}
                />

            </div>
            {executionFlow && (
                <DetailsFlows
                    close={handleModalDetails}
                    archive={handleArchiveFlow(executionFlow)}
                    unarchive={handleUnarchiveFlow(executionFlow)}
                    open={openModalDetails}
                    executionFlow={executionFlow}
                    uidTask={null}
                />
            )}

            <SelectCompanyArea getListFlows={getListFlows} />

        </main>
    );
}
