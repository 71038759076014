import { toDate } from "@functions";

const userModel = {
    uid: "",
    user: "",
    taxDocument: "",
    firstName: "",
    lastName: "",
    email: "",
    provider: "email",
    phone: "",
    phoneArea: "",
    phoneCountry: "",
    /** @type {string[]} */
    groups: [],
    /** @type {string[]} */
    active: [],
    /**
     * @type {{
     *   postalCode: string;
     *   street: string;
     *   streetNumber: string;
     *   complementary: string;
     *   district: string;
     *   city: string;
     *   state: string;
     *   country: string;
     * }[]}
     */
    addresses: [],
    /** @type {Date | undefined} */
    birthdate: undefined,
    /** @type {string[]} */
    companies: [],
    companyadmin: false,
    superadmin: false,
    customer: "",
    /**
     * @type {{
     *     [app: string]: {
     *         id: string;
     *         app: string;
     *         companies: {
     *             [licenseUid: string]: boolean;
     *         };
     *         licenses: {
     *             [companyUid: string]: boolean;
     *         };
     *         since: Date;
     *     }
     * } | undefined}
     */
    applications: {},
    /**
     * @type {{
     *     [app: string]: {
     *         end?: Date;
     *         start?: Date;
     *         online: boolean;
     *         product: string;
     *         token: string;
     *     }
     * } | undefined}
     */
    session: undefined,
    /**
     * @type {{
     *     [app: string]: {
     *         machineName: string;
     *         status: "waiting_approvation" | "approved" | "disapproved";
     *         approvedAt?: Date;
     *         disapprovedAt?: Date;
     *         approvationRequestSent?: boolean;
     *         approvationRequestSentAt?: Date;
     *         firstAccessAt?: Date;
     *     };
     * } | undefined}
     */
    accessControl: undefined,
    createdAt: new Date(),
    createdBy: "",
    deleted: false,
};

/**
 * @typedef User
 * @type {typeof userModel}
 */

/** @param {Partial<User>=} data */
function createUser(data) {
    if (!data) data = {};

    if (data.applications) {
        for (const application of Object.values(data.applications)) {
            application.since = toDate(application.since);
        }
    }

    if (data.session) {
        for (const session of Object.values(data.session)) {
            if (!session) continue;
            if (session.start) session.start = toDate(session.start);
            if (session.end) session.end = toDate(session.end);
            session.online = !!session.online;
        }
    }

    if (data.accessControl) {
        for (const appAccessControlConfig of Object.values(
            data.accessControl
        )) {
            if (!appAccessControlConfig) continue;
            if (appAccessControlConfig.approvedAt)
                appAccessControlConfig.approvedAt = toDate(
                    appAccessControlConfig.approvedAt
                );
            if (appAccessControlConfig.disapprovedAt)
                appAccessControlConfig.disapprovedAt = toDate(
                    appAccessControlConfig.disapprovedAt
                );
            if (appAccessControlConfig.approvationRequestSentAt)
                appAccessControlConfig.approvationRequestSentAt = toDate(
                    appAccessControlConfig.approvationRequestSentAt
                );
            if (appAccessControlConfig.firstAccessAt)
                appAccessControlConfig.firstAccessAt = toDate(
                    appAccessControlConfig.firstAccessAt
                );
        }
    }

    return {
        ...userModel,
        ...data,
        companyadmin: !!data.companyadmin,
        superadmin: !!data.superadmin,
        applications: data.applications,
        birthdate: toDate(data.birthdate),
        createdAt: toDate(data.createdAt),
        deleted: !!data.deleted,
    };
}

export { userModel, createUser };
