import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router";

import i18n from "@i18n";
import { useStore } from "@hooks";
import { history, services } from "@store";
import { serviceExecutionFlow } from "@services/executionFlow";
import { serviceCompanyArea } from "@services/companyArea";
import { filterCompanyAreaCollection } from "@firebase-config/collections";

/**
 * @typedef ListType
 * @type {import("@services/executionFlow/list.js").ListType}
 */

/**
 * @typedef Status
 * @type {import("@models/executionFlow").Status}
 */

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/** @type {ListType} */
const defaultType = "iCanSee";
/** @type {Status} */
const defaultStep = "not-completed";

export function useMyFlows() {
    /**  @type {{ id: string }} */
    const { id } = useParams();
    const {
        auth: { user, company },
        executionFlows,
        workflows,
        companyAreaSelected,
        typeCompanyAreaSelected,
    } = useStore();

    /** @type {[ListType, React.Dispatch<React.SetStateAction<ListType>>]} */
    const [selectedType, setSelectedType] = useState(defaultType);
    /** @type {[ListType[], React.Dispatch<React.SetStateAction<ListType[]>>]} */
    const [typeFlowsSearched, setTypeFlowsSearched] = useState(["iCanSee"])
    /** @type {[Status, React.Dispatch<React.SetStateAction<Status>>]} */
    const [selectedStep, setSelectedStep] = useState(defaultStep);
    const [openModalDetails, setOpenModalDetails] = useState(false);
    const [filterText, setFilterText] = useState("");
    /**
     * @type {{
     *     key: Status;
     *     step: string;
     * }[]}
     */
    const filterStep = [
        {
            key: "not-completed",
            step: i18n("em execução"),
        },
        {
            key: "completed",
            step: i18n("completed"),
        },
        {
            key: "canceled",
            step: i18n("canceladas"),
        },
    ];

    /**
     * @type {{
     *     id: number;
     *     title: string;
     *     type: ListType;
     *     icon: string;
     * }[]}
     */
    const filterFlows = [
        {
            id: 4,
            title: i18n("que posso ver"),
            type: "iCanSee",
            icon: "faEye",
        },
        {
            id: 0,
            title: i18n("que sou responsável"),
            type: "responsible",
            icon: "faUserTag",
        },
        {
            id: 1,
            title: i18n("que eu criei"),
            type: "creator",
            icon: "faUser",
        },
        {
            id: 2,
            title: i18n("que participo"),
            type: "participate",
            icon: "faUsers",
        },
        {
            id: 3,
            title: i18n("que estão arquivados"),
            type: "archived",
            icon: "faBoxArchive",
        },

    ];

    const handleFilterType = (type, flow) => {
        if (flow.archived && type !== "archived") {
            return false;
        }

        switch (type) {
            case "iCanSee":
                return !flow.archived;
            case "responsible":
                if (flow?.agentObject?.groups) {
                    return user.groups.some((group) => flow?.agentObject?.groups.includes(group.uid));
                } else {
                    return flow.agent === user.uid;
                }
            case "creator":
                return flow.createdBy === user.uid;
            case "participate":
                if (flow?.agentObject?.groups) {
                    return user.groups.some((group) => flow?.agentObject?.groups.includes(group.uid));
                } else {
                    return flow.participants.includes(user.uid);
                }
            case "archived":
                return flow.archived;
            default:
                return false;
        }

    }

    const filterFlowsCompanyArea = executionFlows.filter((flow) => {

        const companyAreaFilter = flow.workflowObject.division === companyAreaSelected

        if (!typeCompanyAreaSelected) {
            return companyAreaFilter
        }

        return companyAreaFilter && flow.workflow === typeCompanyAreaSelected
    })

    const flowsFilterType = filterFlowsCompanyArea.filter((flow) => {
        return handleFilterType(selectedType, flow)
    });

    const flowsFilterStep = flowsFilterType.filter((flow) => {
        if (typeof flow.completed === "object" && selectedStep === "completed")
            return true;
        return flow.completed === selectedStep;
    });

    const flowsFilterText = flowsFilterStep.filter((flow) => {
        const subjectUpper = flow?.workflowObject?.description.toUpperCase();
        const textUpper = filterText.toUpperCase();

        return subjectUpper.includes(textUpper);
    });

    const executionFlow = useMemo(
        () => executionFlows.find((flow) => flow.uid === id),
        [id, executionFlows]
    );

    const handleModalDetails = () => {
        setOpenModalDetails(!openModalDetails);
        if (openModalDetails) {
            history.push("/my-flows");
        }
    };

    /** @param {ListType} type */
    const handleChangeType = async (type) => {

        const validateFilterType = executionFlows.filter((flow) => {
            return handleFilterType(type, flow)
        });
        const updateTypeFlowsSearched = typeFlowsSearched

        if (!updateTypeFlowsSearched.includes(type)) {
            updateTypeFlowsSearched.push(type)
            setTypeFlowsSearched(updateTypeFlowsSearched)

            await getListFlows({ companyAreaUid: companyAreaSelected, type })

        }

        setSelectedType(type);
    };


    /** @param {Status} step */
    const handleChangeStep = (step) => {
        setSelectedStep(step);
    };

    /** @param {Status} step */
    const renderQuantityFlowsStep = (step) => {
        const quantity = flowsFilterType.filter((flow) => {
            if (typeof flow.completed === "object" && step === "completed")
                return true;
            return flow.completed === step;
        }).length;

        return quantity <= 9 ? `0${quantity}` : quantity;
    };

    /** @param {ExecutionFlow} flow */
    const handleArchiveFlow = (flow) => async () => {
        await serviceExecutionFlow.update(flow.uid, {
            archived: true,
        });
    };

    /** @param {ExecutionFlow} flow */
    const handleUnarchiveFlow = (flow) => async () => {
        await serviceExecutionFlow.update(flow.uid, {
            archived: false,
        });
    };

    async function getListFlows({ companyAreaUid, type }) {

        try {
            if (!company || !user) return;
            services.spinner.show();

            await updateFiltersCompanyArea({ companyAreaUid })
            await services.executionFlows.listMore({
                company: company.uid,
                user: user.uid,
                type: type,
                userGroups: user.groups,
                companyAreaUid,
                typeWorkflow: typeCompanyAreaSelected,
            });
        } catch (error) {
            console.log(error)
        } finally {

            services.spinner.hide();
        }

    }

    async function updateFiltersCompanyArea({ companyAreaUid }) {
        try {
            await serviceCompanyArea.updateFilter(user.uid, {
                id: user.uid,
                createdAt: new Date(),
                companyAreaUid,
                typeCompanyAreaUid: typeCompanyAreaSelected,
                createdBy: user.uid
            })
        }
        catch (error) {
            console.log("Error ao salvar Filtro")
        }

    }

    const filterWorkflows = workflows.filter((work) => {
        return work.division === companyAreaSelected
    })

    function handleChangeBusinessArea(companyAreaUid) {
        setTypeFlowsSearched([selectedType])
        services.companyArea.changeCompanyAreaSelected(companyAreaUid)
        services.companyArea.changeTypeCompanyAreaSelected("")
    }

    useEffect(() => {

        if (!user || companyAreaSelected !== "") return

        async function getFilters() {
            try {

                const docRef = filterCompanyAreaCollection.doc(user.uid)
                const response = await docRef.get()
                const data = response.data()
                if (data) {
                    services.companyArea.changeCompanyAreaSelected(data.companyAreaUid)
                    services.companyArea.changeTypeCompanyAreaSelected(data.typeCompanyAreaUid)
                    await getListFlows({ companyAreaUid: data.companyAreaUid, type: "iCanSee" })
                }

            } catch (error) {

            }
        }

        getFilters()
    }, [user])

    useEffect(() => {

        async function handleGetListFlows() {

            if (!id || executionFlow) return;

            const newExecutionFlow = await serviceExecutionFlow.find({
                executionFlowUid: id,
            });

            if (typeof newExecutionFlow === "string") return;

            services.executionFlows.addToList([newExecutionFlow]);
            setOpenModalDetails(true);
        }
        handleGetListFlows()

        return () => { handleGetListFlows() }

    }, [id, executionFlow])


    useEffect(() => {

        async function handleGetWorkflows() {

            if (workflows.length > 0 && !user) return

            services.spinner.show();

            await services.workflows.list({ company: company.uid })

            services.spinner.hide();
        }
        handleGetWorkflows()

        return () => { handleGetWorkflows() }

    }, [user])

    return {
        filterStep,
        filterFlows,
        filterText,
        flowsFilterText,
        selectedType,
        selectedStep,
        openModalDetails,
        executionFlow,
        filterWorkflows,
        handleChangeType,
        handleChangeStep,
        handleModalDetails,
        handleArchiveFlow,
        handleUnarchiveFlow,
        renderQuantityFlowsStep,
        setFilterText,
        getListFlows,
        setTypeFlowsSearched,
        handleChangeBusinessArea
    }
}