import { companyAreaCollection } from "@firebase-config/collections";
import { createCompanyArea } from "@models";

export async function listCompanyArea({ companyUid, workflows }) {

  if (!companyUid || typeof companyUid !== "string" || !workflows) {
    return "invalid-param-companyUid";
  }

  let query = companyAreaCollection
    .where("company", "==", companyUid)
    .where("deleted", "==", false)
    .orderBy("createdAt", "desc")

  const list = []
  try {

    for (const workflow of workflows) {
      const divisionUid = workflow?.division
      const existCompanyArea = list.find((item) => item.uid === divisionUid)

      const docRef = companyAreaCollection.doc(divisionUid)

      const companyArea = await docRef.get()

      if (!existCompanyArea) {
        list.push(companyArea.data())
      }
    }

    return list.map(createCompanyArea);

  } catch (error) {
    console.log(error)
    return "error-list-executionFlow";
  }

}
