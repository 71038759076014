import React, { useState, useMemo } from "react";
import { Button } from "@mui/material";

import i18n, { format } from "@i18n";
import { useStore } from "@hooks";
import { handleResponsible, renderName, toDate } from "@functions";

import Icon from "@custom/Icon";

import styles from "./detailsFlow.module.scss";
import { HistoryFlow } from "components/historyFlow";
import { Modal } from "components";
import { ModalCancelFlow, AcordionDetails, AcordionPhase } from "./modules";
import { StatusCanceled } from "components/statusCanceled";

export function DetailsFlows({
    open,
    close,
    archive,
    unarchive,
    executionFlow,
    uidTask,
}) {
    const [openModalCancelFlow, setOpenModalCancelFlow] = useState(false);
    const [openModalHistory, setOpenModalHistory] = useState(false);
    const { users, auth, theme: { theme } } = useStore();

    const hideRequester = useMemo(() => {
        return executionFlow?.workflowObject?.hideRequester;
    }, [executionFlow]);

    const renderCancelFalowButton =
        auth?.user?.companyadmin ||
        executionFlow?.workflowObject?.createdBy === auth?.user?.uid

    const optionsDetails = [
        {
            id: 0,
            title: i18n("Documentos"),
            quantity: executionFlow?.documents?.length,
            description: "Quantidade de arquivos",
            icon: <Icon icon="faFolderOpen" />,
        },
        {
            id: 1,
            title: i18n("Fomulários"),
            quantity: executionFlow?.forms?.length,
            description: "Nenhum formulário adicionado",
            icon: <Icon icon="faFileLines" />,
        },
        {
            id: 2,
            title: i18n("Aprovações"),
            quantity: executionFlow?.approvals?.length,
            description: "Nenhum aprovação/reprovação formalizada",
            icon: <Icon icon="faFileCircleCheck" />,
        },
    ];

    const FlowActions = () => {
        const userUid = auth.user.uid

        const myGroupsResponsible =
            auth.user.groups.some((group) => executionFlow?.agentObject?.groups?.includes(group.uid))

        if (
            userUid === executionFlow?.agent ||
            userUid === executionFlow?.createdBy ||
            myGroupsResponsible
        ) {

            return (
                <>
                    {renderCancelFalowButton && executionFlow.completed !== "canceled" && (
                        <Button
                            fullWidth
                            size="small"
                            color="error"
                            variant="contained"
                            onClick={() => setOpenModalCancelFlow(true)}
                        >
                            {i18n("Cancelar fluxo")}
                        </Button>
                    )}

                    <Button
                        fullWidth
                        size="small"
                        color="info"
                        variant="contained"
                        onClick={executionFlow?.archived ? unarchive : archive}
                    >
                        {i18n(executionFlow?.archived ? "Desarquivar" : "Arquivar")}
                    </Button>
                </>
            )
        }
        return <></>

    }

    return (
        <>
            <Modal
                open={open}
                close={close}
                title={i18n("Solicitação em execução. Visualização completa.")}
            >
                <div className={`${styles.container} ${styles[theme]}`}>
                    <div className={styles.contentColuns}>
                        <div className={styles.areaButton} media-type="mobile">
                            <FlowActions />
                        </div>
                        <div className={styles.content}>
                            <AcordionPhase executionFlow={executionFlow} />
                        </div>
                        <div className={styles.contentRight}>
                            <div className={styles.contentInfoExecutionFlow}>
                                <h3>{i18n("FLUXO COMPLETO EM EXECUÇÃO")}</h3>

                                <div className={styles.details}>
                                    <p>
                                        <strong>
                                            {i18n("Área de negócio")}:{" "}
                                        </strong>{" "}
                                        {
                                            executionFlow?.workflowObject
                                                ?.divisionObject?.name
                                        }
                                    </p>
                                    {executionFlow?.workflowObject
                                        ?.description && (
                                            <p>
                                                <strong>{i18n("Assunto")}: </strong>{" "}
                                                {
                                                    executionFlow?.workflowObject
                                                        ?.description
                                                }
                                            </p>
                                        )}
                                    <p>
                                        <strong>{i18n("Responsável")}: </strong>{" "}
                                        {handleResponsible({ executionFlow, users })}
                                    </p>
                                    <p>
                                        <strong>
                                            {i18n("Executado por")}:{" "}
                                        </strong>{" "}
                                        {!hideRequester &&
                                            renderName({
                                                uid: executionFlow?.createdBy,
                                                users,
                                            })}
                                        {hideRequester && i18n("Anônimo")}
                                    </p>
                                    <p>
                                        <strong>
                                            {i18n("Data de criação")}:{" "}
                                        </strong>{" "}
                                        {format.datetime(
                                            toDate(executionFlow?.createdAt)
                                        )}
                                    </p>
                                    {executionFlow?.managementProtocolCode && (
                                        <p>
                                            <strong>
                                                {i18n(
                                                    "Protocolo de gerenciamento"
                                                )}
                                                :{" "}
                                            </strong>{" "}
                                            {
                                                executionFlow?.managementProtocolCode
                                            }
                                        </p>
                                    )}
                                    <p>
                                        <strong>
                                            {i18n("UID do fluxo")}:{" "}
                                        </strong>{" "}
                                        {executionFlow?.uid}
                                    </p>
                                </div>
                                <StatusCanceled status={executionFlow?.completed} />
                                <div>
                                    {optionsDetails.map((details) => (
                                        <AcordionDetails
                                            key={details.id}
                                            icon={details.icon}
                                            summary={details.title}
                                            number={details?.quantity}
                                        />
                                    ))}
                                </div>
                                <div className={styles.gabarito}>
                                    <div>
                                        <p>{i18n("Não iniciada")}</p>
                                    </div>
                                    <div>
                                        <p>{i18n("Aguardando retorno")}</p>
                                    </div>
                                    <div>
                                        <p>{i18n("Em revisão")}</p>
                                    </div>
                                    <div>
                                        <p>{i18n("Pendente")}</p>
                                    </div>
                                    <div>
                                        <p>{i18n("Finalisada")}</p>
                                    </div>
                                </div>
                            </div>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => setOpenModalHistory(true)}
                            >
                                {i18n("histórico")}
                            </Button>

                            <div className={styles.areaButton} media-type="web">
                                <FlowActions />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalCancelFlow
                open={openModalCancelFlow}
                close={() => setOpenModalCancelFlow(false)}
                flowUid={executionFlow?.uid}
                taskUid={uidTask && uidTask}
            />
            <HistoryFlow
                open={openModalHistory}
                close={() => setOpenModalHistory(false)}
                executionFlow={executionFlow}
            />
        </>
    );
}
