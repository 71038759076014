import React from 'react';
import i18n from '@i18n';
import { Typography } from '@mui/material';

const Unauthorized = () => {
	return (
		<section>
			<header>
				<Typography gutterBottom variant="h5" component="h2">
					{i18n('oops.unauthorized')}
				</Typography>
			</header>
		</section>
	);
};

export default Unauthorized;
