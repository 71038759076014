const loginPageConstants = {
	collectame: {
		title: 'Bem-vindo(a) ao Yopen Sign!',
		subtitle: 'Assine documentos online como, quando e onde quiser!'
	},
	'appcenter-customer': {
		title: 'Bem-vindo(a) ao App Center!',
		subtitle: 'Descubra o centro de integração automatizado das soluções Taugor!'
	},
	dokia: {
		title: 'Bem-vindo(a) ao Dok+!',
		subtitle: 'Gestão eletrônica inteligente de documentos'
	},
	workflux: {
		title: 'Bem-vindo(a) ao Workflux!',
		subtitle: 'Gestão de processos, tarefas e workflow em uma única ferramenta'
	},
	'workflux-portal': {
		title: 'Bem-vindo(a) ao Workflux Portal !',
		subtitle: 'Notícias, gestão de processos, tarefas e serviços em uma única ferramenta!'
	}
}

export default loginPageConstants
