import { serviceNews } from "@services/news";

const LIST_COMPANY_NEWS = "LIST_COMPANY_NEWS";
const COMPANY_NEWS_CHANGE = "COMPANY_NEWS_CHANGE";

/**
 * @typedef Params
 * @type {import("./index.js").Params}
 */

const service = (dispatch) => ({
    /** @param {Params} params */
    list: async ({ company }) => {
        const result = await serviceNews.list({ companyUid: company });
        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceNews.watch({
            list,
            callback: (newsList) => {
                dispatch({
                    type: COMPANY_NEWS_CHANGE,
                    payload: { list: newsList },
                });
            },
        });

        dispatch({
            type: LIST_COMPANY_NEWS,
            payload: { company, list },
        });
    },
});

const INIT_STATE = {
    company: "",
    list: [],
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_COMPANY_NEWS:
            return {
                ...state,
                company: action.payload.company,
                list: action.payload.list,
            };

        case COMPANY_NEWS_CHANGE:
            const allNews = [...state.list, ...action.payload.list];

            return {
                ...state,
                list: allNews.reduce((list, newsItem) => {
                    const alreadyAdded = !!list.find(
                        (item) => item.uid === newsItem.uid
                    );
                    if (alreadyAdded) return list;
                    list.push(newsItem);
                    return list;
                }, []),
            };

        default:
            return { ...state };
    }
};
export default { reducer, service };
