import { filterCompanyAreaCollection } from "@firebase-config/collections";
import { createFilterCompanyArea } from "@models";

export async function filterCompanyArea({ userUid }) {

  if (!userUid) {
    return "invalid-param-companyUid";
  }
  try {

    const doc = await filterCompanyAreaCollection.doc(userUid).get()

    return createFilterCompanyArea(doc.data())

  } catch (error) {
    console.log(error)
    return "error-get-filter-user";
  }

}
