import styles from './commentPhase.module.scss'

import { toDate } from "@functions"
import i18n, { format } from "@i18n"
import { Button, IconButton, TextField } from '@mui/material'
import { Modal } from "components"
import { AvatarProfileStatus } from "components/avatar/avatarStatus"
import SendIcon from '@mui/icons-material/Send';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import React, { useState } from "react"
import { useStore } from '@hooks'
import { executionFlowCollection } from '@firebase-config/collections'
import Icon from '@custom/Icon'
import { workfluxStorage } from '@firebase-config'

export function CommentsPhase({ open, close, phaseUid, executionFlow }) {

  const phases = executionFlow.workflowObject.phases;

  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [prevFileUrl, setPrevFileUrl] = useState(null);
  const { auth: { user } } = useStore();
  const [phase, setPhase] = useState(phases.find((phase) => phase.uid === phaseUid))

  const handleChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setFile(file);

      setPrevFileUrl({
        url: URL.createObjectURL(file),
        name: file.name
      });
    }
  }

  const clearFiles = () => {
    setFile(null)
    setPrevFileUrl(null)
  }

  const addNewComment = async () => {

    if (text === "" && !file || text !== "" && !!file) return

    const currentPhase = phase

    if (text !== "") {

      currentPhase?.comments.push({
        content: {
          text: text,
        },
        user: {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          uid: user.uid
        },
        date: new Date(),
        type: "text",
        uid: "algum uid",
      })

    } else {

      const docRef = workfluxStorage.ref(`comment/${executionFlow.uid}/${file.name}`)

      await docRef.put(file);

      const url = await docRef.getDownloadURL();

      const extension = (() => {
        const parts = file.name.split('.');
        return parts[parts.length - 1];
      })();

      currentPhase?.comments.push({
        content: {
          extension,
          name: file.name,
          size: file.size,
          type: file.type,
          url,
        },
        user: {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          uid: user.uid
        },
        date: new Date(),
        type: "file",
        uid: "algum uid",
      })
    }

    try {

      const updatePhases = phases.filter((phase) => phase.uid !== phaseUid)
      setPhase({ ...currentPhase })
      updatePhases.push(currentPhase)
      console.log(updatePhases)

      await executionFlowCollection.doc(executionFlow.uid).update("workflowObject.phases", updatePhases)
    } catch (error) {

      console.log(error)

    } finally {
      clearFiles();
      setText("")
    }
  }

  const handleTypeDoc = (doc) => {
    if (doc.type !== "text") {

      const extension = doc.content.extension

      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        return "img"
      } else {
        return "doc"
      }

    }
    return "text"
  }

  return (
    <Modal open={open} close={close} title={"Chat"}>
      <div className={styles.container}>
        <div className={styles.messages}>
          {phase?.comments?.map((comment) => (
            <div key={comment.uid} className={styles.comment}>
              <AvatarProfileStatus profileUrl={null} userName={comment.user.name} />

              <div>
                <div>
                  <p>{comment.user.name}</p>
                </div>
                <div className={styles.contentMessage}>
                  {handleTypeDoc(comment) === "text" && (<p>{comment?.content?.text}</p>)}
                  {handleTypeDoc(comment) === "img" && (
                    <img src={comment.content.url} alt={comment.content.name} />
                  )}
                  {handleTypeDoc(comment) === "doc" && (
                    <div className={styles.otherDocs}>
                      <p>{comment.content.name}</p>

                      <a href={comment.content.url} target="_blank">
                        <Button
                          variant="contained"
                          size="small"
                        >
                          <Icon icon="faEye" />
                        </Button>
                      </a>
                      <a
                        href={comment.content.url}
                        download>
                        <Button
                          variant="contained"
                          size="small"
                        >
                          <Icon icon="faDownload" />
                        </Button>
                      </a>
                    </div>
                  )}
                </div>

                <p className={styles.date}>{format.datetime(toDate(comment.date))}</p>
              </div>

            </div>
          ))}
        </div>

        {!!prevFileUrl && (
          <div className={styles.prevImg}>
            <img src={prevFileUrl.url} alt={prevFileUrl.name} />
            <Button
              color='error'
              variant='contained'
              size='small'
              onClick={clearFiles}
            >
              Cancelar
            </Button>
          </div>
        )}

        <div className={styles.inputArea}>
          <TextField
            disabled={prevFileUrl}
            className={styles.input}
            fullWidth
            variant="outlined"
            size='small'
            placeholder={i18n("Digite sua mensagem")}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
          <IconButton
            disabled={text !== ""}
          >
            <InsertDriveFileIcon />
            <input type='file'
              disabled={text !== ""}
              onChange={(e) => handleChangeFile(e)}
              className={styles.inputFile} />
          </IconButton>
          <IconButton
            onClick={() => addNewComment()}
            color={prevFileUrl || text !== "" ? "primary" : "default"}
            disabled={!prevFileUrl && text === ""}
          >
            <SendIcon />
          </IconButton>

        </div>
      </div>

    </Modal>

  )
}