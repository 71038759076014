import { serviceExecutedDocuments } from "@services/executedDocument";
import { services } from "@store";
import { Button, Input, Modal } from "components"
import React, { useState } from "react"

export function RenameFile({ open, close, document }) {

  const [title, setTitle] = useState("")
  const [error, setError] = useState(false)

  const handleClose = () => {
    setTitle("")
    close()
  }

  const handleRenameFile = async () => {
    if (title === "") {
      setError(true);
      setTimeout(() => {
        setError(false)
      }, 2000)

      return
    }

    services.spinner.show()

    try {

      await serviceExecutedDocuments.update(document.uid, {
        action: {
          ...document.action,
          instructions: title,
        },
      });

      handleClose()

    } catch (error) {
      
    }
    services.spinner.hide()

  };

  const FooterModal = () => {

    return (
      <>
        <Button
          title="Cancelar"
          color="error"
          onClick={handleClose}
        />
        <Button
          title="Renomear"
          onClick={handleRenameFile}
        />
      </>
    )
  }

  return (
    <Modal
      open={open}
      close={handleClose}
      title="Renomear arquivo"
      footer={<FooterModal />}
    >
      <Input
        error={error}
        setValue={setTitle}
        value={title}
        title="Novo titulo para o arquivo"
      />
    </Modal>
  )
}