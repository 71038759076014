import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useStore } from "@hooks";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function GroupSelect(props) {
    const { groups } = useStore();
    const { fieldConfig, updateFormValues, formValues, formFilled } = props;
    const field = fieldConfig.name;
    const value = formValues[field];

    /** @param {React.ChangeEvent<HTMLInputElement>} evt */
    const handleGroupChange = (evt) => {
        updateFormValues(field, evt.target.value);
    };

    if (formFilled) {
        return (
            <>
                <p>
                    <strong>{field}</strong>
                </p>
                <p>{value}</p>
            </>
        );
    }

    return (
        <FormControl fullWidth className={styles.GroupSelect}>
            <InputLabel>{fieldConfig.name}</InputLabel>
            <Select
                variant="outlined"
                onChange={handleGroupChange}
                value={value}
            >
                {groups.map((group) => (
                    <MenuItem
                        key={group.uid}
                        value={`${group.uid} ${group.name}`}
                    >
                        {group.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default GroupSelect;
export { GroupSelect };
