import { createExecutionFlow } from "@models";
import { executionFlowCollection } from "@firebase-config/collections";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * Function to listen for changes in all execution flows of company
 * @param {{
 *      list: ExecutionFlow[]
 *      callback: (executionFlows: ExecutionFlow[]) => void;
 * }} params
 */
export function watchExecutionFlows({ list, callback }) {
    const executionFlows = [];

    for (const item of list) {
        executionFlowCollection.doc(item.uid).onSnapshot((snapshot) => {
            const updatedExecutionFlow = createExecutionFlow(snapshot.data());
            executionFlows.push(updatedExecutionFlow);
            callback([updatedExecutionFlow]);
        });
    }

    callback(executionFlows);
}
