"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/**
 * @typedef ErrorMsgs
 * @type {{
 *      [field: string]: string[]
 * }}
 **/

function useErrors() {
  /** @type {[ErrorMsgs, React.Dispatch<React.SetStateAction<ErrorMsgs>>]} */
  var _useState = (0, _react.useState)({}),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    errorMsgs = _useState2[0],
    setErrorMsgs = _useState2[1];

  /**
   * @param {string} field 
   * @param {string} msg 
   */
  var addErrorMsg = function addErrorMsg(field, msg) {
    setErrorMsgs(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, (0, _defineProperty2["default"])({}, field, Array.from(new Set([].concat((0, _toConsumableArray2["default"])(prev[field] || []), [msg])))));
    });
  };

  /**
   * @param {string} field 
   * @param {string} msg 
   */
  var removeErrorMsg = function removeErrorMsg(field, msg) {
    setErrorMsgs(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, (0, _defineProperty2["default"])({}, field, (prev[field] || []).filter(function (errorMsg) {
        return errorMsg !== msg;
      })));
    });
  };

  /** @param {string} field */
  var cleanErrors = function cleanErrors(field) {
    setErrorMsgs(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, (0, _defineProperty2["default"])({}, field, []));
    });
  };
  var cleanAllErrors = function cleanAllErrors() {
    setErrorMsgs({});
  };
  var isDisabled = function isDisabled() {
    var fields = Object.keys(errorMsgs);
    var hasError = false;
    fields.forEach(function (field) {
      if (errorMsgs[field].length > 0) {
        hasError = true;
      }
    });
    return hasError;
  };
  var disabled = isDisabled();
  return {
    errorMsgs: errorMsgs,
    addErrorMsg: addErrorMsg,
    removeErrorMsg: removeErrorMsg,
    cleanErrors: cleanErrors,
    cleanAllErrors: cleanAllErrors,
    disabled: disabled
  };
}
var _default = useErrors;
exports["default"] = _default;