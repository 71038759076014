import { useEffect, useRef } from "react";

/**
 * @param {() => Promise<void>} callback
 * @param {any[]=} triggers
 * @returns {void}
 */
function useAsyncEffect(callback, triggers = []) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
        callbackRef.current();

        return () => {callbackRef.current()}
    }, [...triggers]);
}

export default useAsyncEffect;
export { useAsyncEffect };
