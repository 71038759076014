import React, { useState } from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from "@mui/material";

import i18n from "@i18n";
import lacunaPki from "@services/lacunaPki";
import { useAsyncEffect } from "@hooks";

import styles from "./styles.module.scss";

/**
 * @typedef PkiCertificate
 * @type {import("web-pki-fixed").CertificateModel}
 */

/**
 * @param {{
 *     selectedCertificate: string;
 *     setSelectedCertificate: React.Dispatch<React.SetStateAction<string>>
 * }} props
 */
export function CertificatesSelector({
    selectedCertificate,
    setSelectedCertificate,
}) {
    /** @type {[PkiCertificate[], React.Dispatch<React.SetStateAction<PkiCertificate[]>>]} */
    const [certificates, setCertificates] = useState([]);

    useAsyncEffect(async () => {
        const list = await lacunaPki.listCertificates();
        setCertificates(list);
    }, []);

    return (
        <div className={styles.CertificatesSelector}>
            <p>
                {i18n(
                    "Por favor, selecione seu certificado. Esta aprovação requer assinatura digital."
                )}
            </p>
            <FormControl fullWidth variant="outlined">
                <InputLabel>{i18n("Seus certificados")}</InputLabel>
                <MuiSelect
                    onChange={(e) => setSelectedCertificate(e.target.value)}
                    value={selectedCertificate}
                >
                    <MenuItem value="">{i18n("Nenhuma")}</MenuItem>
                    {certificates.map((cert) => (
                        <MenuItem key={cert.thumbprint} value={cert.thumbprint}>
                            <p>
                                <strong>
                                    {cert.pkiBrazil.certificateType} -{" "}
                                    {cert.subjectName}
                                </strong>
                                {cert.issuerName}

                                {cert.validityEnd < new Date() && (
                                    <>
                                        <br />
                                        <strong>{i18n("Expirado")}</strong>
                                    </>
                                )}
                            </p>
                        </MenuItem>
                    ))}
                </MuiSelect>
            </FormControl>
        </div>
    );
}

export default CertificatesSelector;
