"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _react = require("react");
/**
 * 
 * @param {(id: NodeJS.Timeout) => void | Promise<void>} callback 
 * @param {`${number}${"h" | "m" | "s" | "ms"}` | null} cronExpression 
 * @param {any[]=} triggers 
 */
function useInterval(callback, cronExpression) {
  var triggers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  /** @type {React.MutableRefObject<(id: NodeJS.Timeout) => void>} */
  var savedCallback = (0, _react.useRef)(function (id) {
    clearInterval(id);
  });
  var delay = (0, _react.useMemo)(function () {
    if (cronExpression === null) return null;
    var amount = Number(cronExpression.replace(/[a-zA-Z]+/g, ""));
    var timeUnit = cronExpression.replace(String(amount), "");
    var ms = 1;
    var s = 1000 * ms;
    var m = 60 * s;
    var h = 60 * m;
    if (timeUnit === "h") return amount * h;
    if (timeUnit === "m") return amount * m;
    if (timeUnit === "s") return amount * s;
    if (timeUnit === "ms") return amount * ms;
    return amount;
  }, [cronExpression]);
  (0, _react.useEffect)(function () {
    savedCallback.current = callback;
  }, [callback]);
  (0, _react.useEffect)(function () {
    if (delay === null) {
      return;
    }
    var id = setInterval(function () {
      savedCallback.current(id);
    }, delay);
    return function () {
      return clearInterval(id);
    };
  }, [delay].concat((0, _toConsumableArray2["default"])(triggers)));
}
var _default = useInterval;
exports["default"] = _default;