import { useState, useEffect } from "react";

/** @returns {[width: number, height: number]} */
function useWindowSize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleUpdateSizeValues = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        handleUpdateSizeValues();
        return () => {handleUpdateSizeValues()}
       
    }, [])

    useEffect(() => {
        window.addEventListener(
            "resize",
            handleUpdateSizeValues
        );
        return () =>
            window.removeEventListener(
                "resize",
                handleUpdateSizeValues
            );
    }, []);

    return [width, height];
}

export default useWindowSize;
export { useWindowSize };