import styles from "./acordion.module.scss";
import React, { useMemo, useState } from "react";
import Icon from "@custom/Icon";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
} from "@mui/material";
import { CommentsPhase, DetailTaskCompleted, InfoTask } from "./modules";
import i18n from "@i18n";
import { useStore } from "@hooks";
import { Button } from "components/ui";


export function AcordionPhase({ executionFlow }) {

    const phases = executionFlow?.workflowObject?.phases
    const currentPhase = executionFlow?.currentPhase

    const [taskSelected, setTaskSelected] = useState(null);
    const [phaseComment, setPhaseComment] = useState("")

    const [openModal, setOpenModal] = useState({
        detailTaskCompleted: false,
        infoTask: false,
        comments: false
    });
    const { theme: { theme } } = useStore()

    const handleVisibilityModal = ({ prop, value }) => {
        setOpenModal({ ...openModal, [prop]: value });
    };

    const handleModal = ({ task, prop, value }) => {
        setTaskSelected(task);
        handleVisibilityModal({ prop, value });
    };

    const signatureReaction = useMemo((phase) => {
        return phase?.reactions.find(
            (reaction) => reaction.type.type === "start-collectame-process"
        );
    }, []);

    const signatureAction = useMemo(
        (phase) => {
            return phase?.actions.find(
                (action) => action.uid === signatureReaction?.originDocument
            );
        },
        [signatureReaction]
    );

    const signatureProcessUid = useMemo(() => {
        return signatureReaction?.signatureProcess;
    }, [signatureReaction]);

    const renderStepCompletedTasksPhaseColor = (phase) => {
        const step = phase?.status
        if (step === "deactivated-phasejump") {
            const completed = phase?.actions.find(task => task?.status !== "completed")
            if (completed) {
                return "completed"
            }
        }

        return step
    }

    if (executionFlow) {

        return (
            <div>
                {phases?.map((phase, index) => (
                    <Accordion
                        key={index}
                        style={{
                            boxShadow: "none",
                            border: "none",
                            marginBottom: 8,
                            background: theme === "dark" ? "#2e3646" : "#EAE9EE",
                        }}

                    >
                        <div
                            className={`${styles.acordionSummary} ${styles[theme]}`}
                            step-flow={
                                phase?.status
                                    ? renderStepCompletedTasksPhaseColor(phase)
                                    : currentPhase === phase?.uid
                                        ? "pending"
                                        : "notCompleted"
                            }
                        >
                            {console.log(phase)}
                            <AccordionSummary>
                                <div className={styles.summary}>
                                    <p>{phase?.name}</p>

                                    <div className={styles.phaseAction}>
                                        {executionFlow?.workflowObject?.allowComments && (

                                            <Button
                                                buttonIcon
                                                icon={<Icon icon="faCommentDots" />}
                                                color={phase.comments.length > 0 ? "primary" : "default"}
                                                onClick={() => {
                                                    setPhaseComment(phase.uid)
                                                    handleModal({
                                                        task: null,
                                                        prop: "comments",
                                                        value: true,
                                                    })

                                                }}
                                            />

                                        )}
                                        <p>
                                            {String(
                                                phase?.actions?.filter(
                                                    (action) => !action?.deleted
                                                )?.length
                                            ).padStart(2, "0")}
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                        </div>
                        {phase?.actions
                            ?.filter((action) => !action?.deleted)
                            ?.map((action) => (
                                <AccordionDetails
                                    key={action.uid}
                                    className={`${styles.taskContainer} ${styles[theme]}`}
                                >
                                    <div
                                        className={styles.task}
                                        step-task={
                                            action?.status
                                                ? action?.status
                                                : currentPhase === phase?.uid
                                                    ? "pending"
                                                    : "notCompleted"
                                        }
                                    >
                                        <div>
                                            <Icon
                                                icon={
                                                    action?.status === "completed"
                                                        ? "faCheckCircle"
                                                        : "faCircle"
                                                }
                                                className={styles.icon}
                                            />
                                            <p>
                                                {action?.formTitle ||
                                                    action?.instructions ||
                                                    i18n("título vazio")}{" "}
                                                {signatureAction?.uid === action.uid
                                                    ? "Assinado"
                                                    : "Não assinado"}
                                            </p>
                                        </div>
                                        <div>
                                            <Button
                                                buttonIcon
                                                icon={<Icon icon="faCircleInfo" />}
                                                onClick={() =>
                                                    handleModal({
                                                        task: action,
                                                        prop: "infoTask",
                                                        value: true,
                                                    })
                                                }
                                            />
                                            {action?.status === "completed" && (
                                                <Button
                                                    buttonIcon
                                                    icon={<Icon icon="faEye" />}
                                                    onClick={() =>
                                                        handleModal({
                                                            task: action,
                                                            prop: "detailTaskCompleted",
                                                            value: true,
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            ))}
                    </Accordion>
                ))}


                {executionFlow.completed === "canceled" && (
                    <Accordion
                        style={{
                            boxShadow: "none",
                            border: "none",
                            marginBottom: 8,
                            background: theme === "dark" ? "#2e3646" : "#EAE9EE",
                        }}
                    >
                        <div
                            className={`${styles.acordionSummary} ${styles[theme]}`}
                            step-flow="canceled"
                        >
                            <AccordionSummary>
                                <div className={styles.summary}>
                                    <p style={{ color: "#e75555" }}>
                                        {i18n("Solicitação cancelada")}
                                    </p>
                                </div>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails className={styles.taskContainer}>
                            <div
                                className={styles.task}
                                step-task="canceled"
                            >
                                <div>
                                    <Icon
                                        icon={"canceled"}
                                        className={styles.icon}
                                    />
                                    <p
                                        style={{ color: theme === "dark" ? "#FFF" : "" }}
                                    >
                                        <strong>{i18n("Motivo:")} {" "}</strong>
                                        {executionFlow?.cancelMessage || "Motivo não informado"}
                                    </p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}

                {openModal.comments && (
                    <CommentsPhase
                        phaseUid={phaseComment}
                        executionFlow={executionFlow}
                        open={openModal.comments}
                        close={() =>
                            handleModal({
                                task: null,
                                prop: "comments",
                                value: false,
                            })}
                    />
                )}


                {openModal.detailTaskCompleted && !!taskSelected && (
                    <DetailTaskCompleted
                        taskUid={taskSelected.task}
                        open={openModal.detailTaskCompleted}
                        close={() =>
                            handleModal({
                                task: null,
                                prop: "detailTaskCompleted",
                                value: false,
                            })
                        }
                    />
                )}
                {openModal.infoTask && !!taskSelected && (
                    <InfoTask
                        taskUid={taskSelected.task}
                        open={openModal.infoTask}
                        close={() =>
                            handleModal({
                                task: null,
                                prop: "infoTask",
                                value: false,
                            })
                        }
                    />
                )}
            </div>
        )
    }
}

