// @ts-nocheck
import React, { useState } from "react";
import styles from "./filterTaskDate.module.scss";
import i18n from "@i18n";
import {
    TextField,
} from "@mui/material";

import { ButtonLoading } from "components/buttons/buttonLoading";
import { useInbox } from "@screens/Inbox/contexts/inboxContext";
import { useStore } from "@hooks";
import { services } from "@store";
import { Checkbox, Input, Modal, Select } from "components";

const optionsDate = [
    { id: 0, title: "Hoje", value: "today" },
    { id: 1, title: "Esta semana", value: "week" },
    { id: 2, title: "Este mês", value: "month" },
    { id: 3, title: "Ultimos 30 dias", value: "one_month" },
    { id: 4, title: "Ultimos 90 dias", value: "three_month" },
];

export function FilterTaskDate({ open, close }) {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorDate, setErrorDate] = useState(false)

    const { searchAndFilterTasks } = useInbox()

    const { filters } = useStore()


    const handleFilterTask = async () => {

        if (filters.dateSelected === "") {
            setErrorDate(true)
            setTimeout(() => {
                setErrorDate(false)
            }, 2000)
            return
        }

        setLoading(true)
        await searchAndFilterTasks({ taskTypes: filters, search, date: filters.dateSelected, close })
        setLoading(false)
    }

    const handleChangeType = (type) => {

        services.filters.changeTypeSelected({
            ...filters.typeSelected,
            [type]: !filters.typeSelected[type],
        })

    }

    return (
        <Modal open={open} close={close} title={i18n("Preenche o filtro para buscar")}>

            <div className={styles.optionsFilter}>
                <div className={styles.inputs}>
                    <Input
                        setValue={setSearch}
                        title="Titulo"
                        value={search}
                    />

                    <Select
                        onChange={(e) =>
                            services.filters.changeDateSelected(e)
                        }
                        title="Selecione um prazo"
                        value={filters.dateSelected}
                        error={errorDate}

                        itens={
                            optionsDate.map(option => ({
                                value: option.id,
                                title: option.title
                            }))}
                    />
                </div>
                <div className={styles.cheboxArea}>
                    <Checkbox
                        label="Envio de documento"
                        checked={filters.typeSelected.document}
                        onChange={() => handleChangeType("document")}
                    />
                    <Checkbox
                        label="Preenchimento de Formulário"
                        checked={filters.typeSelected.form}
                        onChange={() => handleChangeType("form")}
                    />
                    <Checkbox
                        label="Aprovação"
                        checked={filters.typeSelected.approval}
                        onChange={() => handleChangeType("approval")}
                    />

                    <ButtonLoading
                        size="small"
                        variant="contained"
                        loading={loading}
                        onClick={handleFilterTask}
                    >
                        Buscar
                    </ButtonLoading>
                </div>
            </div>

        </Modal>
    );
}
