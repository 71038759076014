"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var randInterval = function randInterval( /** @type {number} */min, /** @type {number} */max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
var customSizeUid = function customSizeUid() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  var date = new Date();
  var rand = function rand() {
    return Math.floor(Math.random() * 3000) + 1;
  };
  var a = date.getTime().toString(16);
  date.setDate(date.getDate() + rand());
  var b = date.getTime().toString(16);
  date.setDate(date.getDate() + rand());
  var c = date.getTime().toString(16);
  date.setDate(date.getDate() + rand());
  var d = date.getTime().toString(16);
  date.setDate(date.getDate() + rand());
  var e = date.getTime().toString(16);
  var f = prefix + a + "-" + b + "-" + c + "-" + d + "-" + e;
  var length = f.length;
  for (var i = 0; i < length - 5; i++) {
    var index = randInterval(0, length);
    f = f.substr(0, index) + f.substr(index, 1).toUpperCase() + f.substr(index + 1);
  }
  return f.slice(0, size);
};
var _default = customSizeUid;
exports["default"] = _default;