import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@mui/material";

import { Checkbox, Input } from "components";

export const RenderEntityField = ({ field, handleFieldChange }) => {
    const [value, setValue] = useState("");
    const [helperText, setHelperText] = useState("");

    const updateState = ({ target }) => {
        const stateValue = target.value;

        field.value = stateValue;
        setValue(stateValue);
        setHelperText(field.required && !stateValue ? "Campo obrigatório" : "");
        handleFieldChange(field.uid, stateValue);
    };

    useEffect(() => {
        setValue(field.value);

        return () => { }
    }, [field.value]);

    const singleLineField = () => (
        <Input
            title={field.title}
            value={value}
            onChange={updateState}
            error={field.required && !field.value}
            required={field.required}
        />
        // <TextField
        //     fullWidth
        //     style={{ marginBottom: "10px" }}
        //     variant="outlined"
        //     label={field.title}
        //     value={value}
        //     onChange={updateState}
        //     helperText={helperText}
        //     error={field.required && !field.value}
        //     required={field.required}
        // />
    );

    const multipleLineField = () => (
        <Input
            title={field.title}
            value={value}
            onChange={updateState}
            error={field.required && !field.value}
            required={field.required}
            multiline
        />
        // <TextField
        //     fullWidth
        //     style={{ marginBottom: "10px" }}
        //     variant="outlined"
        //     label={field.title}
        //     value={value}
        //     onChange={updateState}
        //     helperText={helperText}
        //     error={field.required && !field.value}
        //     required={field.required}
        //     multiline
        // />
    );

    const numberField = () => (
        <Input
            title={field.title}
            value={value}
            onChange={updateState}
            error={field.required && !field.value}
            required={field.required}
            multiline
            type="number"
        />
        // <TextField
        //     fullWidth
        //     style={{ marginBottom: "10px" }}
        //     variant="outlined"
        //     type="number"
        //     label={field.title}
        //     value={value}
        //     onChange={updateState}
        //     helperText={helperText}
        //     error={field.required && !field.value}
        //     required={field.required}
        // />
    );

    const currencyField = () => (
        <Input
            title={field.title}
            value={value}
            onChange={updateState}
            error={field.required && !field.value}
            required={field.required}
            type="number"
        />
        // <TextField
        //     fullWidth
        //     style={{ marginBottom: "10px" }}
        //     variant="outlined"
        //     type="number"
        //     label={field.title}
        //     value={value}
        //     onChange={updateState}
        //     helperText={helperText}
        //     error={field.required && !field.value}
        //     required={field.required}
        // />
    );

    const dateField = () => (

        <Input
            type="date"
            label={field.title}
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={updateState}
            helperText={helperText}
            error={field.required && !field.value}
            required={field.required}
        />
        // <TextField
        //     fullWidth
        //     style={{ marginBottom: "10px" }}
        //     variant="outlined"
        //     type="date"
        //     label={field.title}
        //     InputLabelProps={{ shrink: true }}
        //     value={value}
        //     onChange={updateState}
        //     helperText={helperText}
        //     error={field.required && !field.value}
        //     required={field.required}
        // />
    );

    const datetimeField = () => (
        <Input
            type="datetime-local"
            label={field.title}
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={updateState}
            helperText={helperText}
            error={field.required && !field.value}
            required={field.required}
        />
        // <TextField
        //     fullWidth
        //     style={{ marginBottom: "10px" }}
        //     variant="outlined"
        //     type="datetime-local"
        //     label={field.title}
        //     InputLabelProps={{ shrink: true }}
        //     value={value}
        //     onChange={updateState}
        //     helperText={helperText}
        //     error={field.required && !field.value}
        //     required={field.required}
        // />
    );

    const checkboxField = () => (
        <Checkbox
            checked={!!value}
            onChange={(e) =>
                updateState({ target: { value: e.target.checked } })
            }
            label={field.title}
        />
        // <FormControlLabel
        //     control={
        //         <Checkbox
        //             checked={!!value}
        //             onChange={(e) =>
        //                 updateState({ target: { value: e.target.checked } })
        //             }
        //         />
        //     }
        //     label={field.title}
        // />
    );

    const singleSelectionField = () => (
        <Autocomplete
            id="tags-standard"
            options={field.options}
            getOptionLabel={(option) => option.name || ""}
            defaultValue={field.defaultValue}
            renderInput={(params) => (
                <Input
                    fullWidth
                    {...params}
                    label={field.title}
                    className="autocomplete"
                    placeholder={field.title}
                    InputLabelProps={{ shrink: true }}
                />
            )}
            onChange={(e, newValue) => {
                updateState({ target: { value: newValue } });
            }}
        />
    );

    const multiSelectionField = () => (
        <Autocomplete
            multiple
            id="tags-standard"
            options={field.options}
            // @ts-ignore
            getOptionLabel={(option) => option?.name || ""}
            defaultValue={
                field.defaultValue ? [{ name: field.defaultValue }] : []
            }
            renderInput={(params) => (
                <Input
                    {...params}
                    label={field.title}
                    placeholder={field.title}
                    className="autocomplete"
                />
            )}
            onChange={(e, newValue) => {
                updateState({ target: { value: newValue } });
            }}
        />
    );

    const renderFieldByType = () => {

        switch (field.type) {
            case "entity.form.field.types.singleLine":
                return singleLineField();
            case "entity.form.field.types.multipleLine":
                return multipleLineField();
            case "entity.form.field.types.number":
                return numberField();
            case "entity.form.field.types.currency":
                return currencyField();
            case "entity.form.field.types.date":
                return dateField();
            case "entity.form.field.types.datetime":
                return datetimeField();
            case "entity.form.field.types.checkbox":
                return checkboxField();
            case "entity.form.field.types.singleSelection":
                return singleSelectionField();
            case "entity.form.field.types.multiSelection":
                return multiSelectionField();
            default:
                return <></>;
        }
    };

    return <div style={{ marginBottom: 16 }}>{renderFieldByType()}
    </div>;
};
