import React from "react"
import styles from './styles.module.scss'

import { Modal as ModalMui } from "@mui/material"
import { useStore } from "@hooks"
import { Button } from "../button/button"

/**
 *
 * @param {{
*     close: () => void;
*     children: any
*     open: boolean
*     title: string
*     notCloseClick?: boolean
*     footer?: import("react").ReactNode
* }} props
*/

export function Modal({ close, open, children, title, notCloseClick, footer }) {

  const { theme: { theme } } = useStore()

  return (
    <ModalMui onClose={notCloseClick ? () => { } : close} open={open}>

      <div className={`${styles.contentModal} ${styles[theme]}`}>
        <div className={styles.headerModal}>
          <strong>{title}</strong>
          {!notCloseClick && (
            <Button buttonClose onClick={close} />
          )}
        </div>

        <div className={styles.bodyModal}>
          {children}
        </div>

        {footer && (

          <div className={styles.footer}>
            {footer}
          </div>
        )}
      </div>

    </ModalMui>
  )
}