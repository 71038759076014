import React from "react";
import styles from "./avatar.module.scss";
import { AvatarProfileStatus } from "../avatarStatus";


export function AvatarProfileAndInfo({
  userName,
  profileUrl,
  email,
}) {
  return (
    <section className={styles.avataProfileAndInfo}>
      <AvatarProfileStatus userName={userName} profileUrl={profileUrl} />
      <section>
        <strong>{userName}</strong>
        <p>{email}</p>
      </section>
    </section>
  );
}