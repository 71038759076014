import { findExecutedDocument } from "./find";
import { listExecutedDocument } from "./list";
import { watchExecutedDocuments } from "./watch";
import { updateExecutedDocument } from "./update";
import { findByActionExecutedDocument } from "./findByAction";

export const serviceExecutedDocuments = {
    find: findExecutedDocument,
    list: listExecutedDocument,
    watch: watchExecutedDocuments,
    update: updateExecutedDocument,
    findByAction: findByActionExecutedDocument,
};
