import { useSelector } from "react-redux";
// @ts-ignore
import { store } from "zerocode";

/**
 * @typedef Localization
 * @type {import("@apis/ipLocalApi").Localization}
 */

/**
 * @typedef Company
 * @type {{
 *     uid: string;
 *     fantasyName: string;
 * }}
 */

/**
 * @typedef Address
 * @type {{
 *     postalCode: string;
 *     street: string;
 *     streetNumber: number;
 *     district: string;
 *     city: string;
 *     state: string;
 * }}
 */

/**
 * @typedef User
 * @type {{
 *    uid: string;
 *    firstName: string;
 *    lastName: string;
 *    photo?: string;
 *    email: string;
 *    active: string[];
 *    companies: string[];
 *    currentCompany: Company;
 *    companyadmin: boolean;
 *    phone: string;
 *    addresses: Address[];
 *    birthdate: string
 *    groups: any
 *    phoneCountry: string,
 *    phoneArea: string
 * }}
 */

/**
 * @typedef Group
 * @type {{
 *    uid: string;
 *    name: string;
 *    members: string[];
 * }}
 */

/**
 * @typedef News
 * @type {import("@models/news").News}
 */

/**
 * @typedef Task
 * @type {import("@models/task").Task}
 */

/**
 * @typedef Document
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * @typedef Workflow
 * @type {import("@models/workflow").Workflow}
 */

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * @typedef MessageProps
 * @type {import("@custom/Message").MessageProps}
 */

/**
 * @typedef ToastOptions
 * @type {import("@store/modules/toast").ToastOptions}}
 */

/**
 * @returns {{
 *     auth: {
 *         isAuthenticated: boolean;
 *         user: User;
 *         company: Company;
 *     };
 *     spinner: {
 *         show: boolean;
 *     };
 *     pageSpinner: {
 *         show: boolean;
 *     };
 *     message: MessageProps;
 *     users: User[];
 *     allUsers: User[];
 *     activeUsers: User[];
 *     groups: Group[];
 *     news: News[];
 *     documents: Document[];
 *     workflows: Workflow[];
 *     tasks: Task[]
 *     executionFlows: ExecutionFlow[];
 *     toast: ToastOptions;
 *     language: string;
 *     ipLocal: Localization;
 *     companyArea: any
 *     companyAreaSelected: string
 *     typeCompanyAreaSelected: string
 *     filters: {
 *      dateSelected: string,
*       typeSelected: {
*           form: boolean,
*           approval: boolean,
*           document: boolean,
*       }
*  }
 *       theme: any
 * }}
 */
function useStore() {
    const {
        // @ts-ignore
        auth,
        // @ts-ignore
        spinner,
        // @ts-ignore
        pageSpinner,
        // @ts-ignore
        message,
        // @ts-ignore
        news,
        // @ts-ignore
        documents,
        // @ts-ignore
        workflows,
        // @ts-ignore
        executionFlows,
        // @ts-ignore
        tasks,
        // @ts-ignore
        toast,
        // @ts-ignore
        language,
        // @ts-ignore
        ipLocal,
        // @ts-ignore
        companyArea,
        // @ts-ignore
        filters,
        // @ts-ignore
        theme,

    } = useSelector((state) => state);
    const zerocodeState = store.getState();

    /** @type {User[]} */
    const allUsers = zerocodeState.appcenter.users;
    const activeUsers = allUsers.filter((user) => {
        const { currentCompany, active = [], companies = [] } = user;
        const isActive =
            active.includes(companies[0]) ||
            (currentCompany && active.includes(currentCompany.uid));

        return isActive;
    });

    return {
        auth,
        spinner,
        pageSpinner,
        message,
        allUsers,
        activeUsers,
        users: activeUsers,
        groups: zerocodeState.appcenter.groups,
        news: news.list,
        documents: documents.list,
        workflows: workflows.list,
        executionFlows: executionFlows.list,
        tasks: tasks.list,
        toast,
        language: language.current,
        ipLocal,
        companyArea: companyArea.list,
        companyAreaSelected: companyArea.companyAreaSelected,
        typeCompanyAreaSelected: companyArea.typeCompanyAreaSelected,
        filters,
        theme: theme
    };
}

export default useStore;
export { useStore };
