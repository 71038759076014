import { toDate } from "@functions";

const executedDocumentModel = {
    uid: "",
    company: "",
    phase: "",
    workflow: "",
    origin: "",
    location: "",
    agent: "",
    executionFlow: "",
    security: "private",
    action: {
        uid: "",
        instructions: "",
        documentInputDescription: ""
    },
    /**
     * @type {{
     *     uid: string;
     *     name: string;
     *     email: string;
     *     photo?: string;
     *     times: number;
     *     date: Date;
     * }[]}
     */
    accesses: [],
    deleted: false,
    createdAt: new Date(),
    createdBy: ""
};

/**
 * @typedef ExecutedDocument
 * @type {typeof executedDocumentModel}
 */

function createExecutedDocument(data) {
    if (!data) data = {};

    return {
        ...executedDocumentModel,
        ...data,
        action: {
            ...executedDocumentModel.action,
            ...data.action,
        },
        accesses: (data.accesses || []).map((access) => ({
            ...access,
            date: toDate(access.date)
        })),
        deleted: !!data.deleted,
        createdAt: toDate(data.createdAt)
    };
}

export { executedDocumentModel, createExecutedDocument };