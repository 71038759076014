import React, { useEffect, useMemo, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TablePagination,
} from "@mui/material";
import { StepIcon } from "components/stepIcon";
import { useStore } from "@hooks";
import i18n, { format } from "@i18n";
import { handleResponsible, toDate } from "@functions";
import { history, services } from "@store";
import styles from "./table.module.scss";

export function TableFlows({ step, openDetails, flows, selectedType, currentCompanyAreaUid }) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const {
        users,
        auth: { user, company },
        theme: { theme }
    } = useStore();

    useEffect(() => {
        setPage(0);
        return () => { }
    }, [flows]);

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((element, index) => [element, index]);

        return stabilizedThis
            .sort((a, b) => {
                const order = comparator(a[0], b[0]);

                if (order !== 0) return order;

                return a[1] - b[1];
            })
            .map((element) => element[0]);
    };

    const loadMoreFlows = async () => {
        const lastFlow = flows[flows.length - 1];
        services.spinner.show()
        await services.executionFlows.listMore({
            companyAreaUid: currentCompanyAreaUid,
            company: company?.uid,
            user: user?.uid,
            userGroups: user.groups,
            type: selectedType,
            lastDoc: lastFlow?.uid,
        });
        services.spinner.hide()
    };

    const openDetailsFlows = (uid) => {
        openDetails();
        history.push(`/my-flows/${uid}`);
    };

    const sorteredFlows = useMemo(() => {
        return stableSort(flows, getComparator("desc", "createdAt")).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [flows, page]);

    return (
        <TableContainer component={Paper} className={`${styles.table} ${styles[theme]}`}>
            <Table size="small">
                <TableHead className={styles.tHead}>
                    <TableRow>
                        <TableCell className={styles.title}>
                            {i18n("Nome da Solicitação")}
                        </TableCell>
                        {step === "canceled" &&
                            (<>
                                <TableCell className={styles.title}>
                                    {i18n("Etapa Atual")}
                                </TableCell>
                                <TableCell className={styles.title}>
                                    {i18n("Motivo do Cancelamento")}
                                </TableCell>
                                <TableCell className={styles.title}>
                                    {i18n("Cancelado por")}
                                </TableCell>
                            </>
                            )}

                        <TableCell className={styles.title}>
                            {i18n("Criado em")}
                        </TableCell>
                        <TableCell className={styles.title}>
                            {i18n("Responsável")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sorteredFlows.length > 0 ? (
                        sorteredFlows?.map((executionFlow) => (
                            <TableRow
                                className={styles.row}
                                key={executionFlow?.uid}
                                onClick={() =>
                                    openDetailsFlows(executionFlow.uid)
                                }
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    className={styles.colunm}
                                >
                                    <div className={styles.tableIcon}>
                                        <StepIcon
                                            icon
                                            step={executionFlow?.completed}
                                        />
                                        {executionFlow?.workflowObject?.description}
                                    </div>
                                </TableCell>
                                {step === "canceled" && (
                                    <>
                                        <TableCell className={`${styles.colunm} ${styles.colunmCancel}`}>
                                            {i18n("Fluxo Cancelado")}
                                        </TableCell>
                                        <TableCell className={styles.colunm}>
                                            {executionFlow.cancelMessage || "Motivo não informado"}
                                        </TableCell>
                                        <TableCell className={styles.colunm}>
                                            {executionFlow?.canceledBy && (
                                                <p>{executionFlow?.canceledBy?.user}</p>
                                            )}
                                        </TableCell>
                                    </>
                                )}


                                <TableCell className={styles.colunm}>
                                    {format.datetime(
                                        toDate(executionFlow?.createdAt)
                                    )}
                                </TableCell>
                                <TableCell className={styles.colunm}>
                                    {handleResponsible({ executionFlow, users })}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <div className={styles.notFoundFlow}>
                            <p>
                                {i18n(
                                    "Nenhuma solicitação corresponde ao filtro"
                                )}
                            </p>
                        </div>
                    )}
                </TableBody>
            </Table>

            <div className={styles.tFooter}>
                <TablePagination
                    component="div"
                    count={flows.length}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
                    page={page}
                    onPageChange={(_, newPage) => setPage(newPage)}
                    sx={{
                        color: theme === "dark" ? "white" : ""
                    }}
                    slotProps={{
                        actions: {
                            previousButton: {
                                style: {
                                    color: theme === "dark" ? "white" : ""
                                }
                            },
                            nextButton: {
                                style: {
                                    color: theme === "dark" ? "white" : ""
                                }
                            }
                        }
                    }}

                />
            </div>
        </TableContainer>
    );
}
