import styles from "./formForm.module.scss";
import React, { useMemo, useState, useEffect } from "react";

import i18n from "@i18n";
import { history, services } from "@store";
import { useAsyncEffect, useStore } from "@hooks";
import { workfluxApi } from "@apis";
import { getFieldListFromString } from "@functions";
import { serviceSigning } from "@services/signing";
import lacunaPki from "@services/lacunaPki";
import {
    Title,
    Subtitle,
    Link,
    Table,
    UserSelect,
    GroupSelect,
    Checkbox,
    Select,
    RichText,
    Input,
} from "./components/";
import { ButtonLoading } from "components/buttons/buttonLoading";

import CertificatesSelector from "@custom/CertificatesSelector";

const TASKS_STATUS_VIEW = ["completed", "review", "removed"];

export function FormForm({ task, clearTask }) {
    const [formValues, setFormValues] = useState({});
    const [selectedCertificate, setSelectedCertificate] = useState("");
    const [signing, setSigning] = useState(undefined);
    const [sendingForm, setSendingForm] = useState(false);
    const { auth } = useStore();

    const { formFields = "" } = task?.action || {};
    const fields = getFieldListFromString(formFields);

    const components = {
        title: Title,
        subtitle: Subtitle,
        link: Link,
        table: Table,
        user: UserSelect,
        systemUser: UserSelect,
        group: GroupSelect,
        systemGroup: GroupSelect,
        checkbox: Checkbox,
        select: Select,
        richtext: RichText,
    };

    const executedForm = useMemo(() => {
        return task?.action?.executedForm || {};
    }, [task]);

    const signatureRequired = useMemo(() => {
        if (!task) return false;

        for (const phase of task?.workflowObject?.phases) {
            for (const reaction of phase.reactions) {
                if (reaction.originForm === task.action.uid) {
                    if (
                        reaction.type.type === "documentOutput" &&
                        reaction.requireApprovalDocumentSign
                    ) {
                        return true;
                    }
                }
            }
        }

        return false;
    }, [task]);

    const formFilled = useMemo(() => {
        return TASKS_STATUS_VIEW.includes(task?.status) && !!executedForm;
    }, [task, executedForm]);

    useEffect(() => {
        if (signatureRequired) {
            lacunaPki.init().then(console.log).catch(console.log);
        }
        return () => {};
    }, [signatureRequired]);

    /**
     * @param {string} prop
     * @param {any} value
     */
    const updateFormValues = (prop, value) => {
        setFormValues((prev) => ({
            ...prev,
            [prop]: value,
        }));
    };

    const handleSendForm = async () => {
        // const values = Object.keys(formValues)

        // for (const value of values) {
        //     if (
        //         typeof formValues[value] === "string" && formValues[value].trim() === "" ||
        //         Array.isArray(formValues[value]) && formValues[value].length === 0
        //     ) {

        //         if (
        //             value !== "Urgente?" &&
        //             value !== "Objeto da licitação" &&
        //             value !== "Digitalização de documentos" &&
        //             value !== "Software" &&
        //             value !== "Guarda de Documentos"
        //         ) {

        //             console.log("Analise valores", value)
        //             services.message.error({ title: "Preencha todos os campos" })
        //             return
        //         }
        //     }
        // }

        if (signatureRequired && !selectedCertificate) {
            return services.toast.error(
                i18n("Por favor, selecione o certificado para a assinatura")
            );
        }

        services.spinner.show();

        setSendingForm(true);

        try {
            const signing = await workfluxApi.tasks.formInput({
                task: task,
                form: formValues,
                thumbprint: selectedCertificate,
            });

            setSigning(signing);
            localStorage.setItem("signing-uid", signing.uid);

            await services.tasks.updateTask({
                taskUid: task?.uid,
            });
            await services.tasks.list({
                company: auth.company.uid,
                user: auth.user.uid,
            });
            history.push("/inbox/");
        } catch (err) {
            console.error(err);
            services.toast.error(i18n("Algum erro inesperado ocorreu"));
        } finally {
            setSendingForm(false);
            services.spinner.hide();
        }

        clearTask();
    };

    useAsyncEffect(async () => {
        const signingUid = localStorage.getItem("signing-uid");
        if (!signingUid) return;

        const signing = await serviceSigning.find({ signingUid });
        if (typeof signing === "string") return;

        setSigning(signing);
    }, []);

    useAsyncEffect(async () => {
        if (!signing) return;

        const isSigning =
            localStorage.getItem(`is-signing-${signing.uid}`) === "true";

        if (signing.status === "pending-sign" && !isSigning) {
            localStorage.setItem(`is-signing-${signing.uid}`, "true");

            const token = await lacunaPki.signWithRestPki({
                token: signing.token,
                thumbprint: signing.certificateThumbprint,
            });

            await serviceSigning.update(signing.uid, {
                status: "done",
                token,
            });

            localStorage.removeItem("signing-uid");
            localStorage.removeItem(`is-signing-${signing.uid}`);
        }
    }, [signing]);

    useEffect(() => {
        if (!executedForm) return;
        setFormValues((prev) => ({
            ...prev,
            ...executedForm?.fields,
        }));
        return () => {};
    }, [executedForm]);

    return (
        <div className={styles.TaskForm}>
            <hr />

            {fields.map((fieldConfig, i) => {
                const { type, name: field } = fieldConfig;
                const props = {
                    fieldConfig,
                    formValues,
                    updateFormValues,
                    formFilled,
                };

                if (!formValues[field]) {
                    formValues[field] = "";
                }

                const Component = components[type] || Input;
                return <Component key={field + i} {...props} />;
            })}

            {signatureRequired && (
                <CertificatesSelector
                    setSelectedCertificate={setSelectedCertificate}
                    selectedCertificate={selectedCertificate}
                />
            )}
            {task.agent === auth.user.uid && !formFilled && (
                <>
                    <hr />
                    <ButtonLoading
                        loading={sendingForm}
                        onClick={handleSendForm}
                    >
                        {i18n("Enviar formulário")}
                    </ButtonLoading>
                </>
            )}
        </div>
    );
}
