import React from "react";
import { Switch, Router as ReactRouter } from "react-router";
import { history } from "@store";

import { routes } from "@constants";
import CustomRoute from "./CustomRoute";

const Router = () => (
    <ReactRouter history={history}>
        <Switch>
            {routes.map((route) => (
                <CustomRoute key={route.key} routeKey={route.key} {...route} />
            ))}
        </Switch>
    </ReactRouter>
);

export default Router;
