import i18n, { format } from "@i18n";
import styles from "./cardTask.module.scss";
import React from "react";
import { ButtonLoading } from "components/buttons/buttonLoading";
import { toDate } from "@functions";
import { useStore } from "@hooks";
import { history } from "@store";
import Icon from "@custom/Icon";
import { StepIcon } from "components/stepIcon";
import { CirclesTypeTask } from "components/circlesTypeTask";

export function CardTask({ task }) {
    const { users, theme: { theme } } = useStore();
    const { hideRequester } = task?.workflowObject;

    const renderName = (uid) => {
        const responsible = users.find((user) => user.uid === uid);

        if (responsible) {
            return `${responsible?.firstName} ${responsible?.lastName}`;
        }
        return task?.action?.agentName;
    };

    const renderTitle = (step) => {
        if (step === "pending") return "Tarefa em aberto";
        if (step === "waiting-review") return "Tarefa em análise";
        if (step === "review") return "Tarefa revisada";
        if (step === "completed") return "Tarefa completa";
        if (step === "removed") return "Tarefa excluida";
    };

    const handleSelectedTask = async () => {
        history.push(`/inbox/${task.uid}`);
    };

    const handleResponsibleFlow = () => {
        if (task?.workflowObject?.group?.agentObject) {
            return `${task?.workflowObject?.group?.agentObject?.firstName} ${task?.workflowObject?.group?.agentObject?.lastName}`;
        }
        if (task?.workflowObject?.group?.uid === "process-creator-group") {
            return renderName(task?.workflowObject?.createdBy);
        }

        return task?.workflowObject?.group?.name || "validar fluxo";
    };

    const handleExpirationDateTask = (date, statusClass) => {

        const currentDate = new Date();
        const expiration = new Date(date);
        const differenceInTime = expiration.getTime() - currentDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))

        if (statusClass) {
            return differenceInDays < 0 ? Math.abs(differenceInDays) : 0
        }
        if (differenceInDays < 0) {
            return (
                <span><strong> Atrasado </strong> {`${Math.abs(differenceInDays)} ${Math.abs(differenceInDays) > 1 ? "dias" : "dia"} em atraso`}   </span>
            )
        } else {
            return (
                <span> <strong> Em dia </strong> 0 dias em atraso</span>
            )
        }
    }

    const handleStepTask = () => {

        const currentStep = task?.workflowObject?.phases.find(phase => phase.uid === task.phase)

        return currentStep?.name
    }

    const handleStepStatus = () => {
        return task.status === "pending" || task.status === "waiting-review"
    }

    return (

        <div className={`${styles.taskCard} ${styles[theme]} ${styles[task?.status]}`}>
            <div className={styles.headerCard}>
                <div className={styles.areaTitle}>
                    <StepIcon step={task.status} icon />
                    <h3>{i18n(renderTitle(task?.status))}</h3>
                </div>
                <CirclesTypeTask type={task?.action?.type?.type} />
            </div>
            <div className={styles.protocolArea}>
                <strong>{task?.workflowObject?.description}</strong>
                {task?.workflowObject?.managementProtocolCode && (
                    <p>
                        <strong>{i18n("Protocolo n°")}{" "}</strong>{" "}
                        {task?.workflowObject?.managementProtocolCode}
                    </p>
                )}
            </div>
            <div className={styles.mainCard}>
                <div>
                    <p>
                        <strong>{i18n("Tarefa")}:</strong>{" "}
                        {task?.action?.instructions || task?.action?.formTitle}
                    </p>
                    {/* <p>
                        <strong>{i18n("Assunto")}:</strong>{" "}
                        {task?.workflowObject?.description}
                    </p> */}
                    <p>
                        <strong>{i18n("Solicitante")}:</strong>{" "}
                        {!hideRequester &&
                            renderName(task?.workflowObject?.createdBy)}
                        {hideRequester && i18n("Anônimo")}
                    </p>
                    <p>
                        <strong>{i18n("Data da tarefa")}:</strong>{" "}
                        {format.date(toDate(task?.createdAt))}{" "}
                    </p>
                    {task.expirationDateString && (
                        <p>
                            <strong>{i18n("Data de expiração")}:</strong>{" "}
                            {format.date(toDate(task?.expirationDateString))}{" "}
                        </p>
                    )}
                    <p>
                        <strong>{i18n("Dono da Tarefa")}:</strong>{" "}
                        {renderName(task?.action?.agent)}
                    </p>
                    <p>
                        <strong>{i18n("Responsável pelo Fluxo")}: </strong>
                        {handleResponsibleFlow()}
                    </p>

                </div>
                <div className={styles.areaButton}>
                    {handleStepStatus() && (
                        <div className={`${styles.expirationStatus} ${task.expirationDateString !== "" && Number(handleExpirationDateTask(task.expirationDateString, true)) > 0 && styles.expiration}`}>
                            <span className={styles.iconInfo}><Icon icon="faInfo" /></span>
                            <p className={styles.colorText}>
                                <strong className={styles.strongIndicator}>Status:</strong>
                                {handleExpirationDateTask(task.expirationDateString)}
                            </p>
                            <p>
                                <strong>Etapa:</strong> {handleStepTask()}
                            </p>
                        </div>
                    )

                    }

                    <ButtonLoading
                        onClick={handleSelectedTask}
                        loading={false}
                    >
                        <span>{i18n("Abrir solicitação")}</span>
                        <Icon icon="faArrowUpRightFromSquare" />
                    </ButtonLoading>
                </div>
            </div>
        </div>
    );
}
