"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _app["default"];
  }
});
var _app = _interopRequireDefault(require("firebase/app"));
require("firebase/storage");
require("firebase/firestore");
require("firebase/auth");
var firebaseConfig = {
  apiKey: 'AIzaSyB-EnUAbsxTOpO3f5LPPLp3xSxTOhGrZ2s',
  authDomain: 'collecta-me.firebaseapp.com',
  databaseURL: 'https://collecta-me.firebaseio.com',
  projectId: 'collecta-me',
  storageBucket: 'collecta-me.appspot.com',
  messagingSenderId: '150539937450',
  appId: '1:150539937450:web:75f4c53e9e20062c3352d3'
};
var app = _app["default"].initializeApp(firebaseConfig, 'collectame');
var _default = app;
exports["default"] = _default;