// @ts-nocheck
import Axios from "axios";
import { useEffect, useState } from "react";

/**
 * @typedef LocationProperty
 * @type {{
 *    long_name: string;
 *    short_name: string;
 * }}
 */

/**
 * @typedef Location
 * @type {{
 *    streetNumber: LocationProperty;
 *    street: LocationProperty;
 *    neighborhood: LocationProperty;
 *    city: LocationProperty;
 *    state: LocationProperty;
 *    country: LocationProperty;
 *    postalCode: LocationProperty;
 *   latitude: number | null;
 *   longitude: number | null;
 * }}
 */

/**
 * @returns {{location: Location; hasPermission: boolean; loading: boolean;}}
 */
function useGeoLocation() {
    const [loading, setLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(false);
    const [location, setLocation] = useState({
        city: null,
        latitude: null,
        longitude: null,
    });

    useEffect(() => {
        const getCityData = async (position) => {
            console.log("position: >=>", position);
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const findByType = (type) => {
                return ({ types }) => {
                    return (types || []).includes(type);
                };
            };

            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCKGao9IShjWBU60FmyV5Tc9OKlAwyTCEs`;

            const { data } = await Axios.get(url);

            const cityData = data.results[0];
            const address = cityData.address_components;

            const streetNumber = address.find(findByType("street_number"));
            const street = address.find(findByType("route"));
            const neighborhood = address.find(
                findByType("sublocality_level_1")
            );
            const city = address.find(
                findByType("administrative_area_level_2")
            );
            const state = address.find(
                findByType("administrative_area_level_1")
            );
            const country = address.find(findByType("country"));
            const postalCode = address.find(findByType("postal_code"));

            setHasPermission(true);
            setLoading(false);
            setLocation({
                streetNumber,
                street,
                neighborhood,
                city,
                state,
                country,
                postalCode,
                latitude,
                longitude,
            });
        };

        const getCityDataError = (error) => {
            console.log("error: >=>", error);
            if (error.code === 1) {
                setHasPermission(false);
            }
            setLoading(false);
        };

        navigator.geolocation.getCurrentPosition(getCityData, getCityDataError);

        return () => {}
    }, []);

    return { location, hasPermission, loading };
}

export default useGeoLocation;
export { useGeoLocation };
