import { toDate } from "@functions";

const workflowModel = {
    /** @type {"public" | "private"} */
    security: "public",
    uid: "",
    company: "",
    createdAt: new Date(),
    createdBy: "",
    deleted: false,
    description: "",
    division: "",
    document: {
        activities: "",
        beginConditions: "",
        description: "",
        endConditions: "",
        inputs: "",
        outputs: "",
        roles: "",
        title: "",
    },
    experation: {
        daysToExpiration: "",
        daysToFirstWarning: "",
        daysToLastWarning: "",
        doesExpire: "",
        /** @type {"ignore" | "go-previous-phase"| "cancel-execution-flow"} */
        expirationConsequence: "ignore",
    },
    /**
     * @type {{
     *     reactions: {
     *         type: {
     *             type: string;
     *         };
     *         dokiaInfo: {
     *             company: object;
     *             subsidiary: object;
     *             classification: object;
     *             organization: object;
     *         }
     *         originForm: string;
     *         originDocument: '';
     *         deleted: false;
     *         requireApprovalDocumentSign: boolean;
     *     }[];
     * }[]}
     */
    phases: [],
};

/**
 * @typedef Workflow
 * @type {typeof workflowModel}
 */

function createWorkflow(data) {
    if (!data) data = {};

    return {
        ...workflowModel,
        ...data,
        deleted: !!data.deleted,
        createdAt: toDate(data.createdAt),
    };
}

export { workflowModel, createWorkflow };
