"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uid = exports.toDate = exports.smallUid = exports.playSound = exports.objectHasProps = exports.groupByItemProperty = exports.getChunks = exports.getAppImageSrc = exports.fixTimezone = void 0;
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _iconDokplus = _interopRequireDefault(require("../@assets/images/icon-dokplus.png"));
var _YopenLaranja = _interopRequireDefault(require("../@assets/images/Yopen-laranja.ico"));
var _YopenBranco = _interopRequireDefault(require("../@assets/images/Yopen-Branco.png"));
var _Appcenter = _interopRequireDefault(require("../@assets/images/Appcenter.svg"));
var _EditFy = _interopRequireDefault(require("../@assets/images/EditFy.svg"));
var _Inbox = _interopRequireDefault(require("../@assets/images/Inbox.svg"));
var _Workflux = _interopRequireDefault(require("../@assets/images/Workflux.svg"));
var _Indexia = _interopRequireDefault(require("../@assets/images/Indexia.svg"));
var fixTimezone = function fixTimezone(d) {
  var date = new Date(d.getTime());
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
};
exports.fixTimezone = fixTimezone;
var toDate = function toDate(value, extraLog) {
  if (value && (0, _typeof2["default"])(value) === 'object' && value.toDate) {
    var date = value.toDate();
    return date;
  }
  if (value && (0, _typeof2["default"])(value) === 'object' && value._seconds && value._nanoseconds !== undefined) {
    var _date = new Date(parseInt(value._seconds + '' + ('000' + value._nanoseconds).slice(-3).substr(0, 3)));
    return _date;
  }
  if (value && (0, _typeof2["default"])(value) === 'object' && value.seconds && value.nanoseconds !== undefined) {
    var _date2 = new Date(parseInt(value.seconds + '' + ('000' + value.nanoseconds).slice(-3).substr(0, 3)));
    return _date2;
  }
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string' && value !== '') {
    return fixTimezone(new Date(value));
  }
  return value;
};
exports.toDate = toDate;
var objectHasProps = function objectHasProps(object, props) {
  var has = true;
  props.find(function (prop) {
    if (!object[prop]) {
      has = false;
      return prop;
    }
  });
  return has;
};
exports.objectHasProps = objectHasProps;
var getChunks = function getChunks(array, size) {
  var chunks = [];
  var i, j;
  for (i = 0, j = array.length; i < j; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};
exports.getChunks = getChunks;
var groupByItemProperty = function groupByItemProperty(array, property, reduceItem) {
  reduceItem = typeof reduceItem === 'function' ? reduceItem : function (o) {
    return o;
  };
  var result = {};
  (array || []).map(function (item) {
    var propValue = getPropFromPath(item, property);
    if (!result[propValue]) {
      result[propValue] = [];
    }
    result[propValue].push(reduceItem(item));
  });
  return result;
};
exports.groupByItemProperty = groupByItemProperty;
var randInterval = function randInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
var uid = function uid() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var a = Math.ceil(Math.random() * 100000000000).toString(32);
  var b = new Date().getTime().toString(32);
  var c = Math.ceil(Math.random() * (32 - 28) + 28);
  var d = prefix + a + b + c;
  var length = d.length;
  for (var i = 0; i < length - 5; i++) {
    var index = randInterval(0, length);
    d = d.substr(0, index) + d.substr(index, 1).toUpperCase() + d.substr(index + 1);
  }
  return d;
};
exports.uid = uid;
var smallUid = function smallUid() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var a = Math.ceil(Math.random() * 1000000000).toString(32);
  var b = ''; //new Date().getTime().toString(32);
  var c = randInterval(10, 99);
  var d = prefix + a + b + c;
  return d.toUpperCase();
};
exports.smallUid = smallUid;
var getAppImageSrc = function getAppImageSrc(app, theme) {
  var isLight = !theme || theme === 'light';
  switch (app) {
    case 'appcenter':
    case 'appcenter-customer':
      return isLight ? _Appcenter["default"] : _Appcenter["default"];
    case 'dokia':
      return isLight ? _iconDokplus["default"] : _iconDokplus["default"];
    case 'collectame':
      return isLight ? _YopenLaranja["default"] : _YopenBranco["default"];
    case 'workflux':
    case 'workflux-portal':
      return isLight ? _Workflux["default"] : _Workflux["default"];
    case 'lawdoc':
      return isLight ? _EditFy["default"] : _EditFy["default"];
    case 'inboxticket':
      return isLight ? _Inbox["default"] : _Inbox["default"];
    case 'indexia-web':
    case 'indexia':
      return isLight ? _Indexia["default"] : _Indexia["default"];
  }
};
exports.getAppImageSrc = getAppImageSrc;
var playSound = function playSound(url) {
  var audio = new Audio(url);
  audio.volume = 0.2;
  audio.play();
};
exports.playSound = playSound;