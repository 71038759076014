import { calendarCollection } from "@firebase-config/collections";

import { createEvent } from "@models";

/** 
 * @typedef Event
 * @type {import("@models/calendar").Event}
*/

/**
 * Function to list all events of calendar
 * @param {{
 *      companyUid: string;
 *      date?: Date
 * }} params
 * @returns {Promise<string | Event[]>}
 */
export async function listCalendarEvents({ companyUid, date }) {
    if (!companyUid || typeof companyUid !== "string") {
        return "invalid-param-companyUid";
    }

    try {
        let query = calendarCollection
            .where("company", "==", companyUid)
            .where("delete", "==", false)


        if (date) {
            const firsDate = new Date(date.setDate(date.getDate() + 1));
            const firsDateFixed = new Date(firsDate.setHours(0, 0, 0));
            const lastDate = new Date(date.setHours(23, 59, 59));

            query = query
                .where("initialDate", ">=", firsDateFixed)
                .where("initialDate", "<=", lastDate)
        }

        const { docs } = await query.get()

        const allListCalendarEvents = docs.map((doc) => doc.data());

        return allListCalendarEvents.map(createEvent);
    } catch (error) {
        console.log("ERROR in listCalendarEvents: >=>", error);
        return "error-list-events";
    }
}
