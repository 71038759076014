"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
/**
 * @param {...(string | {
 *     [className: string]: boolean
 * })} classes
 * @returns {string}
 */
function classNames() {
  var result = "";
  for (var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++) {
    classes[_key] = arguments[_key];
  }
  for (var _i = 0, _classes = classes; _i < _classes.length; _i++) {
    var className = _classes[_i];
    if ((0, _typeof2["default"])(className) === "object") {
      for (var _i2 = 0, _Object$entries = Object.entries(className); _i2 < _Object$entries.length; _i2++) {
        var _Object$entries$_i = (0, _slicedToArray2["default"])(_Object$entries[_i2], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        if (value) {
          result += " ";
          result += key;
        }
      }
    } else {
      result += " ";
      result += className;
    }
  }
  result = result.trim();
  result = result.replace(/\s+/g, " ");
  return result;
}
var _default = classNames;
exports["default"] = _default;