import { workflowCollection } from "@firebase-config/collections";
import { createWorkflow } from "@models";

/**
 * @typedef Workflow
 * @type {import("@models/workflow").Workflow}
 */

/**
 * Function to list all workflows of company
 * @param {{
 *      companyUid: string;
 *      lastDocUid?: string;
 * }} params
 * @returns {Promise<Workflow[] | string>}
 */
export async function listWorkflows({ companyUid, lastDocUid = "" }) {
    if (!companyUid || typeof companyUid !== "string") {
        return "invalid-param-companyUid";
    }

    try {
        let query = workflowCollection
            .where("company", "==", companyUid)
            .where("security", "==", "public")
            .where("deleted", "==", false);

        if (lastDocUid) {
            const doc = await workflowCollection.doc(lastDocUid).get();

            query = query.startAfter(doc);
        }

        const { docs } = await query.get();

        const allWorkflows = docs.map((doc) => doc?.data());

        return allWorkflows.map(createWorkflow);
    } catch (error) {
        console.log("ERROR in listWorkflows: >=>", error);
        return "error-list-workflows";
    }
}
