import { filterCompanyArea } from "./filter";
import { listCompanyArea } from "./list";
import { updateFilterCompanyArea } from "./update";

export const serviceCompanyArea = {
  list: listCompanyArea,
  filter: filterCompanyArea,
  updateFilter: updateFilterCompanyArea

};
