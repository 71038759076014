import { serviceTask } from "@services/task/index.js";

const LIST_COMPANY_TASKS = "LIST_COMPANY_TASKS";
const LIST_MORE_COMPANY_TASKS = "LIST_MORE_COMPANY_TASKS";
const COMPANY_TASKS_CHANGE = "COMPANY_TASKS_CHANGE";
const ADD_TASKS = "ADD_TASKS";
/**
 * @typedef Task
 * @type {import("@models/task.js").Task}
 */

/**
 * @typedef Params
 * @type {import("./index.js").Params}
 */

const service = (dispatch) => ({
    /** @param {Partial<Params>} params */
    list: async ({ user, lastDoc, limit }) => {
        const result = await serviceTask.list({
            userUid: user,
            lastDocUid: lastDoc,
            limit,
        });

        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceTask.watch({
            list,
            callback: (tasksList) => {
                dispatch({
                    type: COMPANY_TASKS_CHANGE,
                    payload: { list: tasksList },
                });
            },
        });

        dispatch({
            type: LIST_COMPANY_TASKS,
            payload: { list },
        });
    },
    /** @param {{taskUid: string}} params  */
    updateTask: async ({ taskUid }) => {
        const result = await serviceTask.find({
            taskUid,
        });

        dispatch({
            type: COMPANY_TASKS_CHANGE,
            payload: { list: [result] },
        });
    },

    /** @param {Params} params */
    listMore: async ({ user, lastDoc, limit, group }) => {
        const result = await serviceTask.list({
            groupUid: group,
            userUid: user,
            lastDocUid: lastDoc,
            limit,
        });

        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceTask.watch({
            list,
            callback: (tasksList) => {
                dispatch({
                    type: COMPANY_TASKS_CHANGE,
                    payload: { list: tasksList },
                });
            },
        });

        dispatch({
            type: LIST_MORE_COMPANY_TASKS,
            payload: { list },
        });
    },
    searchTasks: ({ tasks }) => {
        const list = tasks.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceTask.watch({
            list,
            callback: (tasksList) => {
                dispatch({
                    type: COMPANY_TASKS_CHANGE,
                    payload: { list: tasksList },
                });
            },
        });

        dispatch({
            type: LIST_MORE_COMPANY_TASKS,
            payload: { list },
        });
    },
    /** @param {Task[]} executedDocument */
    addToList: (executedDocument) => {
        dispatch({
            type: ADD_TASKS,
            payload: { list: executedDocument },
        });
    },
});

const INIT_STATE = {
    list: [],
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_COMPANY_TASKS:
            return {
                ...state,
                list: action.payload.list,
            };

        case ADD_TASKS:
        case LIST_MORE_COMPANY_TASKS:
            const allTasks = [...state.list, ...action.payload.list];

            return {
                ...state,
                list: allTasks.reduce((list, flow) => {
                    const alreadyAdded = !!list.find(
                        (item) => item.uid === flow.uid
                    );
                    if (alreadyAdded) return list;
                    list.push(flow);
                    return list;
                }, []),
            };

        case COMPANY_TASKS_CHANGE:
            return {
                ...state,
                list: state.list.map((flow) => {
                    const updated = action.payload.list.find(
                        (item) => item.uid === flow.uid
                    );

                    return updated || flow;
                }),
            };

        default:
            return { ...state };
    }
};

export default { reducer, service };
