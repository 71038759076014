"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
/**
 * @param {string} phone
 * @param {string=} text
 */
function openInWhatsapp(phone, text) {
  phone = phone.replace(/\D/g, '');
  if (!text) text = '';
  text = encodeURIComponent(text);
  window.open("https://wa.me/".concat(phone, "?text=").concat(text));
}
var _default = openInWhatsapp;
exports["default"] = _default;