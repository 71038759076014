import { useStore } from "@hooks";
import i18n from "@i18n";
import { FormControl, InputLabel, MenuItem, Select as SelectMui } from "@mui/material";
import React from "react"

/**
 * 
 * @param {{
 *  title: string,
 *  onChange: (e: string) => void
 *  itens: { title: string, value: string }[]
 *  value: string
 *  error?: boolean
 *  invalidOption?: boolean
 * }} props
 * 
 */

export function Select({ title, value, itens, error, invalidOption, onChange }) {

  const { theme: { theme } } = useStore()

  if (!itens) return <></>

  return (
    <FormControl
      fullWidth
      size="small"
    >
      <InputLabel size="small"
        sx={{
          color: theme === 'dark' ? '#f5f5f599' : ''
        }}
      >
        {i18n(title)}
      </InputLabel>
      <SelectMui
        size="small"
        label={i18n(title)}
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        error={error}

        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme === 'dark' ? '#f5f5f599' : '',
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme === 'dark' ? 'white' : '',
          },

          "& .MuiOutlinedInput-input": {
            color: theme === 'dark' ? 'white' : '',
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: theme === 'dark' ? '#2e3646' : '',
              '& .MuiMenuItem-root': {
                color: theme === 'dark' ? 'white' : "",
              },
            },
          },
        }}
      >
        {!invalidOption && <MenuItem value="">
          {i18n("Nenhum")}
        </MenuItem>}
        {itens.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
          >
            {i18n(item.title)}
          </MenuItem>
        ))}
      </SelectMui>
    </FormControl>
  )
}