import React from "react";
import { WeatherCard } from "../WeatherCard";

import styles from "./styles.module.scss";
import i18n from "@i18n";

export function WeatherInfo({ weatherList }) {
    return (
        <div className={styles.WeatherInfo}>
            <h2 className={styles.WeatherInfoTitle}>
                {i18n("Previsão de")} {weatherList.length} {i18n("dias")}
            </h2>
            <div className={styles.WeatherInfoList}>
                {weatherList.map((weatherItem) => (
                    <WeatherCard weatherItem={weatherItem} />
                ))}
            </div>
        </div>
    );
}
