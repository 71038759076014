import React from "react"
import { TextField } from "@mui/material"
import i18n from "@i18n"
import { useStore } from "@hooks"

/**
 * 
 * @param {{
 *  setValue?: import("react").Dispatch<import("react").SetStateAction<string>>
 *  title?: string
 * } & import("@mui/material").TextFieldProps} props 
 * @returns 
 */

export function Input({ value, setValue, title, error, onChange, required, multiline, type, ...rest }) {

  const { theme: { theme } } = useStore()

  return (
    <TextField
      fullWidth
      value={value}
      onChange={(e) => {
        onChange(e)
        if (setValue) {
          setValue(e.target.value)
        }
      }}
      type={type}
      required={required}
      label={i18n(title)}
      variant="outlined"
      size="small"
      multiline={multiline}
      error={error}
      InputLabelProps={{
        sx: {
          color: theme === 'dark' ? '#f5f5f599' : '',
        }
      }}
      InputProps={{
        sx: {
          color: theme === 'dark' ? '#fff' : '',
          '& fieldset': {
            borderColor: theme === 'dark' ? '#f5f5f599' : '',
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme === 'dark' ? 'white' : '',
          },
        },
      }}
      {...rest}
    />
  )
}