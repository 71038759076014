import React from "react";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import CurrencyFormat from "react-currency-format";

import i18n from "@i18n";

import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Input(props) {
    const { fieldConfig, formValues, updateFormValues, formFilled } = props;
    const { type, mask, name: field } = fieldConfig;

    const label =
        field +
        (type === "email" && !formFilled
            ? '. Caso for colocar mais de um email separar por ";"'
            : "");
    const value = formValues[field] || "";

    /**
     * @param {{
     *     formattedValue: string;
     * }} values
     */
    const handleCurrencyChange = (values) => {
        const { formattedValue: value } = values;
        updateFormValues(field, value);
    };

    /** @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} evt */
    const handleFormValueChange = (evt) => {
        updateFormValues(field, evt.target.value);
    };

    if (formFilled) {
        return (
            <>
                <p><strong>{label}</strong></p>
                <p>{value}</p>
            </>
        );
    }

    return (
        <div className={styles.Input}>
            <label className={styles.InputLabel}>{label}*</label>
            {type === "textarea" ? (
                <textarea
                    onChange={handleFormValueChange}
                    value={value}
                    placeholder={i18n("Digite aqui...")}
                />
            ) : type === "currency" ? (
                <CurrencyFormat
                    fixedDecimalScale
                    allowNegative={false}
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    placeholder=" "
                    onValueChange={handleCurrencyChange}
                    value={value}
                    disabled={formFilled}
                />
            ) : !!mask ? (
                <InputMask
                    alwaysShowMask
                    mask={mask}
                    onChange={handleFormValueChange}
                    value={value}
                    placeholder=" "
                >
                    {() => <TextField fullWidth variant="outlined" />}
                </InputMask>
            ) : (
                <TextField
                    fullWidth={type !== "color"}
                    variant="outlined"
                    type={type}
                    onChange={handleFormValueChange}
                    value={value}
                />
            )}
        </div>
    );
}

export default Input;
export { Input };
