import { createNews } from "@models";
import { newsCollection } from "@firebase-config/collections";

/**
 * @typedef News
 * @type {import("@models/news").News}
 */

/**
 * Function to listen for changes in all news of company
 * @param {{
 *      list: News[];
 *      callback: (news: News[]) => void;
 * }} params
 */
export function watchNews({ list, callback }) {
    const news = [];

    for (const item of list) {
        newsCollection.doc(item.uid).onSnapshot((snapshot) => {
            const updatedNews = createNews(snapshot.data());
            news.push(updatedNews);
            callback([updatedNews]);
        });
    }

    callback(news);
}
