import React, { useState, useMemo } from "react";
import { Button, } from "@mui/material";
import { history } from "@store";

import styles from "./styles.module.scss";
import { Modal } from "components";
import { useStore } from "@hooks";

const searchTypeOptions = {
    news: "news",
    documents: "documents",
    calendar: "calendar",
};

function SearchModal({ open, onClose, news, documents, events }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchType, setSearchType] = useState(null);
    const { theme: { theme } } = useStore()

    const isValidArray = (arr) => Array.isArray(arr) && arr.length > 0;

    const handleItemClick = (item) => {
        let url;
        if (item.type === "news") {
            url = `/news/${item.uid}`;
        } else if (item.type === "document") {
            url = `/my-files/${item.uid}`;
        } else if (item.type === "event") {
            let formattedDate;

            if (
                item.initialDate &&
                typeof item.initialDate.toDate === "function"
            ) {
                formattedDate = item.initialDate
                    .toDate()
                    .toISOString()
                    .split("T")[0];
            }
            console.log("Formatted Date for URL:", formattedDate);
            if (formattedDate) {
                url = `/calendar?date=${formattedDate}`;
            }
        }
        if (url) {
            history.push(url);
        }
        onClose();
    };

    const filteredResults = useMemo(() => {
        let results = [];
        const searchLower = searchTerm.toLowerCase();

        const filterBySearchTerm = (items) => {
            return items.filter((item) => {
                const title =
                    item.title ||
                    item.name ||
                    item.action?.instructions ||
                    item.action?.documentInputDescription ||
                    "";
                return title.toLowerCase().includes(searchLower);
            });
        };

        if (searchType === searchTypeOptions.news) {
            results = isValidArray(news) ? filterBySearchTerm(news) : [];
        } else if (searchType === searchTypeOptions.documents) {
            results = isValidArray(documents)
                ? filterBySearchTerm(documents)
                : [];
        } else if (searchType === searchTypeOptions.calendar) {
            results = isValidArray(events) ? filterBySearchTerm(events) : [];

            console.log("Eventos:", events);
        } else {
            if (isValidArray(news)) {
                results = results.concat(filterBySearchTerm(news));
            }
            if (isValidArray(documents)) {
                results = results.concat(filterBySearchTerm(documents));
            }
        }
        return results.map((item) => ({
            ...item,
            type: item.initialDate
                ? "event"
                : item.action
                    ? "document"
                    : "news",
        }));
    }, [searchTerm, searchType, news, documents, events]);

    const handleSearchType = (type) => {
        if (type === searchType) {
            setSearchType("");
        } else {
            setSearchType(type);
        }
    };

    return (
        <Modal
            title="Busca Rápida"
            open={open} close={onClose}>
            <div className={`${styles.buttonContainer} ${styles[theme]}`}>
                <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => handleSearchType(searchTypeOptions.news)}
                    data-active={searchType === searchTypeOptions.news}
                >
                    Notícias
                </Button>
                <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() =>
                        handleSearchType(searchTypeOptions.documents)
                    }
                    data-active={searchType === searchTypeOptions.documents}
                >
                    Documentos
                </Button>
                <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() =>
                        handleSearchType(searchTypeOptions.calendar)
                    }
                    data-active={searchType === searchTypeOptions.calendar}
                >
                    Agenda
                </Button>
            </div>

            <div
                className={`${styles.resultsList} ${styles[theme]}`}
            >
                {filteredResults.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleItemClick(item)}
                        className={styles.resultItem}
                    >
                        {item?.title ||
                            item?.name ||
                            item?.action?.instructions ||
                            item?.action?.documentInputDescription}
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default SearchModal;
