"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidDate = exports.getCurrentLanguage = exports.format = exports["default"] = exports.current = exports.changeLanguage = void 0;
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _pt_BR = _interopRequireDefault(require("./pt_BR.json"));
var _pt_PT = _interopRequireDefault(require("./pt_PT.json"));
var _en_US = _interopRequireDefault(require("./en_US.json"));
// import IntlPolyfill from "intl";
// IntlPolyfill.__disableRegExpRestore();
// import "intl/locale-data/jsonp/pt-BR.js";
var IntlPolyfill = Intl;
var allLanguages = {
  pt_BR: _pt_BR["default"],
  pt_PT: _pt_PT["default"],
  en_US: _en_US["default"]
};
var normalizeTranslate = {
  pt_BR: 'pt_BR',
  'pt-BR': 'pt_BR',
  pt_PT: 'pt_PT',
  'pt-PT': 'pt_PT',
  pt: 'pt_BR',
  en_US: 'en_US',
  'en-US': 'en_US',
  en: 'en_US'
};
var getLanguageByDevice = function getLanguageByDevice() {
  var preSelected = localStorage.getItem('language');
  if (preSelected) return preSelected;
  localStorage.setItem('language', Intl.NumberFormat().resolvedOptions().locale);
  return new Intl.NumberFormat().resolvedOptions().locale;
};
var language;
var current;
exports.current = current;
var changeLanguage = function changeLanguage(newLanguage) {
  language = newLanguage || normalizeTranslate[getLanguageByDevice()];
  exports.current = current = allLanguages[language];
  if (!language || !current) {
    language = 'pt_BR';
    exports.current = current = allLanguages[language];
  }
  //('newLanguage', newLanguage)
  localStorage.setItem('language', language);
};
exports.changeLanguage = changeLanguage;
changeLanguage();
var getCurrentLanguage = function getCurrentLanguage() {
  return {
    current: current,
    language: language
  };
};
exports.getCurrentLanguage = getCurrentLanguage;
var i18n = function i18n(key, replaceMatrix) {
  var message = current[key] || key;
  if (replaceMatrix instanceof Array && typeof message === 'string') {
    for (var i = 0; i < replaceMatrix.length; i++) {
      message = message.replace('@', replaceMatrix[i]);
    }
  }
  if ((0, _typeof2["default"])(replaceMatrix) === 'object' && !(replaceMatrix instanceof Array) && typeof message === 'string') {
    for (var _i in replaceMatrix) {
      if (!replaceMatrix.hasOwnProperty(_i)) continue;
      message = message.replace(new RegExp('@' + _i, 'g'), replaceMatrix[_i]);
    }
  }
  return message;
};
var isValidDate = function isValidDate(d) {
  return d instanceof Date && !isNaN(d.getTime());
};
exports.isValidDate = isValidDate;
var format = {
  number: function number(n) {
    var d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return new IntlPolyfill.NumberFormat(language.replace('_', '-'), {
      maximumFractionDigits: 2
    }).format(n);
  },
  currency: function currency(n) {
    var d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return new IntlPolyfill.NumberFormat(language.replace('_', '-'), {
      style: 'currency',
      currency: current["default"].currency.abbreviation
    }).format(n);
  },
  date: function date(d, o) {
    if (!o) {
      o = current["default"].date;
    }
    if (typeof d === 'string') {
      d = new Date(d);
    }
    if (!isValidDate(d)) return;
    return new IntlPolyfill.DateTimeFormat(language.replace('_', '-'), o).format(d);
  },
  datetime: function datetime(d, o) {
    if (!o) {
      o = current["default"].datetime;
    }
    if (typeof d === 'string') {
      d = new Date(d);
    }
    if (!isValidDate(d)) return;
    return new IntlPolyfill.DateTimeFormat(language.replace('_', '-'), o).format(d);
  }
};
exports.format = format;
var _default = i18n;
exports["default"] = _default;