"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatePermission = void 0;
var _store = require("../../@api/store");
var adminPermissions = {
  // DOK+
  'dokia.classification.viewer': 'dokia.classification.admin',
  'dokia.subsidiary.viewer': 'dokia.subsidiary.admin',
  'dokia.company.viewer': 'dokia.company.admin',
  'dokia.organization.viewer': 'dokia.organization.admin',
  'dokia.documents.viewer': 'dokia.documents.admin',
  'dokia.document.custody.viewer': 'dokia.document.custody.admin',
  'dokia.config.extraction.viewer': 'dokia.config.extraction.admin',
  'dokia.config.alerts.viewer': 'dokia.config.alerts.admin',
  'dokia.config.sharings.viewer': 'dokia.config.sharings.admin',
  // 'dokia.config.alerts': 'dokia.config.alerts.admin',
  // 'dokia.trash.view': ['dokia.trash.restore, dokia.trash.clean'],
  'dokia.entity.viewer': 'dokia.entity.admin'
};
var permissionList = function permissionList() {
  var _store$getState, _store$getState$appce;
  return ((_store$getState = _store.store.getState()) === null || _store$getState === void 0 ? void 0 : (_store$getState$appce = _store$getState.appcenter) === null || _store$getState$appce === void 0 ? void 0 : _store$getState$appce.permissions) || [];
};
var validate = function validate(code, permissions) {
  return code && permissions.indexOf(code) > -1;
};
var validatePermission = function validatePermission(code) {
  var _store$getState2, _store$getState2$appc, _store$getState2$appc2;
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    adminCode = _ref.adminCode;
  var admin = false;
  var valid = admin;
  var permissions = permissionList();
  // console.log('permissions: ==>', permissions)
  // console.log('store: ==>', store.getState())
  var isAdmin = (_store$getState2 = _store.store.getState()) === null || _store$getState2 === void 0 ? void 0 : (_store$getState2$appc = _store$getState2.appcenter) === null || _store$getState2$appc === void 0 ? void 0 : (_store$getState2$appc2 = _store$getState2$appc.user) === null || _store$getState2$appc2 === void 0 ? void 0 : _store$getState2$appc2.companyadmin;
  if (permissions.length || isAdmin) {
    admin = isAdmin || validate(adminPermissions[code], permissions) || validate(adminCode, permissions) || false;
    valid = admin || validate(code, permissions);
  }
  // console.log('permission: ==>', code, admin, valid, store.getState()?.appcenter?.user?.uid)
  return {
    admin: admin,
    valid: valid
  };
};
exports.validatePermission = validatePermission;