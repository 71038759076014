import styles from "./formApproval.module.scss";
import React, { useState } from "react";
import { workfluxApi } from "@apis";
import i18n, { format } from "@i18n";
import { useStore } from "@hooks";
import { toDate } from "@functions";
import { Button, TextArea } from "components";
import { history, services } from "@store";

const TASKS_STATUS_VIEW = ["completed", "review", "removed"];

export function FormApproval({ task, clearTask }) {
    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState(false);

    const { auth } = useStore();

    async function approveOrDisapprove({ approve }) {
        if (reason === "") {
            setReasonError(true);
            setTimeout(() => {
                setReasonError(false);
            }, 500);
            return;
        }

        services.spinner.show();
        await workfluxApi.tasks.approve(task?.uid, approve, reason);

        if (task?.action?.onReprovalCancel && !approve) {
            await workfluxApi.executionFlow.cancel({
                id: task?.executionFlow,
                cancelMessage: reason,
                user: auth.user,
            });
        }

        await services.tasks.updateTask({
            taskUid: task?.uid,
        });

        await services.tasks.list({
            company: auth.company.uid,
            user: auth.user.uid,
        });

        history.push("/inbox/");
        services.spinner.hide();
        clearTask();
    }

    if (TASKS_STATUS_VIEW.includes(task.status)) {
        const approvalData = task.action.executedApproval;

        if (!approvalData) {
            return (
                <div className={styles.canceledTask}>
                    Está tarefa foi cancelada!
                </div>
            );
        }

        return (
            <div>
                <h4>
                    {i18n(
                        approvalData.approvedAt
                            ? "Aprovado em"
                            : "Desaprovado em"
                    )}
                </h4>
                <p>
                    {format.datetime(
                        toDate(
                            approvalData.approvedAt || approvalData.reprovedAt
                        )
                    )}
                </p>
                <br />
                <h4>{i18n("Motivo para a Aprovação/Reprovação")}</h4>
                <p>
                    <em>
                        {approvalData.approvalReason ||
                            approvalData.reprovalReason ||
                            "Motivo não informado"}
                    </em>
                </p>
            </div>
        );
    }

    return (
        <div>
            <TextArea
                placeholder={i18n("Motivo para a Aprovação/Reprovação")}
                error={reasonError}
                value={reason}
                setValue={setReason}
            />
            {task.agent === auth.user.uid && (
                <section className={styles.buttons}>
                    <Button
                        color="success"
                        onClick={() => approveOrDisapprove({ approve: true })}
                        title="Aprovar"
                    />

                    <Button
                        color="error"
                        onClick={() => approveOrDisapprove({ approve: false })}
                        title="Reprovar"
                    />
                </section>
            )}
        </div>
    );
}
