"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var colors = {
  collectame: '#F27D18',
  'appcenter-customer': '#00121e',
  dokia: '#00AEDD',
  workflux: '#1969B2',
  'workflux-portal': '#157FE9'
};
var _default = colors;
exports["default"] = _default;