import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";

import i18n from "@i18n";
import { services, history } from "@store";
import { useStore } from "@hooks";
import { ButtonLoading } from "components/buttons/buttonLoading";

import { useMyFiles } from "../../contexts/MyFilesContext";

import FileCard from "../FileCard";
import FilePanel from "../FilePanel";

import styles from "./styles.module.scss";
import { Modal } from "components";

function FileList() {
    const {
        recentlyOpenDocuments,
        filteredDocuments,
        openDocument,
        selectedUser,
        selectedGroup,
    } = useMyFiles();
    const { auth } = useStore();
    const panelIsOpen = !!openDocument;

    const [loadingMoreDocuments, setLoadingMoreDocuments] = useState(false);

    const handleMoreDocuments = async () => {
        setLoadingMoreDocuments(true);

        const lastDocument = filteredDocuments[filteredDocuments.length - 1];

        await services.documents.listMore({
            company: auth.company.uid,
            user:
                selectedUser || (!auth.user.companyadmin ? auth.user.uid : ""),
            group: selectedGroup,
            lastDoc: lastDocument?.uid,
            limit: 20,
        });

        setLoadingMoreDocuments(false);
    };

    return (
        <div className={styles.FileList}>
            {filteredDocuments.length === 0 ? (
                <div className={styles.FileListMessage}>
                    <p>{i18n("Nenhum documento encontrado")}</p>
                </div>
            ) : (
                <>
                    <div className={styles.FileListLeft}>
                        {recentlyOpenDocuments.length > 0 && (
                            <>
                                <section className={styles.FileListSection}>
                                    <h3>{i18n("Abertos recentemente")}</h3>
                                </section>
                                <Swiper
                                    className={styles.FileListSlider}
                                    freeMode
                                    navigation
                                    slidesPerView={"auto"}
                                    spaceBetween={8}
                                    modules={[FreeMode, Navigation]}
                                >
                                    {recentlyOpenDocuments.map((document) => (
                                        <SwiperSlide
                                            key={document.uid}
                                            className={styles.fileCard}
                                        >
                                            <FileCard document={document} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                        )}
                        <section className={styles.FileListSection}>
                            <h3>{i18n("Arquivos")}</h3>
                        </section>
                        <div className={styles.FileListCards}>
                            <section className={styles.areaCards}>
                                {filteredDocuments.map((document) => (
                                    <FileCard
                                        key={document.uid}
                                        document={document}
                                    />
                                ))}
                            </section>
                            <ButtonLoading
                                loading={loadingMoreDocuments}
                                onClick={handleMoreDocuments}
                            >
                                {i18n("Buscar mais")}
                            </ButtonLoading>
                        </div>
                    </div>
                    <Modal
                        open={panelIsOpen}
                        close={() => history.push("/my-files")}
                        title="Detalhes do arquivo"

                    >
                        <FilePanel document={openDocument} />
                    </Modal>
                </>
            )}
        </div>
    );
}

export default FileList;
