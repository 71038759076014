import React from "react";
import Topbar from "@custom/Topbar";

import styles from "./styles.module.scss";
import { DeleteAccount, ProfileForm } from "./components";


export default function Profile() {
    return (
        <section className={styles.Profile}>
            <Topbar />

            <ProfileForm />
            <DeleteAccount />
        </section>
    );
}
