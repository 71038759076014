import React, { useEffect, useState } from "react";
import {
    Button,
    Box,
    Tooltip,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import {
    GridRowModes,
    GridToolbarContainer,
    ptBR,
    DataGrid,
    GridActionsCellItem,
} from "@mui/x-data-grid";
import { Add, Edit, Save, Info, Cancel, Delete } from "@mui/icons-material";

import i18n from "@i18n";
import { generateUid } from "@functions";

import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Table(props) {
    const { formValues, fieldConfig, updateFormValues, formFilled } = props;
    const tableId = fieldConfig.name.split(" ").join("-").toLowerCase();

    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});

    if (!formValues[fieldConfig.name]) {
        formValues[fieldConfig.name] = [];
    }

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    /** @param {string} id */
    const handleEditClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                mode: GridRowModes.Edit,
            },
        });
    };

    /** @param {string} id */
    const handleSaveClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                mode: GridRowModes.View,
            },
        });
    };

    /** @param {string} id */
    const handleDeleteClick = (id) => () => {
        const rowsAux = rows.filter((row) => row.id !== id);
        setRows(rowsAux);
        updateFormValues(fieldConfig.name, rowsAux);
    };

    /** @param {string} id */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                mode: GridRowModes.View,
                ignoreModifications: true,
            },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        const rowsAux = rows.map((row) =>
            row.id === newRow.id ? updatedRow : row
        );

        setRows(rowsAux);
        updateFormValues(fieldConfig.name, rowsAux);

        return updatedRow;
    };

    const handleAddNewRow = () => {
        const uid = generateUid();

        setRows((oldRows) => [...oldRows, { id: uid, isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [uid]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
        }));
    };

    /** @param {{ id: string }} row */
    const getActions = ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
            <GridActionsCellItem
                icon={isInEditMode ? <Save /> : <Edit />}
                label={isInEditMode ? "Save" : "Edit"}
                onClick={
                    isInEditMode ? handleSaveClick(id) : handleEditClick(id)
                }
            />,
            <GridActionsCellItem
                icon={isInEditMode ? <Cancel /> : <Delete />}
                label={isInEditMode ? "Cancel" : "Delete"}
                className="textPrimary"
                onClick={
                    isInEditMode ? handleCancelClick(id) : handleDeleteClick(id)
                }
                color="inherit"
            />,
        ];
    };

    const columns = [
        ...fieldConfig.options.map((option) => ({
            field: option,
            headerName: option,
            width: 150,
            editable: true,
        })),
        {
            field: "actions",
            type: "actions",
            headerName: "AÇÕES",
            width: 100,
            cellClassName: "actions",
            getActions,
        },
    ];

    const EditToolbar = () => (
        <GridToolbarContainer>
            <Button
                color="primary"
                startIcon={<Add />}
                onClick={handleAddNewRow}
            />
        </GridToolbarContainer>
    );

    useEffect(() => {
        setRows(
            formValues[fieldConfig.name].map((item, key) => ({
                id: key,
                ...item,
            }))
        );
        return () => {}
    }, []);

    if (formFilled) {
        return (
            <TableContainer component={Paper}>
                <MuiTable>
                    <TableHead>
                        <TableRow>
                            {fieldConfig.options.map((col) => (
                                <TableCell key={col}>{col}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formValues[fieldConfig.name].map((values, r) => (
                            <TableRow className="row" key={`row-${r}`}>
                                {fieldConfig.options.map((col) => (
                                    <TableCell key={`row-${r}-col-${col}`}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: values[col],
                                            }}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        );
    }

    return (
        <div id={tableId} className={styles.Table}>
            <label className={styles.TableLabel}>
                {fieldConfig.name}
                <Tooltip
                    title={i18n(
                        "Tecle Ctrl + Enter para salvar e adicionar uma nova linha ou apenas Enter para salvar."
                    )}
                >
                    <Info />
                </Tooltip>
            </label>
            <Box
                sx={{
                    height: 500,
                    width: "100%",
                    "& .actions": {
                        color: "text.secondary",
                    },
                    "& .textPrimary": {
                        color: "text.primary",
                    },
                }}
            >
                <DataGrid
                    localeText={
                        ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={setRowModesModel}
                    onRowEditStart={handleRowEditStart}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                />
            </Box>
        </div>
    );
}

export default Table;
export { Table };
