import { executionFlowCollection } from "@firebase-config/collections";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * Function to update execution flows
 * @param  {string} uid
 * @param  {Partial<ExecutionFlow>} updates
 * @returns {Promise<string | ExecutionFlow>}
 */
export async function updateExecutionFlow(uid, updates) {
    try {
        await executionFlowCollection.doc(uid).update({ ...updates });
    } catch (error) {
        console.log("ERROR in updateExecutionFlow: >=>", error);
        return "error-find-executionFlow";
    }
}
