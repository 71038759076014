import React from "react";
import { Avatar, Link, Tooltip } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper/modules";

import i18n, { format } from "@i18n";
import { services, history } from "@store";
import { getNameInitials, openLinkAdBlockerProof } from "@functions";
import { useStore } from "@hooks";
import { workfluxApi } from "@apis";

import FileCard from "../FileCard";

import styles from "./styles.module.scss";

/**
 * @typedef Document
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * @param {{
 *     document: Document;
 * }} props
 */
function FilePanel({ document }) {
    const { allUsers, theme: { theme } } = useStore();

    const accesses = document.accesses.map((access) => {
        const updatedUser = allUsers.find((user) => user.uid === access.uid);
        return {
            ...access,
            email: updatedUser?.email || access.email,
            photo: updatedUser?.photo || access.photo,
        };
    });

    const creator = allUsers.find((user) => document.createdBy === user.uid);
    const agent = allUsers.find((user) => document.agent === user.uid);
    const filename = document.action.instructions;
    const description = document.action.documentInputDescription;

    const lastView = accesses.reduce((lastAccess, access) => {
        if (!lastAccess) return access;
        if (access.date.getTime() > lastAccess.date.getTime()) return access;
        return lastAccess;
    }, undefined);

    const handleOpenFile = () => {
        services.spinner.show();

        workfluxApi.document
            .open(document.uid)
            .then(({ url }) => url)
            .then(openLinkAdBlockerProof)
            .catch((err) => {
                console.error("err", err);
                services.message.error({
                    title: "Erro ao abrir documento",
                    description: err.message,
                });
            })
            .finally(services.spinner.hide);
    };

    const handleOpenFlow = () => {
        history.push(`/inbox/${document.executionFlow}`);
    };

    return (
        <div className={`${styles.FilePanel} ${styles[theme]}`}>

            <FileCard document={document} selectable={false} />

            {accesses.length > 0 && (
                <section className={styles.FilePanelSection}>
                    <p className={styles.FilePanelName}>{i18n("Acessos")}</p>
                    <Swiper
                        freeMode
                        navigation
                        className={styles.FilePanelAccesses}
                        slidesPerView={4}
                        spaceBetween={30}
                        modules={[FreeMode, Navigation]}
                    >
                        {accesses.map((access) => (
                            <SwiperSlide key={access.uid}>
                                <Tooltip
                                    title={i18n("Acessou em @data", {
                                        data: format.datetime(access.date),
                                    })}
                                >
                                    <Avatar
                                        src={access.photo}
                                        alt={access.name}
                                    >
                                        {getNameInitials(access.name)}
                                    </Avatar>
                                </Tooltip>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </section>
            )}

            <section className={styles.FilePanelSection}>
                <p className={styles.FilePanelName}>{i18n("Título")}</p>
                <p className={styles.FilePanelValue}>{filename}</p>
            </section>

            <section className={styles.FilePanelSection}>
                <p className={styles.FilePanelName}>{i18n("Descrição")}</p>
                <p className={styles.FilePanelValue}>
                    {description || <i>{i18n("Sem descrição")}</i>}
                </p>
            </section>

            <section className={styles.FilePanelSection}>
                <p className={styles.FilePanelName}>{i18n("Abrir arquivo")}</p>
                <p className={styles.FilePanelValue}>
                    <Link onClick={handleOpenFile}>
                        {i18n("Clique aqui para abrir o arquivo")}
                    </Link>
                </p>
            </section>

            <section className={styles.FilePanelSection}>
                <p className={styles.FilePanelName}>
                    {i18n("Ir para o fluxo")}
                </p>
                <p className={styles.FilePanelValue}>
                    <Link onClick={handleOpenFlow}>
                        {i18n("Clique aqui para abrir o fluxo")}
                    </Link>
                </p>
            </section>

            {lastView && (
                <section className={styles.FilePanelSection}>
                    <p className={styles.FilePanelName}>
                        {i18n("Último acesso em")}
                    </p>
                    <p className={styles.FilePanelValue}>
                        {i18n("@data por @nome (@email)", {
                            data: format.datetime(lastView.date),
                            nome: lastView.name,
                            email: lastView.email,
                        })}
                    </p>
                </section>
            )}

            <section className={styles.FilePanelSection}>
                <p className={styles.FilePanelName}>{i18n("Criado em")}</p>
                <p className={styles.FilePanelValue}>
                    {format.datetime(document.createdAt)}
                </p>
            </section>

            {agent && (
                <section className={styles.FilePanelSection}>
                    <p className={styles.FilePanelName}>
                        {i18n("Enviado por")}
                    </p>
                    <p className={styles.FilePanelValue}>
                        {agent.firstName} {agent.lastName} · {agent.email}
                    </p>
                </section>
            )}

            {creator && (
                <section className={styles.FilePanelSection}>
                    <p className={styles.FilePanelName}>{i18n("Criado por")}</p>
                    <p className={styles.FilePanelValue}>
                        {creator.firstName} {creator.lastName} · {creator.email}
                    </p>
                </section>
            )}
        </div>
    );
}

export default FilePanel;
