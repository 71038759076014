import { toDate } from "@functions";
import { createWorkflow } from "./workflow";

/**
 * @typedef Status
 * @type {'completed' | 'not-completed' | 'canceled' | 'archived' | 'review'}
 */

/**
 * @typedef Localization
 * @type {import("@apis/ipLocalApi").Localization}
 */

const executionFlowModel = {
    uid: "",
    managementProtocolCode: "",
    /** @type {Status} */
    completed: "not-completed",
    archived: false,
    company: "",
    currentPhase: "",
    agent: "",
    /** @type {import("../@hooks/useStore").User} */
    agentObject: {
        uid: "",
        firstName: "",
        lastName: "",
        photo: "",
        email: "",
        active: [],
        companies: [],
        currentCompany: { uid: "", fantasyName: "" },
        companyadmin: false,
        phone: "",
        addresses: [],
    },
    /** @type {string[]} */
    participants: [],
    /**
     * @type {{
     *     executedForm: string;
     *     task: string;
     * }[]}
     */
    forms: [],
    workflow: "",
    workflowObject: createWorkflow(),
    /**
     * @type {{
     *     type: string;
     *     instructions?: string;
     *     description?: string;
     *     taskDescription?: string;
     *     oldAgent?: {
     *         agentName: string;
     *     };
     *     newAgent?: {
     *         agentName: string;
     *     };
     *     url?: string;
     *     media?: string;
     *     phaseName?: string;
     *     formTitle?: string;
     *     user?: {
     *         uid: string;
     *         name: string;
     *         email: string;
     *     };
     *     agent?: string;
     *     agentEmail?: string;
     *     agentName?: string;
     *     local?: Localization;
     *     code?: string;
     *     date: Date;
     * }[]}
     */
    history: [],
    deleted: false,
    createdAt: new Date(),
    createdBy: "",
};

/**
 * @typedef ExecutionFlow
 * @type {typeof executionFlowModel}
 */

function createExecutionFlow(data) {
    if (!data) data = {};

    return {
        ...executionFlowModel,
        ...data,
        archived: !!data.archived,
        deleted: !!data.deleted,
        createdAt: toDate(data.createdAt),
    };
}

export { executionFlowModel, createExecutionFlow };
