import React from "react";
import i18n from "@i18n";

import styles from "./styles.module.scss";

export function WeatherCard({ weatherItem }) {
    return (
        <div className={styles.WeatherCard}>
            <h3>{weatherItem.dt_txt.split(" ")[0]}</h3>
            <h2>
                <img
                    src={`https://openweathermap.org/img/wn/${weatherItem.weather[0].icon}@4x.png`}
                    alt="weather-icon"
                />
            </h2>
            <p>
                <strong>{i18n("Temperatura")}: </strong>
                {weatherItem.main.temp.toFixed(2)}°C
            </p>
            <p>
                <strong>{i18n("Vento")}: </strong> {weatherItem.wind.speed} km/h
            </p>
            <p>
                <strong>{i18n("Humidade")}: </strong>
                {weatherItem.main.humidity}%
            </p>
        </div>
    );
}
