import { toDate } from "@functions";
const filterCompanyAreaModel = {
  company: "",
  createdAt: new Date(),
  createdBy: "",
  companyArea: "",
  workflow: ""
};

function createFilterCompanyArea(data) {
  if (!data) data = {};

  return {
    ...filterCompanyAreaModel,
    ...data,
    createdAt: toDate(data.createdAt),
  };
}

export { createFilterCompanyArea };