import React, { useState } from "react";
import { useAsyncEffect } from "zerocode/dist/@hooks";
import { serviceTask } from "@services/task";
import i18n, { format } from "@i18n";
import { toDate } from "@functions";
import { useStore } from "@hooks";
import { Modal } from "components";

export function InfoTask({ open, close, taskUid }) {
    const [task, setTask] = useState(null);

    const { users } = useStore();

    useAsyncEffect(async () => {
        try {
            const getTask = await serviceTask.find({ taskUid: taskUid });
            setTask(getTask);
        } catch (error) { }
    }, []);

    if (!task) return <></>;

    const renderName = (uid) => {
        const responsible = users.find((user) => user.uid === uid);

        if (responsible) {
            return `${responsible?.firstName} ${responsible?.lastName}`;
        }
        return task?.action?.agentName;
    };

    const handleResponsibleFlow = () => {
        if (task?.workflowObject?.group?.agentObject) {
            return `${task?.workflowObject?.group?.agentObject?.firstName} ${task?.workflowObject?.group?.agentObject?.lastName}`;
        }
        if (task?.workflowObject?.group?.uid === "process-creator-group") {
            return renderName(task?.workflowObject?.createdBy);
        }

        return task?.workflowObject?.group?.name || "Validar fluxo";
    };

    return (
        <Modal open={open} close={close} title={i18n("Informações sobre a solicitação")} >
            <div>
                <p>
                    <strong>{i18n("Solicitação")}:</strong>{" "}
                    {task?.workflowObject?.description || "Sem informação"}
                </p>
                <p>
                    <strong>{i18n("Assunto")}:</strong>{" "}
                    {task?.action?.formTitle ||
                        task?.action?.instructions ||
                        "Sem informação"}
                </p>
                <p>
                    <strong>{i18n("Solicitante")}:</strong>{" "}
                    {(!task?.workflowObject?.hideRequester
                        ? renderName(task?.workflowObject?.createdBy)
                        : i18n("Anônimo")) || "Sem informação"}
                </p>
                <p>
                    <strong>{i18n("Data da solicitação")}:</strong>{" "}
                    {task?.createdAt
                        ? format.date(toDate(task?.createdAt))
                        : "Sem informação"}
                </p>
                {task?.expirationDateString && (
                    <p>
                        <strong>{i18n("Data de expiração")}:</strong>{" "}
                        {format.date(toDate(task?.expirationDateString))}{" "}
                    </p>
                )}
                <p>
                    <strong>{i18n("Dono da Tarefa")}:</strong>{" "}
                    {renderName(task?.action?.agent) || "Sem informação"}
                </p>
                <p>
                    <strong>{i18n("Responsável pelo Fluxo")}: </strong>
                    {handleResponsibleFlow()}
                </p>
            </div>
        </Modal>
    );
}
