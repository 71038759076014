import React, { Fragment } from "react";
import { useStore } from "@hooks";
import Authentication from "@screens/Authentication";

function ProtectedComponent(props) {
    const { auth } = useStore();
    return (
        <Fragment>
            {auth.isAuthenticated || !props.isProtected ? (
                <props.Screen {...props} />
            ) : (
                <Authentication {...props} />
            )}
        </Fragment>
    );
}

export default ProtectedComponent;
