import styles from './sidebarMobilde.module.scss';
import React from "react";

import { history } from '@store';
import { Icon } from 'zerocode';

export function SidebarMobile({ modules, activeModule }) {
  return (
    <nav className={styles.sidebar}>
      {modules?.map((module) => (
        <button
          key={module.key}
          className={styles.button}
          onClick={() => history.push(module.path)}
          data-active={activeModule?.key === module.key}
        >
          <Icon icon={module.icon} />
        </button>
      ))}
    </nav>
  )
}