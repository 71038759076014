import styles from './icon.module.scss'
import Icon from "@custom/Icon";
import React from 'react'

export function StepIcon({ step, icon }) {

  const changeIcon = () => {
    if (step === "removed" || step === "canceled") return "faTrash"
    if (step === "completed") return "faCheck"
    if (typeof step === "object") return "faCheck"

    return "faDiagramProject"
  }

  const changeStep = () => {

    if (step === "not-completed") return 'pending'
    if (typeof step === "object") return "completed"
    if (step === "archived") return 'review'
    if (step === "canceled") return 'removed'
    return step
  }

  return <span className={`${styles.icon} ${styles[changeStep()]} ${icon ? styles.hasIcon : styles.notIcon}`}>

    {icon && <Icon icon={changeIcon()} />}
  </span>
}