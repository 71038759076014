"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function getBrowser() {
  if (navigator.userAgent.includes('Opera') || navigator.userAgent.includes('OPR')) {
    return 'Opera';
  } else if (navigator.userAgent.includes('Edg')) {
    return 'Edge';
  } else if (navigator.userAgent.includes('Chrome')) {
    return 'Chrome';
  } else if (navigator.userAgent.includes('Safari')) {
    return 'Safari';
  } else if (navigator.userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (navigator.userAgent.includes('MSIE') || !!document.documentMode //IF IE > 10
  ) {
    return 'IE';
  } else {
    return undefined;
  }
}
var _default = getBrowser;
exports["default"] = _default;