import React, { useState } from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Link,
} from "@mui/material";

import i18n from "@i18n";
import { useMyFiles } from "../../contexts/MyFilesContext";
import { services } from "@store";
import { useStore } from "@hooks";

import { InputSearch } from "components/inputs";
import { FilterAdm } from "components/filterAdm";

import styles from "./styles.module.scss";
import { Select } from "components";

/**
 * @typedef Field
 * @type {import("../../contexts/MyFilesContext").Field}
 */

/**
 * @typedef Period
 * @type {import("../../contexts/MyFilesContext").Period}
 */

const fields = ["action.instructions", "action.documentInputDescription"];
/** @type {Period[]} */
const periods = ["none", "month", "semester", "trimester", "year", "custom"];

function FileFilters() {
    const {
        history,
        search,
        activeField,
        activePeriod,
        customPeriod,
        applyFilters,
        allDocuments,
        setFilteredDocuments,
        setSelectedUser,
        setSelectedGroup,
    } = useMyFiles();
    const { auth } = useStore();

    const [mirrorSearch, setMirrorSearch] = useState("");
    const [mirrorField, setMirrorField] = useState("");
    const [mirrorPeriod, setMirrorPeriod] = useState("");
    const [errors, setErrors] = useState({
        field: false,
        period: false,
        search: false,
    });

    const handleApplyAndSearch = () => {
        /**
         * @param {string} value
         * @returns {value is Field}
         */
        const isField = (value) => fields.includes(value);
        /**
         * @param {any} value
         * @returns {value is Period}
         */
        const isPeriod = (value) => periods.includes(value);

        const validations = { ...errors };
        validations.field = !isField(mirrorField);
        validations.period = !isPeriod(mirrorPeriod);
        validations.search = !mirrorSearch;

        setErrors(validations);

        const hasErrors = Object.values(validations).some(
            (hasError) => hasError
        );
        if (hasErrors) return;

        const filteredDocuments = applyFilters([...allDocuments], {
            // @ts-ignore
            field: mirrorField,
            // @ts-ignore
            period: mirrorPeriod,
            search: mirrorSearch,
            customPeriod,
        });
        setFilteredDocuments(filteredDocuments);

        setMirrorSearch("");
        setMirrorField("");
        setMirrorPeriod("");
    };

    const handleCleanFilters = () => {
        setMirrorSearch("");
        setMirrorField("");
        setMirrorPeriod("");

        const filteredDocuments = applyFilters([...allDocuments], {
            // @ts-ignore
            field: mirrorField,
            // @ts-ignore
            period: mirrorPeriod,
            search: mirrorSearch,
            customPeriod,
        });

        setFilteredDocuments(filteredDocuments);
    };

    const handleApplyFilter = async ({ mirrorGroup, mirrorUser }) => {
        const alreadyFetched = !!history.find(
            (item) =>
                item.filters.group === mirrorGroup &&
                item.filters.user === mirrorUser
        );

        if (!alreadyFetched) {
            services.spinner.show();

            await services.documents.listMore({
                group: mirrorGroup,
                user: mirrorUser,
                company: auth.company.uid,
            });

            services.spinner.hide();
        }

        setSelectedGroup(mirrorGroup);
        setSelectedUser(mirrorUser);
    };

    const clearFilter = () => {
        setSelectedGroup("");
        setSelectedUser("");
    }

    return (
        <div className={styles.fileFiltersContainer}>
            <FilterAdm
                clear={clearFilter}
                applyFilter={handleApplyFilter} />
            <strong>{i18n("Filtro por arquivo")}</strong>
            <div className={styles.FileFilters}>
                <div className={styles.filterArea}>
                    <InputSearch
                        placeholder={i18n("Busca rápida de arquivos")}
                        setValue={setMirrorSearch}
                        value={mirrorSearch}
                    />
                    <section className={styles.contentSelect}>

                        <Select
                            title="Filtrar por"
                            error={errors.field}
                            onChange={setMirrorField}
                            value={mirrorField}
                            itens={fields.map(option => ({ title: option, value: option }))}
                        />
                        <Select
                            title="Périodo"
                            error={errors.period}
                            onChange={setMirrorPeriod}
                            value={mirrorPeriod}
                            itens={periods.map(option => ({ title: option, value: option }))}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleApplyAndSearch}
                            size="small"
                        >
                            {i18n("Aplicar")}
                        </Button>
                    </section>
                </div>

                <div>
                    <p className={styles.FileFiltersActiveFilters}>
                        <strong>{i18n("Campo")}:</strong>{" "}
                        {i18n(activeField || "none")} |{" "}
                        <strong>{i18n("Período")}:</strong>{" "}
                        {i18n(activePeriod || "none")} |{" "}
                        <strong>{i18n("Busca")}:</strong> {search || "N/A"} ·{" "}
                        <Link onClick={handleCleanFilters}>
                            {i18n("Limpar")}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FileFilters;
