import React from "react";
import InboxPage from "./InboxPage";
import { InboxProvider } from "./contexts/inboxContext";

const Inbox = ({ match }) => (
    <InboxProvider match={match}>
        <InboxPage />
    </InboxProvider>
);

export default Inbox;
