// @ts-nocheck
import styles from "./filterAdm.module.scss";

import React, { useMemo, useState } from "react";
import { useStore } from "@hooks";
import {
    Button,
} from "@mui/material";
import i18n from "@i18n";
import { ButtonLoading } from "components/buttons/buttonLoading";
import { Checkbox, Select } from "components/ui";

export function FilterAdm({ applyFilter, clear }) {
    const [mirrorGroup, setMirrorGroup] = useState("");
    const [mirrorUser, setMirrorUser] = useState("");
    const [loading, setLoading] = useState(false);
    const [renderFilter, setRenderFilter] = useState(false);
    const {
        auth: { user },
        groups,
        users,
    } = useStore();

    const filteredUsers = useMemo(() => {
        if (mirrorGroup) {
            return users.filter((user) =>
                (user?.groups || []).includes(mirrorGroup)
            );
        }

        return users;
    }, [mirrorGroup, users]);

    const handleApply = async () => {
        setLoading(true);
        await applyFilter({ mirrorUser, mirrorGroup });
        setLoading(false);
    };

    const clearFilter = () => {
        setMirrorGroup("");
        setMirrorUser("");
        clear();
    };

    const subtitle = () => {
        const groupSelected = groups.find(group => group.uid === mirrorGroup)
        const userSelected = users.find(user => user.uid === mirrorUser)

        if (!mirrorGroup && !mirrorUser) return <></>

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 16
                }}
            >
                <p>
                    {groupSelected?.name || ""}
                    {userSelected && `/ ${userSelected?.firstName} ${userSelected?.lastName}`}
                </p>

                <Button
                    onClick={clearFilter}
                    variant="contained"
                    size="small"
                >
                    {i18n("limpar")}
                </Button>
            </div>
        )
    }

    if (!user?.companyadmin || !groups) return <></>;

    return (
        <div className={styles.filterAdmContainer}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >

                <Checkbox
                    checked={renderFilter}
                    onChange={() => setRenderFilter(!renderFilter)}
                    label={
                        renderFilter
                            ? i18n("Fechar Filtro Usuário")
                            : i18n("Abrir filtro por usuário")
                    }
                />
                {subtitle()}

            </div>
            {renderFilter && (
                <div className={styles.searchSelectArea}>

                    <Select
                        title="Grupos"
                        value={mirrorGroup}
                        onChange={setMirrorGroup}
                        itens={groups.map(group => ({
                            value: group.uid,
                            title: group.name
                        }))}

                    />
                    <Select
                        title="Usuários"
                        value={mirrorUser}
                        onChange={setMirrorUser}
                        itens={filteredUsers.map(user => ({
                            value: user.uid,
                            title: ` ${user.firstName} ${user.lastName}`
                        }))}

                    />
                    <ButtonLoading onClick={handleApply} loading={loading}>
                        {i18n("Aplicar e pesquisar")}
                    </ButtonLoading>
                </div>
            )}
        </div>
    );
}
