import { useState, useEffect } from "react";

import { useStore } from "@hooks";
import { services } from "@store";

export function useSelectedCompanyArea({ getListFlows }) {
  const {
    auth: { user, company },
    companyArea,
    executionFlows,
    workflows,
    companyAreaSelected
  } = useStore();
  const [isActiveModalCompanyArea, setIsActiveModalCompanyArea] = useState(false);

  const handleCloseModalCompanyArea = () => {
    setIsActiveModalCompanyArea(false)
  }
  const handleGetLFows = async () => {
    if (!companyAreaSelected) {
      services.message.error({ title: "Selecione uma área de negócio para prosseguir!" })
      return
    }
    services.spinner.show()
    await getListFlows({ companyAreaUid: companyAreaSelected, type: "iCanSee" })
    services.spinner.hide()
    handleCloseModalCompanyArea()
  }

  useEffect(() => {

    if (executionFlows.length === 0 && companyArea.length > 0) {
      setIsActiveModalCompanyArea(true)

    } else {
      setIsActiveModalCompanyArea(false)
    }
    return () => {}
  }, [companyArea])

  useEffect(() => {

    if (!company || !user || !workflows) return;

    async function getCompanyArea() {

      try {
        services.spinner.show()
        await services.companyArea.list({ company: company.uid, workflows })
        services.spinner.hide()

      } catch (error) {
        console.log(error)
      } finally {
        services.spinner.hide()
      }
    }

    if (companyArea.length === 0) {
      getCompanyArea()
    }

    return () => {getCompanyArea()}
  }, [company, user, workflows])

  return {
    isActiveModalCompanyArea,
    handleCloseModalCompanyArea,
    handleGetLFows
  };
}
