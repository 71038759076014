import React from "react";
import { MyFilesProvider } from "./contexts/MyFilesContext";
import MyFilesPage from "./MyFilesPage";

const MyFiles = ({ match }) => (
    <MyFilesProvider match={match}>
        <MyFilesPage />
    </MyFilesProvider>
);

export default MyFiles;
