import React from "react";
import {
    Checkbox as MuiCheckbox,
    FormControl,
    FormControlLabel,
} from "@mui/material";
import i18n from "@i18n";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Checkbox(props) {
    const { formValues, fieldConfig, updateFormValues, formFilled } = props;
    const field = fieldConfig.name;
    const checked = !!formValues[field];

    /** @param {React.ChangeEvent<HTMLInputElement>} evt */
    const handleFormValueChange = (evt) => {
        updateFormValues(field, evt.target.checked);
    };

    if (formFilled) {
        return (
            <>
                <p>
                    <strong>{field}</strong>
                </p>
                <p>{i18n(checked ? "Sim" : "Não")}</p>
            </>
        );
    }

    return (
        <FormControl fullWidth className={styles.Checkbox} variant="outlined">
            <FormControlLabel
                label={field}
                control={
                    <MuiCheckbox
                        onChange={handleFormValueChange}
                        checked={checked}
                    />
                }
            />
        </FormControl>
    );
}

export default Checkbox;
export { Checkbox };
