import React from 'react';

const SHOW_MODAL = 'MODAL_SHOW';
const HIDE_MODAL = 'MODAL_HIDE';

const service = (dispatch) => ({
	/**
	 * @param {React.ReactNode} children 
	 * @param {() => void=} handleClose 
	 */
	show: (
		children, 
		handleClose = () => dispatch({ type: HIDE_MODAL })
	) => {
		dispatch({
			type: SHOW_MODAL,
			children,
			handleClose,
		});
	},
	hide: () => {
		dispatch({
			type: HIDE_MODAL,
		});
	},
});

const INIT_STATE = {
	children: null,
	handleClose: () => {},
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				...state,
				children: action.children,
				handleClose: action.handleClose || state.handleClose,
			};

		case HIDE_MODAL:
			return {
				...state,
				...INIT_STATE,
			};

		default:
			return { ...state };
	}
};

export default { reducer, service };
