import Axios from "axios";
import { WEATHER_BASEURL, WEATHER_TOKEN } from ".";

export async function getForecast({ latitude, longitude }) {
    const FORECAST_URL = `${WEATHER_BASEURL}data/2.5/forecast?lat=${latitude}&lon=${longitude}&lang=pt_br&appid=${WEATHER_TOKEN}&units=metric`;

    try {
        const { data } = await Axios.get(FORECAST_URL);

        const uniqueForecastDays = [];

        const daysForecast = data.list.filter((forecast) => {
            const forecastDate = new Date(forecast.dt_txt).getDate();

            if (!uniqueForecastDays.includes(forecastDate)) {
                return uniqueForecastDays.push(forecastDate);
            }
        });

        return daysForecast;
    } catch (error) {
        console.log("ERROR getForecast : >=>", error);
    }
}
