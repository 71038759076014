import { toDate } from "@functions";

const executedFormModel = {
    uid: "",
    company: "",
    phase: "",
    workflow: "",
    agent: "",
    executionFlow: "",
    /**
     * @type {{
     *     url: string;
     *     date: Date;
     * }[]}
     */
    documents: [],
    /**
     * @type {{
     *     [field: string]: any;
     * }}
     */
    fields: {},
    action: {
        uid: "",
        instructions: "",
        documentInputDescription: "",
    },
    deleted: false,
    createdAt: new Date(),
    createdBy: "",
};

/**
 * @typedef ExecutedForm
 * @type {typeof executedFormModel}
 */

function createExecutedForm(data) {
    if (!data) data = {};

    return {
        ...executedFormModel,
        ...data,
        action: {
            ...executedFormModel.action,
            ...data.action,
        },
        deleted: !!data.deleted,
        createdAt: toDate(data.createdAt),
    };
}

export { executedFormModel, createExecutedForm };
