"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _app["default"];
  }
});
exports.firebaseConfig = void 0;
var _app = _interopRequireDefault(require("firebase/app"));
require("firebase/storage");
require("firebase/firestore");
require("firebase/auth");
var firebaseConfig = {
  apiKey: 'AIzaSyAYj_UST1cRLxH7pqC2-_t7N5pWt9jutF4',
  authDomain: 'dokia-newton.firebaseapp.com',
  databaseURL: 'https://dokia-newton.firebaseio.com',
  projectId: 'dokia-newton',
  storageBucket: 'dokia-newton.appspot.com',
  messagingSenderId: '603770092293',
  appId: '1:603770092293:web:82478df9a5466d56bc956c',
  measurementId: 'G-70VZH80YG6'
};
exports.firebaseConfig = firebaseConfig;
var app = _app["default"].initializeApp(firebaseConfig, 'dokia-newton');
var _default = app;
exports["default"] = _default;