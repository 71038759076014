"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _firebase["default"];
  }
});
exports.signatureType = exports.signatoryType = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _firebase = _interopRequireWildcard(require("./firebase.config"));
var _store = require("../store");
var _common = require("../common");
var _i18n = _interopRequireDefault(require("../../@i18n"));
var _axios = _interopRequireDefault(require("axios"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var templateCollection = _firebase["default"].firestore().collection('template');
var signatureProcessCollection = _firebase["default"].firestore().collection('signatureProcess');
var safeBoxCollection = _firebase["default"].firestore().collection('safeBox');
var storeKey = '@K#App$collectame';
var types = {
  update: 'collectame-'
};
var storedState = JSON.parse(localStorage.getItem(storeKey)) || {};
var createInitialState = function createInitialState(storedState) {
  return _objectSpread({}, storedState);
};
var INITIAL_STATE = createInitialState(storedState);
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var getState = function getState(oState, action) {
    switch (action.type) {
      default:
        return _objectSpread({}, oState);
    }
  };
  var finalState = getState(state, action);
  localStorage.setItem(storeKey, JSON.stringify(finalState));
  return finalState;
};
var getCurrentState = function getCurrentState() {
  return _store.store.getState().appcenter;
};

//("i18n('assinar-como-parte')", i18n('assinar-como-parte'))
var signatoryType = {
  'assinar-como-parte': (0, _i18n["default"])('assinar-como-parte'),
  'assinar-como-testemunha': (0, _i18n["default"])('assinar-como-testemunha'),
  'assinar-como-comprador': (0, _i18n["default"])('assinar-como-comprador'),
  'assinar-como-vendedor': (0, _i18n["default"])('assinar-como-vendedor'),
  'assinar-como-interveniente': (0, _i18n["default"])('assinar-como-interveniente'),
  'acusar-recebimento': (0, _i18n["default"])('acusar-recebimento'),
  'assinar-como-emissor, Endossante': (0, _i18n["default"])('assinar-como-emissor, Endossante'),
  'assinar-como-fiador': (0, _i18n["default"])('assinar-como-fiador'),
  'assinar-como-parte-fiador': (0, _i18n["default"])('assinar-como-parte-fiador'),
  'assinar-como-responsavel-solidario': (0, _i18n["default"])('assinar-como-responsavel-solidario'),
  'assinar-como-parte-responsavel-solidario': (0, _i18n["default"])('assinar-como-parte-responsavel-solidario'),
  'assinar-como-fiel-depositario': (0, _i18n["default"])('assinar-como-fiel-depositario'),
  'assinar-como-advogado': (0, _i18n["default"])('assinar-como-advogado'),
  'assinar-como-procurador': (0, _i18n["default"])('assinar-como-procurador'),
  'assinar-como-locador': (0, _i18n["default"])('assinar-como-locador'),
  'assinar-como-locatario': (0, _i18n["default"])('assinar-como-locatario')
};
exports.signatoryType = signatoryType;
var signatureType = {
  'ds-eletronic': 'Assinatura Real Manuscrita - Assinatura Eletrônica',
  'icp-brasil': 'ICP Brasil - Assinatura Digital com Certificado (A1, A3, Token, SmartCard)'
};
exports.signatureType = signatureType;
var isLocal = window.location.href.indexOf('localhost') > -1;
var getAppcenterStore = function getAppcenterStore() {
  return _store.store.getState().appcenter;
};
var api = function api() {
  var api = _axios["default"].create({
    baseURL: isLocal ? 'http://localhost:5000/collecta-me/us-central1/webApi/api/v1/' : 'https://us-central1-collecta-me.cloudfunctions.net/webApi/api/v1',
    timeout: 240000
  });
  var service = {
    axios: function axios() {
      return api;
    },
    auth: {
      login: {
        token: function token(_token) {
          return _firebase["default"].auth().signInWithCustomToken(_token);
        }
      },
      logout: function logout() {
        if (!_firebase["default"]) return;
        return _firebase["default"].auth().signOut();
      }
    },
    template: {
      list: function list() {
        var appcenterStore = getAppcenterStore();
        if (!appcenterStore.authenticated) return Promise.reject();
        //('appcenterStore.company', appcenterStore.company)
        return templateCollection.where('company', '==', appcenterStore.company.uid).where('deleted', '==', false).get().then(function (s) {
          return s.docs.map(function (d) {
            return d.data();
          });
        });
      }
    },
    safeBox: {
      list: function list() {
        var appcenterStore = getAppcenterStore();
        if (!appcenterStore.authenticated) return Promise.reject();
        return safeBoxCollection.where('company', '==', appcenterStore.company.uid).where('deleted', '==', false).get().then(function (s) {
          return s.docs.map(function (d) {
            return d.data();
          });
        });
      }
    }
  };
  return service;
};
var collectame = api();
_store.store.injectReducer('collectame', reducer, collectame);
var _default = collectame;
exports["default"] = _default;