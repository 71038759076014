"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
/**
 * @param {{ [key: string]: string }} json
 * @returns {string}
 */
function jsonToUrlQueries(json) {
  return Object.entries(json).reduce(function (result, _ref, i) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (i === 0) {
      result += "&";
    }
    result += "".concat(key, "=").concat(value);
    return result;
  }, "");
}
var _default = jsonToUrlQueries;
exports["default"] = _default;