import React, { useState } from 'react';
import styles from "./styles.module.scss"

import { Menu as MenuMui, MenuItem } from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert';
import i18n from '@i18n';
import { Button } from '../button/button';
import { useStore } from '@hooks';

/**
 * @param {{
 *  title?: string
 *  options: {
 *    id?: string | number
 *    title: string
 *    onClick: () => void | Promise<void>
 *  }[],
 *  icon?: import('react').ReactNode,
 * }} props
 */

export function Menu({ options, icon, title }) {
  const { theme: { theme } } = useStore()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickOption = (onclick) => (event) => {
    event.stopPropagation();
    onclick(event);
    handleClose(event);
  };

  return (
    <div>
      <Button
        buttonIcon
        icon={icon ? icon : <MoreVertIcon />}
        onClick={handleClick}
      />
      <MenuMui
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            background: theme === "dark" ? "#2e3646" : ""
          }
        }}
      >
        {title && (
          <MenuItem className={styles.title} >
            {i18n(title)}
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index}
            onClick={handleClickOption(option.onClick)}
            sx={{
              color: theme == "dark" ? "#f0f0f0" : "",
            }}
          >
            {i18n(option.title)}
          </MenuItem>
        ))}
      </MenuMui>
    </div>
  );
}
