import LacunaWebPKI from "web-pki-fixed";

const pki = new LacunaWebPKI();

/**
 * @typedef PkiCertificate
 * @type {import("web-pki-fixed").CertificateModel}
 */

const lacunaPki = {
    /** @returns {Promise<void>} */
    init: () =>
        new Promise((resolve) => {
            pki.init({
                ready: resolve,
            });
        }),

    /** @returns {Promise<PkiCertificate[]>} */
    listCertificates: () =>
        new Promise((resolve, reject) => {
            pki.listCertificates()
                .success((list) => {
                    resolve(list);
                })
                .fail((err) => reject(err));
        }),
    /**
     * @param {{
     *     thumbprint: string;
     *     token: string;
     * }} params
     * @returns {Promise<string>}
     */
    signWithRestPki: (params) =>
        new Promise((resolve, reject) => {
            pki.signWithRestPki(params)
                .success((token) => {
                    resolve(token);
                })
                .fail((err) => reject(err));
        }),
};

export { lacunaPki };
export default lacunaPki;
