import React from "react";
import { LinkedInPage } from "zerocode";

export default function LinkedIn() {
    console.log(">=> >=>  LinkedIn  <=< <=<");
    console.log("LinkedInPage: >=>", LinkedInPage);
    return (
        <div className="LinkedIn">
            <LinkedInPage />
        </div>
    );
}
