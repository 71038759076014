import { taskCollection } from "@firebase-config/collections";
import { createTask } from "@models";

/**
 * @typedef Task
 * @type {import("@models/task").Task}
 */

/**
 * Function to search tasks
 * @param {{
 *      limit?: number;
 *      types: string[];
 *      createdAt: Date;
 *      search: string;
 *      userUid: string;
 * }} params
 * @returns {Promise<string | Task[]>}
 */
export async function searchTasks({
    limit,
    types,
    search,
    createdAt,
    userUid,
}) {
    try {
        let query = taskCollection
            .where("createdAt", "<=", createdAt)
            .where("agent", "==", userUid)
            .where("workflowObject.security", "==", "public")
            .where("deleted", "==", false);

        if (types.length > 0) {
            query = query.where("action.type.type", "in", types);
        }

        if (limit) {
            query = query.limit(limit);
        }

        const { docs } = await query.get();

        const tasks = docs.map((doc) => doc.data());
        const searchLowerCase = search.toLowerCase();

        const filteredTasks = tasks.filter((task) => {
            // const instructions = (task.action.instructions || "").toLowerCase();
            // const formTitle = (task.action.formTitle || "").toLowerCase();
            const workflowTitle = task?.workflowObject?.description.toLowerCase()

            if (
                // instructions.includes(searchLowerCase) ||
                // formTitle.includes(searchLowerCase)
                workflowTitle.includes(searchLowerCase)
            ) {
                return true;
            }

            return false;
        });

        if (!filteredTasks.length) return "not-found-tasks";

        return filteredTasks.map(createTask);
    } catch (error) {
        console.log("ERROR in searchTasks: >=>", error);
        return "error-search-tasks";
    }
}
