/** @param {string} name */
function getNameInitials(name) {
    const fullName = name
        .trim()
        .replace(/\s+/g, " ");
    let initials = fullName.split(" ").map((name) => name[0]);
    initials = [initials[0], initials[initials.length - 1]];

    return initials.join("");
}

export default getNameInitials;
export { getNameInitials };