"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function isLocal() {
  return window.location.href.includes("localhost");
}
var _default = isLocal;
exports["default"] = _default;