import { useStore } from '@hooks';
import styles from './filterTask.module.scss'
import i18n from "@i18n";
import { useInbox } from '@screens/Inbox/contexts/inboxContext';
import React from "react"
import { Icon } from 'zerocode';
import { FilterAdm } from 'components/filterAdm';
import { services } from '@store';

export function FilterTasks() {
  const cardsFilters = [
    {
      key: "pending",
      icon: "faDiagramProject",
      quantity: 0,
      step: i18n("em Aberto"),
    },
    {
      key: "waiting-review",
      icon: "faDiagramProject",
      quantity: 0,
      step: i18n("em revisão"),
    },
    {
      key: "review",
      icon: "faDiagramProject",
      quantity: 0,
      step: i18n("revisadas"),
    },
    {
      key: "completed",
      icon: "faCheck",
      quantity: 0,
      step: i18n("completed"),
    },
    {
      key: "removed",
      icon: "faTrash",
      quantity: 0,
      step: i18n("canceladas"),
    },
  ];
  const {
    filterStep,
    handleChangeFilterStep,
    resultFilterTaskDate,
    setSelectedGroup,
    setSelectedUser,
    filterUserSelected

  } = useInbox()
  const { tasks, auth, theme: { theme } } = useStore()

  const handleQuantity = (step) => {
    const quantity = resultFilterTaskDate.length > 0 ?
      resultFilterTaskDate.filter((task) => task.status === step) :
      filterUserSelected.filter((task) => task.status === step)
    return quantity.length
  }

  const handleApplyFilter = async ({ mirrorGroup, mirrorUser }) => {
    const alreadyFetched = !!tasks.find(
      (item) =>
        item.action.group.uid === mirrorGroup &&
        item.action.agent === mirrorUser || item.action.agent === mirrorUser
    );

    if (!alreadyFetched) {
      try {
        services.spinner.show();
        await services.tasks.listMore({
          company: auth.company.uid,
          user: mirrorUser,
          group: mirrorGroup,
        });

      } catch (error) {

        services.toast.error(i18n("Ops! Erro ao buscar solicitações do usuário"))

      } finally {
        services.spinner.hide();
      }
    }
    setSelectedGroup(mirrorGroup);
    setSelectedUser(mirrorUser);
  };

  const clearFilter = () => {
    setSelectedGroup("");
    setSelectedUser("");
  }

  return (
    <div className={`${styles.filterContent} ${styles[theme]}`}>
      <FilterAdm
        clear={clearFilter}
        applyFilter={handleApplyFilter}
      />
      <div className={styles.cards}>
        {cardsFilters.map((card) => (
          <div
            key={card.key}
            className={`${styles.card} ${styles[card.key]} ${filterStep === card.key && styles.active}`}
            onClick={() => handleChangeFilterStep(card.key)}
          >
            <div className={styles.headerCard}>
              <div
                className={styles.icon}
                data-status={card.key}
              >
                <Icon icon={card.icon} />
              </div>

              <div className={styles.quantity}>
                <span>{handleQuantity(card.key)}</span>
              </div>
            </div>

            <div className={styles.text}>
              <p >
                {i18n("Tarefas")}
              </p>
              <strong >
                {card.step}
              </strong>

            </div>
          </div>
        ))}
      </div>
    </div>
  )
}