import styles from './inputSearch.module.scss';
import Icon from "@custom/Icon";
import { useStore } from '@hooks';
import React from "react";

export function InputSearch({ placeholder, value, setValue, ...rest }) {

  const { theme: { theme } } = useStore()

  return (
    <label className={`${styles.label} ${styles[theme]}`}>
      <span>
        <Icon icon="faSearch" />
      </span>
      <input
        type="text"
        placeholder={placeholder}
        value={value} onChange={(e) => setValue(e.target.value)}
        {...rest}
      />
    </label>
  )
}
