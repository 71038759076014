import { serviceWorkflow } from "@services/workflow";

const LIST_COMPANY_WORKFLOWS = "LIST_COMPANY_WORKFLOWS";
const COMPANY_WORKFLOWS_CHANGE = "COMPANY_WORKFLOWS_CHANGE";

/**
 * @typedef Params
 * @type {import("./index.js").Params}
 */

const service = (dispatch) => ({
    /** @param {Params} params */
    list: async ({ company }) => {
        const result = await serviceWorkflow.list({ companyUid: company });
        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceWorkflow.watch({
            list,
            callback: (workflowList) => {
                dispatch({
                    type: COMPANY_WORKFLOWS_CHANGE,
                    payload: { list: workflowList },
                });
            },
        });

        dispatch({
            type: LIST_COMPANY_WORKFLOWS,
            payload: { company, list },
        });
    },
});

const INIT_STATE = {
    company: "",
    list: [],
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_COMPANY_WORKFLOWS:
            return {
                ...state,
                company: action.payload.company,
                list: action.payload.list,
            };

        case COMPANY_WORKFLOWS_CHANGE:
            const allWorkflows = [...state.list, ...action.payload.list];

            return {
                ...state,
                list: allWorkflows.reduce((list, workflowItem) => {
                    const alreadyAdded = !!list.find(
                        (item) => item.uid === workflowItem.uid
                    );
                    if (alreadyAdded) return list;
                    list.push(workflowItem);
                    return list;
                }, []),
            };

        default:
            return { ...state };
    }
};
export default { reducer, service };
