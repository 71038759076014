import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { services } from "@store";
import { useAsyncEffect, useStore } from "@hooks";
import { semesters, trimesters } from "@constants";
import { serviceExecutedDocuments } from "@services/executedDocument";

/**
 * @typedef Document
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * @typedef Period
 * @type {"none" | "month" | "semester" | "trimester" | "year" | "custom"}
 */

/**
 * @typedef Field
 * @type {keyof Document}
 */

/**
 * @typedef Access
 * @type {Document["accesses"][number]}
 */

/**
 * @typedef CustomPeriod
 * @type {{
 *     startDate: Date;
 *     endDate: Date;
 * }}
 */

/**
 * @typedef HistoryItem
 * @type {{
 *     date: Date;
 *     filters: {
 *         group: string;
 *         user: string;
 *         search: string;
 *     };
 * }}
 */

const today = new Date();
const currentYear = today.getFullYear();

const defaultStartDate = new Date(currentYear, 0, 1);
const defaultEndDate = new Date(currentYear + 1, 0, 1);

const MyFilesContext = createContext({
    /** @type {HistoryItem[]} */
    history: [],
    search: "",
    /** @param {string} text */
    setSearch: (text) => {},
    /** @type {Field} */
    activeField: undefined,
    /** @param {Field} field */
    setActiveField: (field) => {},
    /** @type {Period | undefined} */
    activePeriod: undefined,
    /** @param {Period} period */
    setActivePeriod: (period) => {},
    customPeriod: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    },
    /**
     * @type {(customPeriod: {
     *     startDate: Date;
     *     endDate: Date;
     * }) => void}
     */
    setCustomPeriod: (customPeriod) => {},
    /** @type {Document[]} */
    allDocuments: [],
    /** @type {Document[]} */
    filteredDocuments: [],
    /** @type {Document[]} */
    recentlyOpenDocuments: [],
    /** @type {Document | undefined} */
    openDocument: undefined,
    /** @param {Document[]} documents */
    setFilteredDocuments: (documents) => {},
    /**
     * @param {Document[]} documents
     * @param {{
     *     field?: Field;
     *     period?: Period;
     *     customPeriod?: CustomPeriod;
     *     search?: string;
     * }=} params
     * @returns {Document[]}
     */
    applyFilters: (documents, params) => [],
    selectedUser: "",
    /** @param {string} uid */
    setSelectedUser: (uid) => {},
    selectedGroup: "",
    /** @param {string} uid */
    setSelectedGroup: (uid) => {},
});

/**
 * @param {{
 *     match: {
 *         params: {
 *             documentUid?: string;
 *         };
 *     };
 *     children: React.ReactNode;
 * }} props
 */
function MyFilesProvider({ match, children }) {
    /** @type {[HistoryItem[], React.Dispatch<React.SetStateAction<HistoryItem[]>>]} */
    const [history, setHistory] = useState([]);
    const [search, setSearch] = useState("");
    /** @type {[Field, React.Dispatch<React.SetStateAction<Field>>]} */
    const [activeField, setActiveField] = useState();
    /** @type {[Period, React.Dispatch<React.SetStateAction<Period>>]} */
    const [activePeriod, setActivePeriod] = useState();
    const [customPeriod, setCustomPeriod] = useState({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });
    /** @type {[Document[], React.Dispatch<React.SetStateAction<Document[]>>]} */
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const { auth, documents: allDocuments } = useStore();

    const [selectedUser, setSelectedUser] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");

    const { documentUid } = match.params;

    /**
     * @param {Document[]} documents
     * @param {{
     *     field?: Field;
     *     period?: Period;
     *     customPeriod?: CustomPeriod;
     *     search?: string;
     * }=} params
     */
    const applyFilters = (documents, params = {}) => {
        const {
            field = activeField,
            period = activePeriod,
            customPeriod: dates = customPeriod,
            search: inputSearch = search,
        } = params;

        /** @param {Document} document */
        const filterByTime = (document) => {
            if (!period || period === "none") return true;

            /** @type {Date} */
            let startDate;
            /** @type {Date} */
            let endDate;

            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth();

            if (period === "month") {
                startDate = new Date(currentYear, currentMonth, 1);
                endDate = new Date(currentYear, currentMonth + 1, 1);
            } else if (period === "trimester") {
                const currentTrimester = trimesters.find((trimester) =>
                    trimester.includes(currentMonth)
                );

                const startMonth = currentTrimester[0];
                const endMonth = currentTrimester[currentTrimester.length - 1];

                startDate = new Date(currentYear, startMonth, 1);
                endDate = new Date(currentYear, endMonth + 1);
            } else if (period === "semester") {
                const currentSemester = semesters.find((semester) =>
                    semester.includes(currentMonth)
                );

                const startMonth = currentSemester[0];
                const endMonth = currentSemester[currentSemester.length - 1];

                startDate = new Date(currentYear, startMonth, 1);
                endDate = new Date(currentYear, endMonth + 1, 1);
            } else if (period === "year") {
                startDate = new Date(currentYear, 0, 1);
                endDate = new Date(currentYear + 1, 0, 1);
            } else if (period === "custom") {
                startDate = dates.startDate;
                endDate = dates.endDate;
            }

            const { createdAt } = document;

            return (
                (!startDate || createdAt.getTime() >= startDate.getTime()) &&
                (!endDate || createdAt.getTime() <= endDate.getTime())
            );
        };

        /** @param {Document} document */
        const filterByField = (document) => {
            if (!field) return true;

            const parts = field.split(".");
            let value = document[parts[0]];

            for (const part of parts.slice(1)) {
                value = value[part];
            }

            return value === inputSearch;
        };

        /** @param {Document} document */
        const filterByUser = (document) => {
            if (auth.user.companyadmin)
                return !selectedUser || document.agent === selectedUser;
            return document.agent === (selectedUser || auth.user.uid);
        };

        /** @param {Document} document */
        const filterByGroup = (document) => {
            // TO DO
            return true;
        };

        setActiveField(field);
        setActivePeriod(period);
        setCustomPeriod(dates);
        setSearch(inputSearch);

        return documents
            .filter(filterByTime)
            .filter(filterByField)
            .filter(filterByUser)
            .filter(filterByGroup);
    };

    const recentlyOpenDocuments = useMemo(() => {
        /**
         * @param {Access} lastAccess
         * @param {Access} access
         */
        const getLastAccessReduce = (lastAccess, access) => {
            if (!lastAccess) return access;
            if (lastAccess.date.getTime() <= access.date.getTime())
                return access;
            return lastAccess;
        };

        return allDocuments
            .sort((a, b) => {
                const aLastAcccess = a.accesses.reduce(
                    getLastAccessReduce,
                    a.accesses[0]
                );
                const bLastAcccess = b.accesses.reduce(
                    getLastAccessReduce,
                    a.accesses[0]
                );

                if (!aLastAcccess && !bLastAcccess) return 0;
                if (!aLastAcccess) return 1;
                if (!bLastAcccess) return -1;

                const aTime = aLastAcccess.date.getTime();
                const bTime = bLastAcccess.date.getTime();

                if (aTime > bTime) return -1;
                if (aTime < bTime) return 1;
                return 0;
            })
            .filter((document) => {
                return !!document.accesses.find((access) =>
                    selectedUser !== ""
                        ? access.uid === selectedUser
                        : access.uid === auth.user.uid
                );
            });
    }, [allDocuments, auth.user.uid, selectedUser]);

    const openDocument = useMemo(() => {
        return allDocuments.find((document) => document.uid === documentUid);
    }, [allDocuments, match, documentUid]);

    useEffect(() => {
        setFilteredDocuments(applyFilters([...allDocuments]));
        return () => {}
    }, [allDocuments, selectedUser, selectedGroup]);

    useEffect(() => {
        setHistory((prev) => {
            return [
                ...prev,
                {
                    date: new Date(),
                    filters: {
                        group: selectedGroup,
                        user: selectedUser,
                        search,
                    },
                },
            ];
        });
        return () => {}
    }, [selectedGroup, selectedUser, search]);

    useAsyncEffect(async () => {
        const { documentUid } = match.params;

        if (!documentUid) return;

        const newExecutionFlow = await serviceExecutedDocuments.find({
            executedDocumentUid: documentUid,
        });

        if (typeof newExecutionFlow === "string") return;

        services.documents.addToList([newExecutionFlow]);
    }, [documentUid]);

    return (
        <MyFilesContext.Provider
            value={{
                history,
                search,
                setSearch,
                activeField,
                setActiveField,
                activePeriod,
                setActivePeriod,
                customPeriod,
                setCustomPeriod,
                allDocuments,
                filteredDocuments,
                recentlyOpenDocuments,
                openDocument,
                setFilteredDocuments,
                applyFilters,
                selectedUser,
                setSelectedUser,
                selectedGroup,
                setSelectedGroup,
            }}
        >
            {children}
        </MyFilesContext.Provider>
    );
}

function useMyFiles() {
    const context = useContext(MyFilesContext);

    if (!context) {
        throw new Error("useMyFiles must be used within a MyFilesProvider");
    }

    return context;
}

export { MyFilesContext, MyFilesProvider, useMyFiles };
