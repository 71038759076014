import React, { useState } from "react";
import { Button, Modal, Typography } from "@mui/material";

import i18n from "@i18n";
import { useAsyncEffect, useStore } from "@hooks";

import Icon from "@custom/Icon";
import WorkflowStartup from "../WorkflowStartup/inde";
import PlusWorkflux from "@assets/images/Plus Workflux.svg";

import styles from "./styles.module.scss";
import { serviceWorkflow } from "@services/workflow";

/**
 * @typedef Workflow
 * @type {import("@models/workflow").Workflow}
 */

// TODO: check if updateGroups is necessary when selecting a workflow
// const updateGroups = async (workflow) => {
// 	services.spinner.show();
// 	await Api.updateGroups(workflow.uid);
// 	services.spinner.hide();
// };

const WORKFLOWS_PAGE_LIMIT = 5;

function Workflows() {
    const { auth, theme: { theme } } = useStore();
    /** @type {[Workflow[], React.Dispatch<React.SetStateAction<Workflow[]>>]} */
    const [workflows, setWorkflows] = useState([]);
    /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}} */
    const [isLastPage, setIsLastPage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    /** @type {[Workflow, React.Dispatch<React.SetStateAction<Workflow>>]} */
    const [selectedWorkflow, setSelectedWorflow] = useState();

    // TODO: add loading (first loading and see more)

    const handleCloseModal = () => setIsModalOpen(false);

    /** @param {Workflow} workflow */
    const handleSelectWorflow = (workflow) => {
        setSelectedWorflow(workflow);
        setIsModalOpen(true);
    };

    /**
     * @param {Workflow} a
     * @param {Workflow} b
     */
    const sortWorkflows = (a, b) => {
        if (a.document.title > b.document.title) return 1;
        if (a.document.title < b.document.title) return -1;
        return 0;
    };

    const handleSeeMore = () => {
        const lastWorflow = workflows?.at(-1);
        fetchWorkflows(lastWorflow.uid);
    };

    /** @param {Workflow[]} newWorkflows */
    const parseWorkflowData = (newWorkflows) => {
        try {
            setWorkflows((prevWorflows) => {
                const allWorkflows = [...prevWorflows, ...newWorkflows];

                return allWorkflows.reduce((list, workflow) => {
                    const alreadyAdded = !!list.find(
                        (item) => item.uid === workflow.uid
                    );
                    if (alreadyAdded) return list;

                    list.push(workflow);
                    return list;
                }, []);
            });

            if (newWorkflows.length < WORKFLOWS_PAGE_LIMIT) {
                setIsLastPage(true);
            }
        } catch (error) {
            console.error("Error when parsing worflows", error);
        }
    };

    /** @param {string?} lastDocUid */
    const fetchWorkflows = async (lastDocUid) => {
        const result = await serviceWorkflow.list({
            companyUid: auth.company.uid,
            lastDocUid,
        });
        if (typeof result === "string") return;

        parseWorkflowData(result);
    };

    useAsyncEffect(() => fetchWorkflows(null), []);

    return (
        <>
            <section className={`${styles.Workflows} ${styles[theme]}`}>
                <Typography color="textPrimary">
                    <strong>{i18n("Serviços/Solicitações")}</strong>
                </Typography>

                <div className={styles.WorkflowCardList}>
                    {workflows?.length === 0 ? (
                        <div className={styles.WorkflowsNothingFound}>
                            <p>{i18n("Nada encontrado!")}</p>
                        </div>
                    ) : (
                        workflows.sort(sortWorkflows).map((workflow) => (
                            <div
                                key={workflow.uid}
                                className={styles.WorkflowCardInfo}
                                onClick={() => handleSelectWorflow(workflow)}
                            >
                                <div className={styles.bannerIcon}>
                                    <img
                                        src={PlusWorkflux}
                                        alt="Isenções"
                                        className={styles.WorkflowCardInfoImg}
                                    />
                                </div>
                                <h3 className={styles.WorkflowCardInfoTitle}>
                                    {i18n(workflow.document?.title)}
                                </h3>
                            </div>
                        ))
                    )}

                    {!!workflows?.length && !isLastPage && (
                        <div
                            className={styles.WorkflowCardInfo}
                            onClick={handleSeeMore}
                        >
                            <Icon icon="faCirclePlus" />
                            <h3 className={styles.WorkflowCardInfoTitle}>
                                {i18n("Ver Mais")}
                            </h3>
                        </div>
                    )}
                </div>
            </section>

            <WorkflowStartup
                workflow={selectedWorkflow}
                close={handleCloseModal}
                open={isModalOpen}
            />

        </>
    );
}

export default Workflows;
