import { createExecutedDocument } from "@models";
import { executedDocumentCollection } from "@firebase-config/collections";

/**
 * @typedef ExecutedDocument
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * Function to find all executedDocuments by action uid
 * @param {{
 *      actionUid: string;
 *      executionFlow: string;
 * }} params
 * @returns {Promise<ExecutedDocument[] | string>}
 */
export async function findByActionExecutedDocument({
    actionUid,
    executionFlow,
}) {
    if (!actionUid || typeof actionUid !== "string") {
        return "invalid-param-actionUid";
    }

    try {
        let query = executedDocumentCollection
            .where("action.uid", "==", actionUid)
            .where("executionFlow", "==", executionFlow)
            .where("deleted", "==", false)
            .orderBy("createdAt", "desc");

        const { docs } = await query.get();
        const allExecutedDocuments = docs.map((doc) => doc.data());

        return allExecutedDocuments.map(createExecutedDocument);
    } catch (error) {
        console.log("ERROR in findByActionExecutedDocument: >=>", error);
        return "error-list-executedDocument";
    }
}
