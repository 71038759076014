"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _fixTimezone = _interopRequireDefault(require("./fixTimezone"));
/**
 * @param {any} value
 * @returns {Date}
 */
var toDate = function toDate(value) {
  if (value && (0, _typeof2["default"])(value) === "object" && value.toDate) {
    var date = value.toDate();
    return date;
  }
  if (value && (0, _typeof2["default"])(value) === "object" && value._seconds && value._nanoseconds !== undefined) {
    var _date = new Date(parseInt(value._seconds + "" + ("000" + value._nanoseconds).slice(-3).substr(0, 3)));
    return _date;
  }
  if (value && (0, _typeof2["default"])(value) === "object" && value.seconds && value.nanoseconds !== undefined) {
    var _date2 = new Date(parseInt(value.seconds + "" + ("000" + value.nanoseconds).slice(-3).substr(0, 3)));
    return _date2;
  }
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === "string" && value !== "") {
    if (value.match(/\d{2}\/\d{2}\/\d+/)) {
      var _value$split = value.split("/"),
        _value$split2 = (0, _slicedToArray2["default"])(_value$split, 3),
        day = _value$split2[0],
        month = _value$split2[1],
        yearAndTime = _value$split2[2];
      value = "".concat(month, "/").concat(day, "/").concat(yearAndTime);
    }
    return (0, _fixTimezone["default"])(new Date(value));
  }
  return value;
};
var _default = toDate;
exports["default"] = _default;