"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = _interopRequireDefault(require("react"));
var draggableFactory = function draggableFactory(_ref) {
  var element = _ref.element,
    onDragStart = _ref.onDragStart,
    onDragMove = _ref.onDragMove,
    onDragEnd = _ref.onDragEnd;
  var onMouseDown = function onMouseDown(e) {
    if (!element) return;
    element.dataset.moving = true;
    var clone = element.cloneNode(true);
    clone.classList.add('draggable');
    document.body.appendChild(clone);
    element.classList.add('dragging');
    var x = e.clientX - 40;
    var y = e.clientY - 40;
    clone.style.width = element.offsetWidth + 'px';
    clone.style.height = element.offsetHeight + 'px';
    clone.style.transform = "translate3d(".concat(x, "px,").concat(y, "px,0)");
    document.body.classList.add('dragging');
    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp);
    if (typeof onDragStart == 'function') onDragStart(e, clone);
  };
  var onMouseMove = function onMouseMove(e) {
    if (!element.dataset.moving) return;
    var clones = document.body.querySelectorAll('.draggable');
    if (!clones.length) {
      document.body.classList.remove('dragging');
      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.removeEventListener('mouseup', onMouseUp);
      return;
    }
    for (var i = 0; i < clones.length; i++) {
      var clone = clones.item(i);
      var x = e.clientX - 40;
      var y = e.clientY - 40;
      clone.style.transform = "translate3d(".concat(x, "px,").concat(y, "px,0)");
    }
    if (typeof onDragMove == 'function') onDragMove(e, clones);
  };
  var onMouseUp = function onMouseUp(e) {
    if (element) {
      element.dataset.moving = false;
      element.classList.remove('dragging');
    }
    var clones = document.body.querySelectorAll('.draggable');
    var droppable = document.body.querySelector('.droppable.hover');
    for (var i = 0; i < clones.length; i++) {
      var clone = clones.item(i);
      document.body.removeChild(clone);
    }
    document.body.classList.remove('dragging');
    document.body.removeEventListener('mousemove', onMouseMove);
    document.body.removeEventListener('mouseup', onMouseUp);
    if (typeof onDragEnd == 'function') onDragEnd(e, clones, droppable);
  };
  return {
    onMouseDown: onMouseDown,
    onMouseMove: onMouseMove,
    onMouseUp: onMouseUp
  };
};
var _default = draggableFactory;
exports["default"] = _default;