import Axios from "axios";

/**
 *  @param {Blob | string} value
 *  @param {string} filename
 */
async function downloadFile(value, filename) {
    const isUrlParam = typeof value === "string";

    const blob = isUrlParam
        ? (await Axios.get(value, { responseType: "blob" })).data
        : value;
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;

    downloadLink.click();

    URL.revokeObjectURL(url);
}

export default downloadFile;
export { downloadFile };
