const bimesters = [
    [0, 1],
    [2, 3],
    [4, 5],
    [6, 7],
    [8, 9],
    [10, 11],
];

const trimesters = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [9, 10, 11],
];

const semesters = [
    [0, 1, 2, 3, 4, 5],
    [6, 7, 8, 9, 10, 11],
];

export { bimesters, trimesters, semesters };