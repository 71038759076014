import React, { createContext, useContext, useEffect, useState } from "react";

import { history } from "@store";
import { useStore } from "@hooks";
import { serviceTask } from "@services/task";
import { getDiffDaysWeek } from "@functions";
import { services } from "@store";;

const InboxContextV2 = createContext({
    handleChangeFilterStep: (step) => { },
    filterStep: "",
    filterTaskStep: [],
    setFilterTask: (e) => { },
    filterTask: "",
    filterDescriptionTask: [],
    searchAndFilterTasks: (credential) => { },
    resultFilterTaskDate: [],
    clearFilterDateTask: () => { },
    setSelectedGroup: (e) => { },
    setSelectedUser: (e) => { },
    filterUserSelected: [],
    setOrderTasks: (e) => { }
});

function InboxProvider({ children, match }) {
    const { tasks, auth } = useStore();
    const [filterStep, setFilterStep] = useState("pending");
    const [filterTask, setFilterTask] = useState("");
    const [resultFilterTaskDate, setResultFilterTaskDate] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [orderTasks, setOrderTasks] = useState('created')

    const filterUserSelected = tasks.filter((tasks) => {
        if (selectedGroup !== "" || selectedUser !== "") {

            if (selectedUser && tasks.agent === selectedUser) return true
            if (selectedGroup && tasks.action.group.uid === selectedGroup) return true
        } else {
            if (tasks.agent === auth.user.uid) return true
        }
    })
    const filterTaskStep = resultFilterTaskDate.length > 0 ?
        resultFilterTaskDate.filter((task) => task?.status === filterStep) :
        filterUserSelected.filter((task) => task?.status === filterStep);

    const filterDescriptionTask = filterTaskStep.filter((task) => {
        const filterTaskUpperCase = filterTask.toUpperCase();
        if (
            (task?.action?.instructions === "" ||
                task?.action?.formTitle === "") &&
            filterTaskUpperCase === ""
        ) {
            return true;
        }

        if (task?.workflowObject?.description) {
            return task?.workflowObject?.description
                .toUpperCase()
                .includes(filterTaskUpperCase);
        }
        // } else if (task?.action?.formTitle) {
        //     return task?.action?.formTitle
        //         .toUpperCase()
        //         .includes(filterTaskUpperCase);
        // } else {

        return false;
    });

    const handleOrder = (a, b) => {
        if (orderTasks === 'expiration') {

            const dateA = a.expirationDateString ?
                new Date(a.expirationDateString).getTime() : Infinity;
            const dateB = b.expirationDateString ?
                new Date(b.expirationDateString).getTime() : Infinity;

            return dateA - dateB;
        }
    }

    filterDescriptionTask.sort(handleOrder)

    const handleChangeFilterStep = (step) => {
        history.push(`/inbox`);
        setFilterStep(step);
    };

    const clearFilterDateTask = () => {
        setResultFilterTaskDate([])
    }

    const searchAndFilterTasks = async ({ taskTypes, search, date, close }) => {
        const today = new Date();
        today.setHours(0, 0, 0);

        let taskDateMiliseconds;

        const types = [];

        if (taskTypes.form) types.push("formInput");
        if (taskTypes.approval) types.push("approval");
        if (taskTypes.document) types.push("documentInput");

        // @ts-ignore
        switch (date?.value) {
            case "today":
                taskDateMiliseconds = today;
                break;

            case "week":
                const diffDaysWeek = getDiffDaysWeek(today);
                taskDateMiliseconds = today.setDate(
                    today.getDate() - diffDaysWeek
                );
                break;

            case "month":
                taskDateMiliseconds = today.getDate() - 1;
                break;
            case "six_month":
                taskDateMiliseconds = today.setMonth(today.getMonth() - 6);
                break;
            case "year":
                taskDateMiliseconds = today.setFullYear(
                    today.getFullYear() - 1
                );
                break;
            default:
                taskDateMiliseconds = today;
        }

        const taskDate = new Date(taskDateMiliseconds);

        const tasks = await serviceTask.search({
            createdAt: taskDate,
            types: types,
            search: search,
            userUid: auth.user.uid,
        });

        if (typeof tasks === "string") {
            console.log(tasks)
            if (tasks === "not") {
                services.message.show({
                    title: "0 resultados",
                    description: "Nenhuma solicitação corresponde ao filtro"
                })
            }
            return
        };

        services.tasks.searchTasks({
            tasks,
        });
        close()
        setResultFilterTaskDate(tasks);
    };

    return (
        <InboxContextV2.Provider
            value={{
                handleChangeFilterStep,
                filterStep,
                filterTaskStep,
                setFilterTask,
                filterTask,
                filterDescriptionTask,
                searchAndFilterTasks,
                resultFilterTaskDate,
                clearFilterDateTask,
                setSelectedGroup,
                setSelectedUser,
                filterUserSelected,
                setOrderTasks
            }}
        >
            {children}
        </InboxContextV2.Provider>
    );
}

const useInbox = () => {
    const context = useContext(InboxContextV2);
    if (!context) {
        throw new Error("useInbox must be used within a InboxProvider");
    }

    return context;
};

export { InboxContextV2, InboxProvider, useInbox };
