"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
/** @param {any} value */
function onlyNumbers(value) {
  var result = String(value || '').replace(/[^\d,\.]/g, '').replace(/\./g, '').replace(/,/g, '.');
  return result;
}
var _default = onlyNumbers;
exports["default"] = _default;