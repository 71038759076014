import { Button } from '@mui/material'
import styles from './styles.module.scss'
import { Modal } from 'components'
import React, { useState } from 'react'
import Icon from '@custom/Icon'
import { useSelectedCompanyArea } from './useSelectedCompanyArea'
import { useStore } from '@hooks'
import i18n from '@i18n'
import { services } from '@store'

export function SelectCompanyArea({ getListFlows }) {

  const { companyArea, companyAreaSelected, typeCompanyAreaSelected, theme: { theme } } = useStore()

  const {
    isActiveModalCompanyArea,
    handleCloseModalCompanyArea,
    handleGetLFows
  } = useSelectedCompanyArea({ getListFlows })

  function handleChangeBusinessArea(companyAreaUid) {
    services.companyArea.changeCompanyAreaSelected(companyAreaUid)
  }
  if(companyAreaSelected !== "" && typeCompanyAreaSelected !== "") return <></>

  return (
    <Modal
      open={isActiveModalCompanyArea}
      close={handleCloseModalCompanyArea}
      notCloseClick
      title={i18n("Escolha a área de negócios")}
    >
      <div className={`${styles.companyArea} ${styles[theme]}`}>

        <div className={styles.companyAreaList}>
          {companyArea.map((companyOption) => (
            <button
              key={companyOption.uid}
              className={`${styles.buttonSelect} ${companyAreaSelected === companyOption.uid && styles.buttonSelected}`}
              onClick={() => handleChangeBusinessArea(companyOption.uid)}
            >
              <div className={styles.buttonBorder}>
                <span className={styles.icon}><Icon icon='faFolder' /></span>
                <strong>
                  {companyOption.name}
                </strong>
              </div>
            </button>
          ))}
        </div>

        <div className={styles.buttons}>
          <Button
            size='small'
            variant='contained'
            color='success'
            onClick={handleGetLFows}
          >
            {i18n("Salvar e continuar")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}