import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import LogoDark from "@assets/images/logo-dark.png";
import styles from "./styles.module.scss";

/**
 * @typedef PageSpinnerProps
 * @type {{
 *     loading: boolean;
 * }}
 */

/** @param {PageSpinnerProps} props */
const PageSpinner = ({ loading }) => (
    <div
        className={styles.PageSpinner}
        style={{ height: loading ? "100vh" : 0 }}
    >
        <img
            className={styles.Logo}
            src={LogoDark}
            alt="Workflux Logo"
            style={{ height: loading ? "auto" : 0 }}
        />
        <PulseLoader color="#00679d" loading={loading} size={30} />
    </div>
);

export default PageSpinner;
