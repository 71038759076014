import { createTask } from "@models";
import { taskCollection } from "@firebase-config/collections";

/**
 * @typedef Task
 * @type {import("@models/task").Task}
 */

/**
 * Function to listen for changes in all tasks
 * @param {{
 *      list: Task[]
 *      callback: (tasks: Task[]) => void;
 * }} params
 */
export function watchTasks({ list, callback }) {
    const tasks = [];

    for (const item of list) {
        taskCollection.doc(item.uid).onSnapshot((snapshot) => {
            const task = createTask(snapshot.data());
            tasks.push(task);
            callback([task]);
        });
    }

    callback(tasks);
}
