const SHOW_MESSAGE = "MESSAGE_SHOW";
const HIDE_MESSAGE = "MESSAGE_HIDE";

/** @type {MessageProps} */
const INIT_STATE = {
    visible: false,
    title: "",
    description: "",
    buttons: [],
    hide: null,
    variant: undefined,
};

/**
 * @typedef MessageProps
 * @type {import("@custom/Message").MessageProps}
 */

const service = (dispatch) => ({
    /** @param {Omit<MessageProps, "visible">} params */
    show({
        title = INIT_STATE.title,
        description = INIT_STATE.description,
        buttons = INIT_STATE.buttons,
        hide = INIT_STATE.hide,
        variant = INIT_STATE.variant,
    }) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title,
                description,
                buttons,
                hide,
                variant,
            },
        });
    },
    /** @param {Omit<MessageProps, "visible">} params */
    success(params) {
        this.show({ ...params, variant: "success" });
    },
    /** @param {Omit<MessageProps, "visible">} params */
    error(params) {
        this.show({ ...params, variant: "error" });
    },
    hide() {
        dispatch({
            type: HIDE_MESSAGE,
        });
    },
});

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                visible: true,
                title: action.payload.title,
                description: action.payload.description,
                buttons: action.payload.buttons,
                hide: action.payload.hide,
                variant: action.payload.variant,
            };

        case HIDE_MESSAGE:
            return {
                ...state,
                ...INIT_STATE,
                visible: false,
            };

        default:
            return { ...state };
    }
};

export default { reducer, service };
