"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signatureMicroservice = exports.receita = exports.googleAuthy = exports.dokdrive = exports.cep = exports.appcenter = exports.SYSTEM_TOKEN = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _functions = require("../@functions");
var local = (0, _functions.isLocal)();
var defaultTimeout = 0;
var SYSTEM_TOKEN = '@A251FBCACAF8@F6D1.37434A6A54A5@98C1DF74ES2]24C@05143EE1F6F43DF828D4DEB0E@C19B47SA977@GB4CE47390D76F337@2A;/3D85D5K60A198B@6B8D70E3F6D@E1AB10C12140@K97C4AA02';
exports.SYSTEM_TOKEN = SYSTEM_TOKEN;
var appcenterProdRoute = "https://us-central1-taugor-appcenter.cloudfunctions.net/customer_api/";
var appcenter = _axios["default"].create({
  baseURL: process.env.API_PROD ? appcenterProdRoute : local ? 'http://localhost:5001/taugor-appcenter/us-central1/customer_api/' : appcenterProdRoute,
  timeout: defaultTimeout
});
exports.appcenter = appcenter;
var cep = _axios["default"].create({
  baseURL: 'https://api.postmon.com.br/v1/cep/',
  timeout: defaultTimeout
});
exports.cep = cep;
var receita = {
  baseURL: 'https://www.receitaws.com.br/v1',
  timeout: defaultTimeout
};
exports.receita = receita;
var dokdrive = _axios["default"].create({
  baseURL: 'http://localhost:8088/dokdrive',
  timeout: defaultTimeout
});
exports.dokdrive = dokdrive;
var googleAuthy = _axios["default"].create({
  baseURL: 'https://api.authy.com',
  timeout: defaultTimeout
});
exports.googleAuthy = googleAuthy;
var signatureMicroservice = _axios["default"].create({
  baseURL: local ? 'http://localhost:5010/signature-microservice/us-central1/signatureMicroservice' : 'https://us-central1-signature-microservice.cloudfunctions.net/signatureMicroservice',
  timeout: defaultTimeout
});
exports.signatureMicroservice = signatureMicroservice;