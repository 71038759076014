import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/**
 * @param {{
 *     className?: string;
 *     icon: string;
 * }} props
 */
const Icon = (props) => (
    <FontAwesomeIcon {...props} icon={fas[props.icon] || fab[props.icon]} />
);

export default Icon;
