import { appcenter } from "./endpoints";

/**
 * @typedef User
 * @type {import('@hooks/useStore').User}
 */

const appcenterApi = {
    users: {
        /**
         * @param {string} companyUid
         * @param {User} updatedUser
         */
        update: (companyUid, updatedUser) => {
            return appcenter.post(`/company/${companyUid}/users/save`, {
                user: updatedUser,
            });
        },

        /**
         * @param {string} companyUid
         * @param {User[]} users
         */
        disable: (companyUid, users) => {
            return appcenter.post(`/company/${companyUid}/users/disable`, {
                users,
            });
        },
    },
    sms: {
        /**
         * @typedef {import("axios").AxiosResponse<boolean>} SMSResponse
         */
        /**
         * @param {string} to
         * @param {string} msg
         * @returns {Promise<SMSResponse>}
         */
        send: (to, msg) => {
            return appcenter.post("/sms/send/", { to, msg });
        },
    },
};

export default appcenterApi;
export { appcenterApi };
