"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _useUnmount = _interopRequireDefault(require("./useUnmount"));
/**
 * @param {() => void} callback
 * @param {number} waitTime
 * @param {any[]} triggers
 * @returns {void}
 */
function useTimeout() {
  var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
    console.log("callback");
  };
  var waitTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var triggers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var callbackRef = (0, _react.useRef)(callback);
  callbackRef.current = callback;

  /** @type {[NodeJS.Timer | undefined, (timer: NodeJS.Timer) => void]} */
  var _useState = (0, _react.useState)(),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    intId = _useState2[0],
    setIntId = _useState2[1];
  (0, _react.useEffect)(function () {
    if (waitTime === null) return;
    var id = setTimeout(function () {
      callbackRef.current();
    }, waitTime);
    setIntId(id);
  }, [waitTime].concat((0, _toConsumableArray2["default"])(triggers)));
  (0, _react.useEffect)(function () {
    if (waitTime === null) {
      clearInterval(intId);
    }
  }, [waitTime]);
  (0, _useUnmount["default"])(function () {
    clearInterval(intId);
  });
}
var _default = useTimeout;
exports["default"] = _default;