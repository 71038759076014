import React, { useMemo, useState } from "react";

import i18n from "@i18n";
import { workfluxApi } from "@apis";
import { useMyFiles } from "../../contexts/MyFilesContext";
import { extensionToIcon, downloadFile } from "@functions";
import { history, services } from "@store";

import Icon from "@custom/Icon";

import styles from "./styles.module.scss";
import { useStore } from "@hooks";
import { Menu } from "components";
import { RenameFile } from "./modules";

/**
 * @typedef Document
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * @param {{
 *     document: Document;
 *     selectable?: boolean;
 * }} props
 */
function FileCard({ document, selectable = true }) {
    const { openDocument } = useMyFiles();
    const { theme: { theme } } = useStore()

    const [openRename, setOpenRename] = useState(false)

    const isSelected = selectable && openDocument?.uid === document.uid;
    const filename = document.action.instructions;
    const description = document.action.documentInputDescription;

    const extension = useMemo(() => {
        const [fileUrl] = document.location.split("?");
        const parts = fileUrl.split(".");
        return parts[parts.length - 1];
    }, [document.location]);
    const fileIconUrl = extensionToIcon(extension);

    const handleSelectedDocumentMenu = () => {
        if (!selectable) return;
        history.push(`/my-files/${isSelected ? "" : document.uid}`);
    };

    const handleDownloadFile = async () => {
        services.spinner.show();

        try {
            const { url } = await workfluxApi.document.open(document.uid);
            await downloadFile(url, `${filename}.${extension}`);
        } catch (err) {
            console.error("err", err);
            services.message.error({
                title: "Erro ao abrir documento",
                description: err.message,
            });
        } finally {
            services.spinner.hide();
        }
    };

    const optionsMenu = [
        {
            id: 0,
            title: i18n("Ver informações"),
            onClick: handleSelectedDocumentMenu,
        },
        {
            id: 1,
            title: i18n("Fazer download"),
            onClick: handleDownloadFile,
        },
        {
            id: 2,
            title: i18n("Renomear"),
            onClick: () => setOpenRename(true),
        },
    ];

    return (
        <>
            <div
                className={`${styles.FileCard} ${styles[theme]}`}
                onClick={handleSelectedDocumentMenu}
                data-selected={isSelected}
                data-selectable={selectable}
            >
                <div className={styles.FileCardHeader}>
                    {fileIconUrl ? (
                        <img
                            className={styles.FileCardIcon}
                            src={fileIconUrl}
                            alt={extension}
                        />
                    ) : (
                        <Icon className={styles.FileCardIcon} icon="faFile" />
                    )}

                    <Menu
                        options={optionsMenu}
                    />
                </div>
                <div className={styles.FileCardInfo}>
                    <p className={styles.FileCardFilename}>{filename}</p>
                    <p className={styles.FileCardFileDescription}>
                        {description || <i>{i18n("Sem descrição")}</i>}
                    </p>
                </div>
            </div>

            <RenameFile
                open={openRename}
                close={() => setOpenRename(false)}
                document={document}
            />
        </>
    );
}

export default FileCard;
