import styles from './styles.module.scss'
import React, { useState } from 'react'
import i18n from '@i18n';
import { workfluxApi } from "@apis";
import { useStore } from "@hooks";
import { Button, Modal, Select } from "components/ui";
import { services } from '@store';

export function DelegateResponsibility({ isVisible, close, taskUid }) {

  const [groupSelectedUid, setGroupSelectedUid] = useState("")
  const [memberSelected, setMemberSelected] = useState("")
  const [error, setError] = useState(false)
  const {
    groups,
    users,
    auth
  } = useStore();

  const handleDelegateResponsibility = async () => {
    const error = memberSelected === "" || groupSelectedUid === ""

    if (error) {
      setError(true)

      setTimeout(() => {
        setError(false)
      }, 2000)

      return
    }
    services.spinner.show()
    const member = users.find((user) => user.uid === memberSelected)

    workfluxApi.tasks.updateTaskOwner(taskUid, {
      uid: member.uid,
      agentName: `${member.firstName} ${member.lastName}`,
      agentEmail: member.email,
    }).then(() => {

      console.log("SUCESSO")
    }).catch((err) => {
      console.log(err)
    })

    setMemberSelected('')
    setGroupSelectedUid('')
    services.spinner.hide()
    close()
  }

  const groupSelected = groups.find((group) => group.uid === groupSelectedUid)
  const members = users.filter((user) => (
    groupSelected ? groupSelected.members.includes(user.uid) && user.uid !== auth?.user?.uid : false))


  const Footer = () => {
    return <>
      <Button
        color="error"
        onClick={close}
        title="Cancelar"
      />

      <Button
        onClick={handleDelegateResponsibility}
        title="Salvar"
      />
    </>
  }

  return (
    <Modal
      open={isVisible}
      close={close}
      title="Responsabilidade da execução"
      footer={<Footer />}
    >

      <div className={styles.container}>
        <p>
          {i18n("Escolha um usuário para assumir a responsabilidade desta atividade.")}
        </p>

        <Select
          invalidOption
          itens={groups.map(group => ({ title: group.name, value: group.uid }))}
          onChange={setGroupSelectedUid}
          error={error}
          value={groupSelectedUid}
          title="Grupos"
        />
        <Select
          invalidOption
          itens={members.map(user => ({
            title: `${user.firstName} ${user.lastName}`, value: user.uid
          }))}
          onChange={setMemberSelected}
          error={error}
          value={memberSelected}
          title="Users"
        />
      </div>
    </Modal>
  )
}