import { createWorkflow } from "@models";
import { workflowCollection } from "@firebase-config/collections";

/**
 * @typedef Workflow
 * @type {import("@models/workflow").Workflow}
 */

/**
 * Function to listen for changes in all workflows of company
 * @param {{
 *      list: Workflow[];
 *      callback: (workflows: Workflow[]) => void;
 * }} params
 */
export function watchWorkflows({ list, callback }) {
    const workflows = [];

    for (const item of list) {
        workflowCollection.doc(item.uid).onSnapshot((snapshot) => {
            const workflow = createWorkflow(snapshot.data());
            workflows.push(workflow);
            callback([workflow]);
        });
    }

    callback(workflows);
}
