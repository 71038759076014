
export const renderName = ({ uid, users }) => {
  const responsible = users.find((user) => user.uid === uid);
  return `${responsible?.firstName} ${responsible?.lastName}`;
};

export const handleResponsible = ({ executionFlow, users }) => {

  if (executionFlow?.agentObject?.groups) {
    return executionFlow?.workflowObject?.group?.name
  }

  return renderName({ uid: executionFlow?.agent, users })
}