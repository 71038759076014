import { useStore } from '@hooks'
import styles from './circleType.module.scss'
import React from "react"

export function CirclesTypeTask({ type }) {

  const { theme: { theme } } = useStore()

  return (
    <div className={`${styles.areaCircle} ${styles[theme]} ${styles[type]}`}>
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
    </div>
  )
}