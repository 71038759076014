import { createNews } from '@models';
import { newsCollection } from '@firebase-config/collections';

/**
 * @typedef News
 * @type {import("@models/news").News}
 */

/**
 * Function to list all news of company
 * @param {{
 *      companyUid: string
 * }} params
 * @returns {Promise<string | News[]>}
 */
export async function listNews({ companyUid }) {
    if (!companyUid || typeof companyUid !== 'string') {
        return 'invalid-field-companyUid';
    }

    try {
        const { docs } = await newsCollection
            .where('company', '==', companyUid)
            .where('delete', '==', false)
            .get();
        const allListNews = docs.map((doc) => doc.data());

        return allListNews.map(createNews);
    } catch (error) {
        console.log('ERROR in listNews: >=>', error);
        return 'error-list-news';
    }
}
