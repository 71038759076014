import { toDate } from "@functions";

const newsModel = {
    uid: "",
    company: "",
    title: "",
    summary: "",
    text: "",
    bannerUrl: "",
    deleted: false,
    createdAt: new Date(),
    createdBy: ""
};

/**
 * @typedef News
 * @type {typeof newsModel}
 */

function createNews(data) {
    if (!data) data = {};

    return {
        ...newsModel,
        ...data,
        deleted: !!data.delete,
        createdAt: toDate(data.createdAt)
    };
}

export { newsModel, createNews };