import Icon from "@custom/Icon";
import styles from "./sidebarWeb.module.scss";
import React, { useEffect, useState } from "react";
import i18n from "@i18n";
import { history } from "@store";

import LogoLight from "@assets/images/logo-light.png";
import { useWhitelabel } from "zerocode/dist/@hooks";
import { useStore } from "@hooks";

export function SidebarWeb({ modules, activeModule }) {
    const [logo, setLogo] = useState(LogoLight);
    const { loading, whitelabel } = useWhitelabel();
    const { theme: {theme} } = useStore()

    useEffect(() => {
        if (!loading && whitelabel.logo) {
            setLogo(whitelabel.logo);
        }
        return () => {}
    }, [whitelabel, loading]);

    return (
        <aside className={`${styles.sidebar} ${styles[theme]}`}>
            <div className={styles.LogoFigure}>
                <img
                    className={styles.LogoImg}
                    src={logo}
                    alt="Logo Workflux"
                />
            </div>
            <nav className={styles.ModuleList}>
                {modules?.map((module) => (
                    <li
                        key={module.key}
                        className={styles.ModuleItem}
                        onClick={() => history.push(module.path)}
                        data-active={activeModule?.key === module.key}
                    >
                        <div className={styles.ModuleItemIcon}>
                            <Icon icon={module.icon} />
                        </div>
                        <strong className={styles.ModuleItemLabel}>
                            {i18n(module.label)}
                        </strong>
                    </li>
                ))}
            </nav>
        </aside>
    );
}
