import logoYopen from '../@assets/images/yopen/yopenLogo.svg'
import logoAppcenter from '../@assets/images/appcenter/appcenterLogo.svg'
import logoDokPlus from '../@assets/images/dokplus/dokiaLogo.png'
import logoWorkflux from '../@assets/images/workflux/workfluxLogo.png'
import logoWorkfluxPortal from '../@assets/images/workflux/workfluxPortalLogo.svg'

const logosImages = {
	collectame: {
		logo: logoYopen,
		logoAlt: 'Logo Yopen',
		coverAlt: 'Yopen cover'
	},
	'appcenter-customer': {
		logo: logoAppcenter,
		logoAlt: 'Logo Appcenter',
		coverAlt: 'Appcenter cover'
	},
	dokia: {
		logo: logoDokPlus,
		logoAlt: 'Logo Dok+',
		coverAlt: 'Dok+ cover'
	},
	workflux: {
		logo: logoWorkflux,
		logoAlt: 'Logo Workflux',
		coverAlt: 'Workflux cover'
	},
	'workflux-portal': {
		logo: logoWorkfluxPortal,
		logoAlt: 'Logo Workflux Portal',
		coverAlt: 'Workflux Portal cover'
	}
}

export default logosImages
