import styles from "./tasksList.module.scss";
import i18n from "@i18n";
import { InputSearch } from "components/inputs";
import React, { useState } from "react";
import { CardTask } from "../cardTask";
import { useInbox } from "@screens/Inbox/contexts/inboxContext";
import { useStore } from "@hooks";
import { services } from "@store";
import Icon from "@custom/Icon";
import { FilterTaskDate } from "../filterTaskDate";
import { CirclesTypeTask } from "components/circlesTypeTask";
import { Button, Menu } from "components";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Button as ButtonMui } from "@mui/material"

export function TasksList() {
    const { auth, theme: { theme } } = useStore();
    const {
        filterTaskStep,
        filterTask,
        filterDescriptionTask,
        resultFilterTaskDate,
        clearFilterDateTask,
        setFilterTask,
        setOrderTasks
    } = useInbox();

    const [isFilterTaskDate, setIsFilterTaskDate] = useState(false);
    const [loadingMoreTasks, setLoadingMoreTasks] = useState(false);

    const optionsOrder = [
        {
            id: 0,
            title: "data de criação",
            onClick: () => setOrderTasks('created')
        },
        {
            id: 1,
            order: "expiration",
            title: "data de expiração",
            onClick: () => setOrderTasks('expiration')
        },
    ]

    const handleMoreTasks = async () => {
        try {
            setLoadingMoreTasks(true);
            const lastTask =
                filterDescriptionTask[filterDescriptionTask.length - 1];

            await services.tasks.listMore({
                user: auth.user.uid,
                lastDoc: lastTask.uid,
                company: auth.company.uid,
            });
        } catch (error) {
            console.log("ERROR handleMoreTasks: >=>", error);
        } finally {
            setLoadingMoreTasks(false);
        }
    };

    return (
        <>
            <div className={`${styles.tasksList} ${styles[theme]}`}>
                <header className={styles.header}>
                    <div></div>
                    <h4>{i18n("Caixa de entrada")}</h4>
                    <Menu
                        options={optionsOrder}
                        icon={<ImportExportIcon />}
                        title={"Ordenar por:"}
                    />
                </header>

                <div className={styles.gabaritTypes}>
                    <div className={styles.type}>
                        <CirclesTypeTask type="approval" />
                        <p>{i18n("Aprovação")}</p>
                    </div>
                    <div className={styles.type}>
                        <CirclesTypeTask type="formInput" />
                        <p>{i18n("Preenchimento de Formulário")}</p>
                    </div>
                    <div className={styles.type}>
                        <CirclesTypeTask type="documentInput" />
                        <p>{i18n("Envio de documento")}</p>
                    </div>
                </div>

                <div className={styles.headerFilter}>
                    {filterTaskStep.length > 0 && (
                        <InputSearch
                            setValue={setFilterTask}
                            value={filterTask}
                            placeholder={i18n("Busca rápida por de solicitação")}
                        />
                    )}
                    <ButtonMui
                        startIcon={<Icon icon="faBars" />}
                        onClick={() => setIsFilterTaskDate(true)}
                        variant="contained"
                    >
                        Filtro
                    </ButtonMui>
                </div>



                {filterDescriptionTask.length === 0 && (
                    <div className={styles.messageNotTasks}>
                        <p>{i18n("Nenhuma solicitação corresponde ao filtro")}</p>
                    </div>
                )}

                {resultFilterTaskDate.length > 0 &&
                    (<div className={styles.headerFilterResults}>
                        <h3>Resultado da Pesquisa</h3>
                        <Button
                            onClick={clearFilterDateTask}
                            iconLeft={<Icon icon="faRotateRight" />}
                            title="voltar para solicitações atuais"
                        />
                    </div>)
                }

                <div className={styles.listCardsTasks}>
                    {filterDescriptionTask?.map((task) => (
                        <CardTask task={task} key={task.uid} />
                    ))}
                </div>

                {filterTaskStep.length > 0 && (
                    <Button
                        loading={loadingMoreTasks}
                        onClick={handleMoreTasks}
                        title="Buscar mais"
                    />
                )}
            </div>
            {
                isFilterTaskDate && (
                    <FilterTaskDate
                        close={() => setIsFilterTaskDate(false)}
                        open={isFilterTaskDate}
                    />
                )
            }
        </>
    );
}
