import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { Button } from "@mui/material";

export function ButtonLoading({ loading, children, ...rest }) {
    return (
        <Button
            fullWidth
            variant="contained"
            size="small"
            disabled={loading}
            {...rest}
        >
            {loading ? <CircularProgress size={20} /> : children}
        </Button>
    );
}
