import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    LocalizationProvider,
    DateCalendar,
    PickersDay,
    DayCalendarSkeleton,
} from "@mui/x-date-pickers";
import i18n, { format } from "@i18n";
import { serviceCalendar } from "@services/calendar";
import { Badge } from "@material-ui/core";
import { useAsyncEffect, useStore } from "@hooks";

import Icon from "@custom/Icon";
import Topbar from "@custom/Topbar";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import { toDate } from "zerocode";
import { history, services } from "@store";

import BadgeExistEventAndExpirationDate from "@assets/images/badgeCalendar.png";
import { Button } from "@mui/material";
import { DetailTask } from "components/detailTask";

/**
 * @typedef Event
 * @type {import("@models/calendar").Event}
 */

export default function Calendar() {
    /**
     * @type {[ Event[], React.Dispatch<React.SetStateAction<Event[]>>]}
     */
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const initialValue = dayjs(new Date());

    const { auth, tasks, theme: { theme } } = useStore();

    useAsyncEffect(async () => {
        if (
            !auth.company?.uid ||
            !auth.user?.uid ||
            !auth.isAuthenticated ||
            !!tasks.length
        )
            return;

        try {
            setLoading(true);
            await services.tasks.list({
                company: auth.company.uid,
                user: auth.user.uid,
            });
            const resultEvents = await serviceCalendar.listEvents({
                companyUid: auth.company.uid,
            });

            if (typeof resultEvents !== "string") {
                setEvents(resultEvents);
            } else {
                return;
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, []);

    const formatMinorDate = (num) => {
        return num < 10 ? `0${num}` : num;
    };
    const dateNumber = (newDate) => {
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const day = newDate.getDate();

        return Number(
            `${year}${formatMinorDate(month)}${formatMinorDate(day)}`
        );
    };

    const removeHtmlTags = (input) => {
        const regex = /<[^>]*>/g;
        return input.replace(regex, "");
    };

    const handleRenderEvent = ({ initialDate, endDate }) => {
        const dateSelectedInMonth = dateNumber(selectedDate);

        if (
            dateSelectedInMonth >= dateNumber(initialDate) &&
            dateSelectedInMonth <= dateNumber(endDate)
        ) {
            return true;
        }
        return false;
    };

    const markDate = ({ day, year, month, type }) => {
        const currentDate = Number(
            `${year}${formatMinorDate(month)}${formatMinorDate(day)}`
        );

        if (type === "event") {
            const exist = events.some((event) => {
                const initialDate = dateNumber(event.initialDate);
                const endDate = dateNumber(event.endDate);

                return currentDate >= initialDate && currentDate <= endDate;
            });
            return exist;
        }

        if (type === "tasks") {
            const existExpirationTask = tasks.some((task) => {
                const [yearTask, monthTask, dayTask] =
                    task?.expirationDateString.split("-");

                return (
                    year == yearTask && month == monthTask - 1 && day == dayTask && task.status !== "completed"
                );
            });
            return existExpirationTask;
        }
    };

    const handleChangeDay = (day) => {
        setSelectedDate(new Date(day.$d));
    };

    const ServerDay = (props) => {
        const { day, outsideCurrentMonth, ...other } = props;
        const isSelected = markDate({
            month: day.$M,
            year: day.$y,
            day: day.$D,
            type: "event",
        });

        const isExpirationTask = markDate({
            month: day.$M,
            year: day.$y,
            day: day.$D,
            type: "tasks",
        });

        const renderBadge = () => {
            if (isExpirationTask && isSelected)
                return (
                    <img
                        src={BadgeExistEventAndExpirationDate}
                        width={14}
                        height={14}
                    />
                );
            if (isSelected) return "🔵";
            if (isExpirationTask) return "🔴";
        };

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={renderBadge()}
            >
                <PickersDay
                    {...other}
                    outsideCurrentMonth={outsideCurrentMonth}
                    style={{ color: theme === "dark" ? "#f1f1f198" : "" }}
                    day={day}
                />
            </Badge>
        );
    };

    const dateEvents = events.filter(
        (event) =>
            dateNumber(selectedDate) >= dateNumber(event.initialDate) &&
            dateNumber(selectedDate) <= dateNumber(event.endDate)
    );

    const template = [
        {
            id: 0,
            title: i18n("Vencimento de tarefa"),
            type: "expirationTask",
        },
        {
            id: 1,
            title: i18n("Eventos"),
            type: "events",
        },
        {
            id: 2,
            title: i18n("Vencimento de tarefa / Eventos"),
            type: "eventsAndExpirationTask",
        },
    ];

    const taskInExpirationSelectedDate = tasks.filter((task) => {
        if (task.expirationDateString) {
            const year = selectedDate.getFullYear();
            const month = selectedDate.getMonth();
            const day = selectedDate.getDate();
            const [yearTask, monthTask, dayTask] =
                task?.expirationDateString.split("-");

            return (
                year === Number(yearTask) &&
                month === Number(monthTask) - 1 &&
                day === Number(dayTask) && task.status !== "completed"
            );
        }
        return false;
    });

    const renderNamePashe = ({ task }) => {
        const currentPhase = task.currentPhase
        if (!currentPhase || currentPhase === "") {
            return task.workflowObject.phases[0].name
        }

        const phase = task.phases.find(item => item.uid === task.phase)

        if (phase) {
            return phase.name
        }

        return "nome etapa"
    }

    return (
        <>
            <DetailTask />
            <div className={`${styles.Calendar} ${styles[theme]}`}>
                <Topbar />
                <div className={styles.CalendarMain}>
                    <div className={styles.CalendarContent}>
                        <h2 className={styles.CalendarTitle}>
                            {i18n("Calendário organizacional")}
                        </h2>
                    </div>
                    <section className={styles.containerCalendar}>
                        <section className={styles.CalendarSection}>
                            <LocalizationProvider

                                dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    defaultValue={initialValue}
                                    value={dayjs(selectedDate)}
                                    showDaysOutsideCurrentMonth
                                    loading={loading}
                                    renderLoading={() => <DayCalendarSkeleton />}
                                    slots={{
                                        day: ServerDay
                                    }}
                                    onChange={handleChangeDay}
                                />
                            </LocalizationProvider>
                        </section>
                        <section className={styles.CalendarSection}>
                            <div className={styles.CalendarSubtitle}>
                                <Icon icon="faBook" />
                                {selectedDate && !isNaN(selectedDate.getTime())
                                    ? selectedDate.toLocaleDateString("pt-BR", {
                                        timeZone: "UTC",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })
                                    : i18n("Data inválida")}
                            </div>
                            <div className={styles.templates}>
                                {template.map((template) => (
                                    <div
                                        key={template.id}
                                        className={styles.template}
                                    >
                                        <div
                                            className={`${styles.badge}  ${styles[template.type]
                                                }`}
                                        />

                                        <p>{template.title}</p>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.CalendarEvents}>
                                {events.map(
                                    (event) =>
                                        handleRenderEvent({
                                            initialDate: event.initialDate,
                                            endDate: event.endDate,
                                        }) && (
                                            <div
                                                className={
                                                    styles.CalendarEventsItem
                                                }
                                                key={event.uid}
                                            >
                                                <div
                                                    className={
                                                        styles.CalendarEventsItemHour
                                                    }
                                                >
                                                    <p>
                                                        Início{" "}
                                                        {format.date(
                                                            toDate(
                                                                event.initialDate
                                                            )
                                                        )}{" "}
                                                        ás{" "}
                                                        {format.time(
                                                            toDate(
                                                                event.initialDate
                                                            )
                                                        )}
                                                    </p>
                                                    <p>
                                                        Fim{" "}
                                                        {format.date(
                                                            toDate(event.endDate)
                                                        )}{" "}
                                                        ás{" "}
                                                        {format.time(
                                                            toDate(event.endDate)
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.CalendarEventsItemDescrition
                                                    }
                                                >
                                                    <p>
                                                        {removeHtmlTags(event.text)}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                )}

                                {dateEvents.length === 0 &&
                                    taskInExpirationSelectedDate.length === 0 && (
                                        <div className={styles.CalendarNotEvents}>
                                            {i18n("Nenhum evento para este dia!")}
                                        </div>
                                    )}

                                {taskInExpirationSelectedDate.map((task) => (
                                    <div key={task.uid} className={styles.cardTask}>
                                        <div>
                                            <p>
                                                <strong>{i18n("Assunto")}:</strong>{" "}
                                                {task?.workflowObject?.description}
                                            </p>
                                            <p>
                                                <strong>{i18n("Etapa")}:</strong>{" "}
                                                {renderNamePashe({ task })}
                                            </p>
                                            {console.log("TASK SELECTED CALENDAR", task)}
                                            <p>
                                                {format.date(
                                                    toDate(task?.createdAt)
                                                )}{" "}
                                                á{" "}
                                                {format.date(
                                                    toDate(
                                                        task?.expirationDateString
                                                    )
                                                )}
                                            </p>
                                        </div>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() =>
                                                history.push(`/calendar/${task.uid}`)
                                            }
                                        >
                                            ver
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </section>
                </div>

            </div>
        </>
    );
}
