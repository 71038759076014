import { signingCollection } from "@firebase-config/collections";
import { createSigning } from "@models";

/**
 * @typedef Signing
 * @type {import("@models/signing").Signing}
 */

/**
 * Function to find signing by uid
 * @param {{
 *      signingUid: string;
 * }} params
 * @returns {Promise<string | Signing>}
 */
export async function findSigning({ signingUid }) {
    if (!signingUid || typeof signingUid !== "string") {
        return "invalid-param-signingUid";
    }

    try {
        const { docs } = await signingCollection
            .where("uid", "==", signingUid)
            .where("deleted", "==", false)
            .get();

        const signings = docs.map((doc) => doc.data());

        if (!signings.length) return "not-found-signing";

        return createSigning(signings[0]);
    } catch (error) {
        console.log("ERROR in findSigning: >=>", error);
        return "error-find-signing";
    }
}
