import Home from "@screens/Home";
import Inbox from "@screens/Inbox";
import Calendar from "@screens/Calendar";
import MyFiles from "@screens/MyFiles";
import Authentication from "@screens/Authentication";
import Unauthorized from "@screens/Unauthorized";
import Profile from "@screens/Profile";
import MyFlows from "@screens/myFlows";
import Weather from "@screens/Weather";
import LinkedIn from "@screens/LinkedIn";

const miscRoutes = [
    {
        key: "profile",
        Component: Profile,
        path: "/profile",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "linkedin",
        Component: LinkedIn,
        path: "/linkedin",
        unprotected: true,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },

    {
        key: "weather",
        Component: Weather,
        path: "/weather",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "calendar",
        Component: Calendar,
        path: "/calendar/:taskUid?",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "inbox",
        Component: Inbox,
        path: "/inbox/:taskUid?",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "myFlows",
        Component: MyFlows,
        path: "/my-flows/:id?",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "myFiles",
        Component: MyFiles,
        path: "/my-files/:documentUid?",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "unauthorized",
        Component: Unauthorized,
        path: "/unauthorized",
        unprotected: true,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "readNews",
        Component: Home,
        path: "/news/:newsUid",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "home",
        Component: Home,
        path: "/",
        unprotected: false,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
    {
        key: "authentication",
        Component: Authentication,
        path: "/",
        unprotected: true,
        licenses: [],
        testStage: false,
        taugorOnly: false,
        adminOnly: false,
    },
];

export default miscRoutes;
