import { serviceCompanyArea } from "@services/companyArea";

const LIST_COMPANY_AREA = "LIST_COMPANY_AREA";
const COMPANY_AREA_SELECTED_CHANGE = "COMPANY_AREA_SELECTED_CHANGE";
const TYPE_COMPANY_AREA_SELECTED_CHANGE = "TYPE_COMPANY_AREA_SELECTED_CHANGE";

const service = (dispatch) => ({

  changeCompanyAreaSelected: (companyArea) => {
    dispatch({
      type: COMPANY_AREA_SELECTED_CHANGE,
      payload: { companyAreaSelected: companyArea },
    })
  },
  changeTypeCompanyAreaSelected: (typeCompanyArea) => {
    dispatch({
      type: TYPE_COMPANY_AREA_SELECTED_CHANGE,
      payload: { typeCompanyAreaSelected: typeCompanyArea },
    })
  },
  list: async ({ company, workflows }) => {
    const result = await serviceCompanyArea.list({
      companyUid: company,
      workflows
    });
    if (typeof result === "string") return;

    const list = result.sort((a, b) => {
      const aTime = a.createdAt.getTime();
      const bTime = b.createdAt.getTime();
      return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
    });

    dispatch({
      type: LIST_COMPANY_AREA,
      payload: { company, list },
    });
  },
});

const INIT_STATE = {
  company: "",
  companyAreaSelected: "",
  typeCompanyAreaSelected: "",
  list: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_COMPANY_AREA: {
      return {
        ...state,
        company: action.payload.company,
        list: action.payload.list,
      };
    }
    case COMPANY_AREA_SELECTED_CHANGE:
      return { ...state, companyAreaSelected: action.payload.companyAreaSelected }

    case TYPE_COMPANY_AREA_SELECTED_CHANGE:
      return { ...state, typeCompanyAreaSelected: action.payload.typeCompanyAreaSelected }
    default:
      return { ...state };
  }
};

export default { reducer, service };
