const SET_PAGE_SPINNER_STATUS = "LOADING_SET_PAGE_SPINNER_STATUS";

const INIT_STATE = {
    show: false,
};

const service = (dispatch) => ({
    show: () => {
        dispatch({
            type: SET_PAGE_SPINNER_STATUS,
            payload: { show: true },
        });
    },
    hide: () => {
        dispatch({
            type: SET_PAGE_SPINNER_STATUS,
            payload: { show: false },
        });
    },
});

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PAGE_SPINNER_STATUS:
            return {
                ...state,
                show: action.payload.show,
            };

        default:
            return { ...state };
    }
};

export default { reducer, service };
