import styles from './detailTask.module.scss'
import React, { useState } from 'react'
import { Modal } from "@mui/material";
import i18n from '@i18n';
import { useAsyncEffect } from 'zerocode/dist/@hooks';
import { serviceTask } from '@services/task';
import { FormApproval } from 'components/detailTask/components/formApproval';
import { FormForm } from 'components/detailTask/components/formForm';
import { FormDocument } from 'components/detailTask/components/formDocument';
import { Button } from 'components/ui';

export function DetailTaskCompleted({ open, close, taskUid }) {

  const [task, setTask] = useState(null)

  useAsyncEffect(async () => {

    try {
      const getTask = await serviceTask.find({ taskUid: taskUid })
      setTask(getTask)

    } catch (error) { }
  }, [])

  const typesTask = {
    approval: {
      component: <FormApproval task={task} />,
      title: i18n("Aprovação de fluxos"),
    },
    formInput: {
      component: <FormForm task={task} />,
      title: i18n("Preenchimento de Formulário"),
    },
    documentInput: {
      component: <FormDocument task={task} />,
      title: i18n("Envio de documento"),
    },
  };

  if (!task) return <></>

  return (

    <Modal open={open} onClose={close} className={styles.modal}>
      <div className={styles.detailTask}>
        <div className={styles.header}>
          <h3>{typesTask[task?.action?.type?.type].title}</h3>
          <Button buttonClose onClick={close} />
        </div>
        {typesTask[task?.action?.type?.type]?.component}
      </div>
    </Modal>

  )
}