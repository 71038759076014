import React from "react";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Link(props) {
    const { fieldConfig } = props;
    const { options = [] } = fieldConfig;
    const link = options.join("");

    return (
        <a className={styles.Link} href={link}>
            {fieldConfig.name}
        </a>
    );
}

export default Link;
export { Link };
