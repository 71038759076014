
import React, { useEffect, useState } from 'react'
import { executionFlowCollection } from '@firebase-config/collections'
import { HistoryFlow } from 'components/historyFlow'

export function HistoryTask({ open, close, executionFlowUid }) {

    const [executionFlow, setExecutionFlow] = useState({})

    useEffect(() => {
        async function getExecutionFlow() {

            const query = executionFlowCollection.where("uid", "==", executionFlowUid);

            try {
                const response = await query.get();
                const data = response.docs[0].data()
                setExecutionFlow(data);
            } catch (error) {
            }
        }
        getExecutionFlow();

        return () => {getExecutionFlow();}
    }, [])

    return (
        <HistoryFlow close={close} open={open} executionFlow={executionFlow} />
    )
}