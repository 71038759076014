import { useStore } from "@hooks"
import i18n from "@i18n"
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material"
import React from "react"

/**
 * @param {{checked: boolean, onChange: () => void, label: string}} props
 * 
*/

export function Checkbox({ checked, onChange, label }) {

  const { theme: { theme } } = useStore()

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          sx={{
            '& .MuiSvgIcon-root': {
              color: checked ? '' : (theme === "dark" ? "#F4f4F4" : ""),
            },
          }}
        />
      }
      label={<p style={{ marginBottom: -4 }}>{i18n(label)}</p>}
    />
  )
}