"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _convertToBytes = _interopRequireWildcard(require("./convertToBytes"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/**
 * @param {number} value
 * @param {"bytes" | "kb" | "mb" | "gb" | "tb"} unit
 */
function formatBytes(value) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'bytes';
  var bytes = (0, _convertToBytes["default"])(value, unit);
  var newUnit = 'bytes';
  for (var i = 0; i < _convertToBytes.units.length; i++) {
    var newBytes = bytes / 1024;
    if (newBytes < 1) {
      break;
    }
    bytes = newBytes;
    newUnit = _convertToBytes.units[i + 1] || 'tb';
  }
  if (newUnit === 'bytes') {
    newUnit = 'b';
  }
  return "".concat(bytes.toFixed(2), " ").concat(newUnit.toUpperCase()).replace(/\./g, ',');
}
var _default = formatBytes;
exports["default"] = _default;