import { toDate } from "@functions";

const signingModel = {
    uid: "",
    status: "pending",
    token: "",
    certificateThumbprint: "",
    deleted: false,
    createdAt: new Date(),
    createdBy: ""
};

/**
 * @typedef Signing
 * @type {typeof signingModel}
 */

function createSigning(data) {
    if (!data) data = {};

    return {
        ...signingModel,
        ...data,
        deleted: !!data.deleted,
        createdAt: toDate(data.createdAt)
    };
}

export { signingModel, createSigning };