import React from "react";
import styles from "./styles.module.scss";
import { useStore } from "@hooks";

/**
 * 
 * @param {{
 *  error: boolean, 
 *  value: string
 *  setValue: import("react").Dispatch<import("react").SetStateAction<string>>
 * } & import("react").TextareaHTMLAttributes<HTMLTextAreaElement>} props 
 * @returns 
 */

export function TextArea({ error, value, setValue, ...rest }) {
  const { theme: { theme } } = useStore()
  return (
    <textarea
      className={`${styles.textAreaComponent} ${styles[theme]}`}
      action-error={error ? 'error' : ''}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      placeholder="Digite um texto"
      {...rest}
    />
  );
}
