import React, { useState, useMemo, useEffect } from "react";
import {
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    InputAdornment,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import i18n, { allLanguages } from "@i18n";
import { services, history } from "@store";
import { useStore } from "@hooks";
import { getNameInitials } from "@functions";

import Icon from "@custom/Icon";

import styles from "./styles.module.scss";
import SearchModal from "@custom/SearchModal";
import { serviceCalendar } from "@services/calendar";
import { Notification } from "./modules";

/**
 * @typedef Option
 * @type {{
 *     label: string;
 *     value: string;
 *     type: "news" | "document";
 * }}
 */

const matchOptions = {
    insideWords: true,
    requireMatchAll: true,
};

const menuProps = {
    anchorOrigin: {
        /** @type {"bottom"} */
        vertical: "bottom",
        /** @type {"left"} */
        horizontal: "left",
    },
    transformOrigin: {
        /** @type {"top"} */
        vertical: "top",
        /** @type {"left"} */
        horizontal: "left",
    },
};

function Topbar() {
    /** @type {[HTMLButtonElement | null, React.Dispatch<React.SetStateAction<HTMLButtonElement>>]} */
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    /** @type {[HTMLButtonElement | null, React.Dispatch<React.SetStateAction<HTMLButtonElement>>]} */
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    /** @type {[HTMLInputElement | null, React.Dispatch<React.SetStateAction<HTMLInputElement>>]} */
    const [searchEl, setSearchEl] = useState(null);

    const { auth, news, documents, theme: { theme } } = useStore();
    const langOptionsOpen = Boolean(langAnchorEl);
    const currentLanguage = services.language.getCurrentLanguage();
    const userMenuOpen = Boolean(userAnchorEl);

    const [events, setEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSearchBarClick = () => {
        setIsModalOpen(true);
        console.log(handleSearchBarClick);
    };

    const userInitials = useMemo(() => {
        const fullName = `${auth.user.firstName} ${auth.user.lastName}`;
        return getNameInitials(fullName);
    }, [auth.user]);

    const newsOptions = news.map((news) => ({
        label: `${i18n("Notícia")}: ${news.title}`,
        value: news.uid,
        /** @type {"news"} */
        type: "news",
    }));

    const documentsOptions = documents.map((document) => ({
        label: `${i18n("Documento")}: ${document.action.instructions ||
            document.action.documentInputDescription
            }`,
        value: document.uid,
        /** @type {"document"} */
        type: "document",
    }));

    /** @type {Option[]} */
    const allOptions = [...newsOptions, ...documentsOptions];

    /** @param {React.MouseEvent<HTMLButtonElement>} evt */
    const handleOpenLangOptions = (evt) => {
        setLangAnchorEl(evt.currentTarget);
    };

    const handleCloseLangOptions = () => {
        setLangAnchorEl(null);
    };

    /** @param {React.MouseEvent<HTMLButtonElement>} evt */
    const handleOpenUserMenu = (evt) => {
        setUserAnchorEl(evt.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setUserAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            services.pageSpinner.show();
            handleCloseUserMenu();
            await services.auth.logout();
            services.reset.resetState();
        } finally {
            setTimeout(() => services.pageSpinner.hide(), 5000);
        }
    };

    const handleProfile = () => {
        history.push(`/profile`);
    };

    const handleChangePortal = () => {
        const baseDomain = "https://portal.workflux.digital/";
        const search = "?removeRememberAccess=true";
        window.open(`${baseDomain}${search}`, "_self");
    };

    /** @param {string} newLanguage */
    const handleChangeLanguage = (newLanguage) => () => {
        services.language.changeLanguage(newLanguage);
        handleCloseLangOptions();
    };

    /** @param {Option | null} option */
    const handleSearchChange = (_, option) => {
        console.log("Opção selecionada:", option);
        if (!option) return;

        if (option.type === "news") {
            console.log("Opção selecionada:", option);
            history.push(`/news/${option.value}`);
        }

        if (option.type === "document") {
            history.push(`/my-files/${option.value}`);
        }
    };

    useEffect(() => {
        if (!searchEl) return;

        const input = searchEl.querySelector("input");
        if (!input) return;

        window.addEventListener("keydown", (evt) => {
            if (evt.ctrlKey && evt.key === "/") input.focus();
        });
        return () => { }
    }, [searchEl]);

    useEffect(() => {
        const loadEvents = async () => {
            try {
                if (!auth || !auth.company || !auth.company.uid) {
                    console.error(
                        "Usuário não autenticado ou sem empresa associada."
                    );
                    return;
                }

                const companyUid = auth.company.uid;

                const resultEvents = await serviceCalendar.listEvents({
                    companyUid: companyUid,
                });

                if (typeof resultEvents === "string") {
                    console.error("Erro ao buscar eventos:", resultEvents);
                    return;
                }

                setEvents(resultEvents);
            } catch (error) {
                console.error("Erro ao carregar eventos:", error);
            }
        };

        loadEvents();

        return () => { loadEvents(); }
    }, [auth]);

    useEffect(() => {
        function getTheme() {
            const response = localStorage.getItem("theme")

            if (response) {
                changeTheme(response)
            }
        }
        getTheme()
    }, [])

    const changeTheme = (theme) => {
        document.body.classList.remove(theme === "dark" ? "light" : "dark");
        document.body.classList.add(theme);
        services.theme.changeTheme(theme);
        localStorage.setItem('theme', theme);
    };

    return (
        <header className={`${styles.Topbar} ${styles[theme]}`}>
            <div className={styles.TopbarLeft}>
                <TextField
                    onClick={handleSearchBarClick}
                    className={styles.TopbarSearch}
                    fullWidth
                    variant="standard"
                    placeholder={i18n("Pesquisar (Ctrl+/)")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ color: theme === "dark" ? "#f0f0f0" : "" }}>
                                <Icon icon="faSearch" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiInputBase-input::placeholder': {
                            color: theme === "dark" ? "#f0f0f0" : "",
                        },
                    }}
                />
            </div>
            <div className={styles.TopbarRight}>
                <IconButton onClick={handleOpenLangOptions}>
                    <TranslateIcon />
                </IconButton>
                <IconButton onClick={() => changeTheme(theme === "dark" ? "light" : "dark")}>
                    {theme === "dark" ? (
                        <LightModeIcon />
                    ) : (
                        <DarkModeIcon />
                    )}
                </IconButton>
                <Notification />

                <IconButton
                    className={styles.TopbarUserPhotoButton}
                    onClick={handleOpenUserMenu}
                >
                    <Avatar
                        className={styles.TopbarUserPhoto}
                        src={auth.user.photo}
                        alt="Foto de perfil"
                    >
                        {userInitials}
                    </Avatar>
                </IconButton>

                <Menu
                    anchorEl={userAnchorEl}
                    open={userMenuOpen}
                    onClose={handleCloseUserMenu}
                    PaperProps={{
                        className: styles.TopbarUserMenu,
                        sx: {
                            background: theme === "dark" ? "#2e3646" : ""
                        }
                    }}
                    {...menuProps}
                >
                    <MenuItem onClick={handleProfile}
                        sx={{
                            color: theme == "dark" ? "#f0f0f0" : "",
                        }}
                    >
                        {i18n("Perfil")}
                    </MenuItem>
                    <MenuItem onClick={handleChangePortal}
                        sx={{
                            color: theme == "dark" ? "#f0f0f0" : "",
                        }}
                    >
                        {i18n("Trocar Portal")}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}
                        sx={{
                            color: theme == "dark" ? "#f0f0f0" : "",
                        }}
                    >
                        {i18n("Sair da conta")}
                    </MenuItem>
                </Menu>
                <Menu
                    anchorEl={langAnchorEl}
                    open={langOptionsOpen}
                    onClose={handleCloseLangOptions}
                    PaperProps={{
                        className: styles.TopbarLanguages,
                        sx: {
                            background: theme === "dark" ? "#2e3646" : ""
                        }
                    }}
                    {...menuProps}
                >
                    {Object.keys(allLanguages).map((language) => {
                        const [, code] = language.split("_");
                        return (
                            <MenuItem
                                key={language}
                                className={styles.TopbarOption}
                                onClick={handleChangeLanguage(language)}
                                disabled={language === currentLanguage.language}
                                sx={{
                                    color: theme == "dark" ? "#f0f0f0" : "",
                                }}
                            >
                                <img
                                    loading="lazy"
                                    srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
                                    alt={i18n(language)}
                                />
                                <span>{i18n(language)}</span>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
            <SearchModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                news={news}
                documents={documents}
                events={events}
            />
        </header>
    );
}

export default Topbar;

/**
 * @param {string} text
 * @param {string} search
 */
function getHighlightedParts(text, search) {
    const matches = match(text, search, matchOptions);
    const parts = parse(text, matches);
    return parts;
}

/**
 * @param {any} props
 * @param {Option} option
 * @param {{ inputValue: string }} param2
 */
function renderOption(props, option, { inputValue }) {
    const parts = getHighlightedParts(option.label, inputValue);

    return (
        <li {...props}>
            {parts.map((part, index) => (
                <span
                    key={index}
                    style={{
                        whiteSpace: "pre",
                        fontWeight: part.highlight ? 700 : 400,
                    }}
                >
                    {part.text}
                </span>
            ))}
        </li>
    );
}
