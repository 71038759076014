"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _firebase["default"];
  }
});
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _firebase = _interopRequireWildcard(require("./firebase.config"));
var _store = require("../store");
var _common = require("../common");
var _i18n = _interopRequireDefault(require("../../@i18n"));
var _axios = _interopRequireDefault(require("axios"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var templateCollection = _firebase["default"].firestore().collection('template');
var storeKey = '@K#App$autodocs';
var types = {
  update: 'autodocs-'
};
var storedState = JSON.parse(localStorage.getItem(storeKey)) || {};
var createInitialState = function createInitialState(storedState) {
  return _objectSpread({}, storedState);
};
var INITIAL_STATE = createInitialState(storedState);
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var getState = function getState(oState, action) {
    switch (action.type) {
      default:
        return _objectSpread({}, oState);
    }
  };
  var finalState = getState(state, action);
  localStorage.setItem(storeKey, JSON.stringify(finalState));
  return finalState;
};
var getCurrentState = function getCurrentState() {
  return _store.store.getState().appcenter;
};
var isLocal = window.location.href.indexOf('localhost') > -1;
var getAppcenterStore = function getAppcenterStore() {
  return _store.store.getState().appcenter;
};
var api = function api() {
  var api = _axios["default"].create({
    baseURL: isLocal ? 'http://localhost:5002/autodocsapp/us-central1/webApi/api/v1/' : 'https://us-central1-autodocsapp.cloudfunctions.net/webApi/api/v1',
    timeout: 240000
  });
  var service = {
    axios: function axios() {
      return api;
    },
    auth: {
      login: {
        token: function token(_token) {
          return _firebase["default"].auth().signInWithCustomToken(_token);
        }
      },
      logout: function logout() {
        if (!_firebase["default"]) return;
        return _firebase["default"].auth().signOut();
      }
    },
    template: {
      list: function list() {
        var appcenterStore = getAppcenterStore();
        if (!appcenterStore.authenticated) return Promise.reject();
        //('WILL SEARCH TEMPLATES', appcenterStore.company)
        return templateCollection.where('company', '==', appcenterStore.company.uid).where('deleted', '==', false).get().then(function (s) {
          return s.docs.map(function (d) {
            return d.data();
          });
        });
      },
      get: function get(uid) {
        if (!uid) return Promise.reject();
        var appcenterStore = getAppcenterStore();
        if (!appcenterStore.authenticated) return Promise.reject();
        if (uid === 'l1GzKBp4M3zlPIuXtWqB') {
          return templateCollection.doc(uid).get().then(function (doc) {
            return doc.data();
          });
        }
        return templateCollection.where('uid', '==', uid).where('deleted', '==', false).where('company', '==', appcenterStore.company.uid).get().then(function (s) {
          return s.docs.map(function (d) {
            return d.data();
          })[0];
        });
      },
      document: function (_document) {
        function document(_x, _x2, _x3) {
          return _document.apply(this, arguments);
        }
        document.toString = function () {
          return _document.toString();
        };
        return document;
      }(function (templateDoc, fields, fileName) {
        var appcenterStore = getAppcenterStore();
        if (!appcenterStore.authenticated) return Promise.reject();
        var template = {
          document: templateDoc,
          fields: fields,
          company: appcenterStore.company.uid,
          header: '',
          footer: '',
          headerHeight: '',
          footerHeight: ''
        };
        return api.post('document/generate/pdf', _objectSpread({}, template), {
          responseType: 'blob'
        }).then(function (r) {
          if (!fileName) {
            return r;
          }
          var file = r.data;
          file.name = fileName;
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = fileURL;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(fileURL);
          document.body.removeChild(a);
          return Promise.resolve();
        });
      })
    }
  };
  return service;
};
var autodocs = api();
_store.store.injectReducer('autodocs', reducer, autodocs);
var _default = autodocs;
exports["default"] = _default;