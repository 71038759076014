import { ipLocal } from "./endpoints";

/**
 * @typedef Localization
 * @type {{
 *     ip: string;
 *     city: string;
 *     region: string;
 *     region_code: string;
 *     country: string;
 *     country_code: string;
 *     country_code_iso3: string;
 *     country_name: string;
 *     country_capital: string;
 *     country_tld: string;
 *     continent_code: string;
 *     in_eu: boolean;
 *     postal: string;
 *     latitude: number;
 *     longitude: number;
 *     timezone: string;
 *     utc_offset: string;
 *     country_calling_code: string;
 *     currency: string;
 *     currency_name: string;
 *     languages: string;
 *     asn: string;
 *     org: string;
 * }}
 */

const ipLocalApi = {
    /** @returns {Promise<Localization>} */
    async getLocal() {
        const { data } = await ipLocal.get("/");
        return data;
    },
};

export default ipLocalApi;
export { ipLocalApi };
