import styles from './acordionDetails.module.scss'
import React from 'react'
import { Accordion, AccordionSummary } from '@mui/material'
import { useStore } from '@hooks'

export function AcordionDetails({ icon, summary, number }) {

  const { theme: { theme } } = useStore()

  return (
    <Accordion
      style={{
        boxShadow: 'none',
        border: 'none',
        marginBottom: 8,
        background: theme === "dark" ? "#2e3646" : "#EAE9EE",

      }}
    >
      <AccordionSummary>
        <div className={`${styles.summaryContainer} ${styles[theme]}`}>
          <div>
            <span>{icon}</span>
            <strong>{summary}</strong>
          </div>
          <strong>{number < 9 ? `0${number}` : number}</strong>
        </div>
      </AccordionSummary>
    </Accordion >
  )
}