import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";

import { services } from "@store";
import { useStore } from "@hooks";
import Router from "../Router";

import SidebarNav from "@custom/SidebarNav";
import Spinner from "@custom/Spinner";
import PageSpinner from "@custom/PageSpinner";
import Message from "@custom/Message";

import styles from "./styles.module.scss";

function AppCommon() {
    const { auth, message, spinner, pageSpinner, toast } = useStore();

    useEffect(() => {
        services.ipLocal.fetch();
        return () => {}
    }, []);

    return (
        <>
            <main className={styles.Page}>
                {auth.isAuthenticated && <SidebarNav />}
                <Router />
            </main>
            <Spinner fullPage loading={spinner.show} />
            <PageSpinner loading={pageSpinner.show} />
            <Message
                visible={message.visible}
                title={message.title}
                description={message.description}
                buttons={message.buttons}
                hide={message.hide}
                variant={message.variant}
            />
            <Toaster {...toast} />
        </>
    );
}

export default AppCommon;
