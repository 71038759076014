import React, { useState } from 'react'
import styles from './menuNotification.module.scss'
import { IconButton, Menu, MenuItem } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AvatarProfileStatus } from 'components/avatar/avatarStatus';
import { useAsyncEffect, useStore } from '@hooks';
import i18n, { format } from '@i18n';
import { toDate } from 'zerocode';
import { history, services } from '@store';

export function Notification() {

  /** @type {[HTMLButtonElement | null, React.Dispatch<React.SetStateAction<HTMLButtonElement>>]} */
  const [AnchorEl, setAnchorEl] = useState(null);
  const MenuOpen = Boolean(AnchorEl);

  const menuProps = {
    anchorOrigin: {
      /** @type {"bottom"} */
      vertical: "bottom",
      /** @type {"left"} */
      horizontal: "left",
    },
    transformOrigin: {
      /** @type {"top"} */
      vertical: "top",
      /** @type {"left"} */
      horizontal: "left",
    },
  };

  const url = null


  const { tasks, auth, theme: { theme } } = useStore();

  function handleNotificationsList({ uid, title, description, date, profileUrl, to }) {
    return {
      id: uid,
      profileUrl,
      title,
      description,
      date,
      to
    }
  }

  useAsyncEffect(async () => {
    if (
      !auth.company?.uid ||
      !auth.user?.uid ||
      !auth.isAuthenticated ||
      !!tasks.length
    )
      return;

    services.spinner.show();

    await services.tasks.list({
      company: auth.company.uid,
      user: auth.user.uid,
    });
    services.spinner.hide();

  }, [auth.company, auth.user]);


  const tasksExpirations =
    tasks.map(task => {
      if (task?.status.toString() === 'pending') {
        return handleNotificationsList({
          uid: task?.uid,
          profileUrl: url,
          title: task?.workflowObject?.description,
          description: task?.action?.formTitle || task?.action?.instructions,
          date: format.date(toDate(task?.createdAt)),
          to: `/inbox/${task?.uid}`
        })
      } else {

        return null
      }

    }).filter(task => task !== null);

  const notifications = [...tasksExpirations]


  /** @param {React.MouseEvent<HTMLButtonElement>} evt */
  function openMenu(evt) {
    setAnchorEl(evt.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null)
  }

  function handleClickMenu(link) {
    history.push(link)
    closeMenu()
  }

  return (
    <div>
      <IconButton onClick={openMenu}>
        <NotificationsIcon />
        {notifications.length > 0 && (<div className={styles.badgeNotify} />)}
      </IconButton>

      <Menu
        anchorEl={AnchorEl}
        open={MenuOpen}
        onClose={closeMenu}
        {...menuProps}
        className={`${styles.menu} ${styles[theme]}`}
        PaperProps={{
          sx: {
            background: theme === "dark" ? "#2e3646" : ""
          }
        }}
      >
        <div className={styles.headerMenu}>
          <strong>Notificações</strong>
          <div className={styles.quantityNotify}>
            <strong>
              {notifications.length} Novas</strong>
          </div>
        </div>
        {notifications.length === 0 ? (
          <MenuItem
            className={styles.notification}
            onClick={closeMenu}
          >
            <div className={styles.infos}>
              <div className={styles.text}>
                <h3>
                  {i18n("Nenhuma notificação encontrada")}
                </h3>
              </div>
            </div>
          </MenuItem>
        ) :
          notifications?.map((notify) => (
            <MenuItem key={notify?.id}
              className={styles.notification}
              onClick={() => handleClickMenu(notify.to)}
            >
              <div className={styles.infos}>
                <AvatarProfileStatus profileUrl={null} userName={auth.user.firstName} />
                <div className={styles.text}>
                  <h3>
                    {notify?.title}
                  </h3>
                  <p>{notify?.description}</p>
                </div>
              </div>
              <p className={styles.date}>{notify?.date}</p>
            </MenuItem>
          )
          )

        }
      </Menu>
    </div>
  )
}