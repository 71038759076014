import React, { useEffect, useState } from "react";
import Topbar from "@custom/Topbar";

import Icon from "@custom/Icon";
import styles from "./styles.module.scss";

import { WeatherInfo } from "./components/WeatherInfo";
import { useAsyncEffect, useGeoLocation, useStore } from "@hooks";
import { serviceWeather } from "@services/weather";
import { services } from "@store";
import i18n from "@i18n";

export default function Weather() {
    const [weatherList, setWeatherList] = useState([]);
    const [currentWeather, setCurrentWather] = useState({
        dt_txt: "",
        weather: [
            {
                description: "",
            },
        ],
        main: {
            temp: 0,
            humidity: 0,
        },
        wind: {
            speed: 0,
        },
    });

    const { ipLocal } = useStore();
    const { location, hasPermission, loading } = useGeoLocation();

    useEffect(() => {
        loading ? services.spinner.show() : services.spinner.hide();
        return () => {}
    }, [loading]);

    useAsyncEffect(async () => {
        const forecast = await serviceWeather.getForecast({
            latitude: ipLocal.latitude,
            longitude: ipLocal.longitude,
        });

        if (!forecast) return;

        const weather = forecast.shift();

        setWeatherList(forecast);
        setCurrentWather(weather);
    }, []);

    return (
        <div className={styles.Weather}>
            <Topbar />

            {!hasPermission && (
                <div className={styles.WeatherError}>
                    <div className={styles.WeatherErrorMessage}>
                        <Icon icon="faMapLocation" />

                        <h2>
                            Não foi possível carregar os dados climáticos, por
                            favor, verifique as permissões de seu navegador.
                        </h2>
                    </div>
                </div>
            )}

            {hasPermission && (
                <div className={styles.WeatherMain}>
                    <header className={styles.WeatherHeader}>
                        <div className={styles.WeatherHeaderLeft}>
                            <h1>
                                {location.city?.long_name},
                                {location.state?.short_name}
                            </h1>
                            <div className={styles.WeatherHeaderLeftLast}>
                                <p>
                                    <strong>{i18n("Temperatura")}: </strong>
                                    {currentWeather.main.temp.toFixed(2)}
                                    °C
                                </p>
                                <p>
                                    <strong>{i18n("Vento")}: </strong>
                                    {currentWeather.wind.speed}
                                    km/h
                                </p>
                                <p>
                                    <strong>{i18n("Humidade")}: </strong>
                                    {currentWeather.main.humidity}%
                                </p>
                                <p>
                                    <strong>{i18n("Data")}: </strong>
                                    {currentWeather.dt_txt.split(" ")[0]}
                                </p>
                            </div>
                        </div>

                        <div className={styles.WeatherHeaderRight}>
                            <div>
                                <img
                                    src={`https://openweathermap.org/img/wn/${currentWeather.weather[0].icon}@4x.png`}
                                    alt="weather-icon"
                                />
                            </div>
                            <p>
                                {`${currentWeather.weather[0].description}`.toLocaleUpperCase()}
                            </p>
                        </div>
                    </header>

                    <WeatherInfo weatherList={weatherList} />
                </div>
            )}
        </div>
    );
}
