import React from "react";

import { ConnectedRouter } from "connected-react-router";
import { store, history } from "@store";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Provider } from "react-redux";
import AppCommon from "./AppCommon";

import "@assets/styles/index.scss";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";


const theme = createTheme({
    palette: {
        primary: { main: "#00679d" },
        secondary: { main: "#fdd835" },
        error: { main: "#f44336" },
        warning: { main: "#FF8800" },
    },
});

const App = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <AppCommon />
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>
);

export default App;
