import React, { useEffect, useState } from "react";

import { executionFlowCollection } from "@firebase-config/collections";
import { DetailsFlows } from "components/detailsFlow";


export function FullFlow({ task, close, open }) {
    /**  @type {any} */
    const [executionFlow, setExecutionFlow] = useState({});

    useEffect(() => {
        async function getExecutionFlow() {
            const query = executionFlowCollection.where(
                "uid",
                "==",
                task?.executionFlow
            );

            try {
                const response = await query.get();
                const data = response.docs[0].data();
                setExecutionFlow(data);

            } catch (error) { }
        }
        getExecutionFlow();

        return () => { getExecutionFlow();}
    }, []);

    return <DetailsFlows
        open={open}
        close={close}
        executionFlow={executionFlow}
        uidTask={task.uid}
        archive={null}
        unarchive={null}
    />

}
