"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.linkedInSecret = exports.linkedInClientID = exports.googleClientID = exports.facebookClientID = void 0;
var googleClientID = '970445245374-hahvagt8r8ur4ppqcuvhl5rnqebei3r9.apps.googleusercontent.com';
exports.googleClientID = googleClientID;
var linkedInClientID = '778yzvz5lavggi';
exports.linkedInClientID = linkedInClientID;
var facebookClientID = '1045212833246664';
exports.facebookClientID = facebookClientID;
var linkedInSecret = 'W6UOpiDon3h7Uc6e';
exports.linkedInSecret = linkedInSecret;