"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var registerPageConstants = {
  collectame: {
    title: 'Sua assinatura digital começa aqui!',
    subtitle: 'Cadastre a sua conta e descubra a sua caneta online'
  },
  'appcenter-customer': {
    title: 'O seu centro de aplicações Taugor!',
    subtitle: 'Faça seu cadastro e tenha acesso ao painel de soluções!'
  },
  dokia: {
    title: 'Uma central para gerenciar todos os seus documentos',
    subtitle: 'Cadastre-se e descubra o poder do Dok+'
  },
  workflux: {
    title: 'Sua central gerencial de fluxos e tarefas!',
    subtitle: 'Cadastre-se e descubra o Workflux'
  },
  'workflux-portal': {
    title: 'Sua central gerencial de fluxos e tarefas!',
    subtitle: 'Cadastre-se e descubra o Workflux Portal'
  }
};
var _default = registerPageConstants;
exports["default"] = _default;