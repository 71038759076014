

import { toDate } from "@functions";

const deletedModel = {
  active: [],
  addresses: [],
  aplications: [],
  birthdate: "",
  companies: [],
  companyadmin: false,
  createdAt: new Date(),
  createdBy: "",
}

const companyAreaModel = {
  company: "",
  createdAt: new Date(),
  createdBy: "",
  deleted: false,
  deletedBy: {},
  description: "",
  groups: [],
  name: "",
  uid: "",
  workflows: []
};

function createCompanyArea(data) {
  if (!data) data = {};

  return {
    ...companyAreaModel,
    ...data,
    deleted: !!data.delete,
    createdAt: toDate(data.createdAt),
  };
}

export { companyAreaModel, createCompanyArea };