"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "colors", {
  enumerable: true,
  get: function get() {
    return _colors["default"];
  }
});
Object.defineProperty(exports, "coverImages", {
  enumerable: true,
  get: function get() {
    return _coverImages["default"];
  }
});
Object.defineProperty(exports, "docMasks", {
  enumerable: true,
  get: function get() {
    return _docMasks["default"];
  }
});
exports.inboxLink = void 0;
Object.defineProperty(exports, "loginPageConstants", {
  enumerable: true,
  get: function get() {
    return _loginPageConstants["default"];
  }
});
Object.defineProperty(exports, "logosImages", {
  enumerable: true,
  get: function get() {
    return _logosImages["default"];
  }
});
Object.defineProperty(exports, "registerPageConstants", {
  enumerable: true,
  get: function get() {
    return _registerPageConstants["default"];
  }
});
var _docMasks = _interopRequireDefault(require("./docMasks"));
var _colors = _interopRequireDefault(require("./colors"));
var _logosImages = _interopRequireDefault(require("./logosImages"));
var _coverImages = _interopRequireDefault(require("./coverImages"));
var _loginPageConstants = _interopRequireDefault(require("./loginPageConstants"));
var _registerPageConstants = _interopRequireDefault(require("./registerPageConstants"));
var inboxLink = "https://inbox-ticket.web.app/tickets/new";
exports.inboxLink = inboxLink;