import React from "react";
import { Modal } from "@mui/material";

import i18n from "@i18n";
import QuillRichTextEditor from "@custom/QuillRichTextEditor";

import "./styles.scss";
import { Button } from "components/ui";

export function RichTextModal({
    open,
    handleClose,
    handleChange,
    value,
    field,
}) {
    return (
        <Modal open={open}>
            <div className="RichTextModal">
                <div className="RichTextModalTop">
                    <label className="RichTextModalLabel">{field}</label>

                    <Button buttonClose onClick={handleClose} />
                </div>

                <QuillRichTextEditor
                    style={{ width: "100%" }}
                    theme="snow"
                    onChange={handleChange}
                    value={value}
                    placeholder={i18n("Digite aqui...")}
                />
            </div>
        </Modal>
    );
}
