"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.store = exports.services = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _redux = require("redux");
var _message = _interopRequireDefault(require("./message"));
var _browser = _interopRequireDefault(require("./browser"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var initialState = {};
var createReducers = function createReducers(asyncReducers) {
  return (0, _redux.combineReducers)(_objectSpread({
    message: _message["default"].reducer,
    browser: _browser["default"].reducer
  }, asyncReducers));
};
var store = (0, _redux.createStore)(createReducers(), initialState);
exports.store = store;
var services = {
  message: _message["default"].service(store.dispatch),
  browser: _browser["default"].service(store.dispatch)
};
exports.services = services;
store.asyncReducers = {};
store.injectReducer = function (key, asyncReducer, service) {
  store.asyncReducers[key] = asyncReducer;
  services[key] = service;
  store.replaceReducer(createReducers(store.asyncReducers));
};