import React, { useEffect, useState } from "react";
import {
    Tooltip,
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Stack,
    Typography,
    Divider,
} from "@mui/material";

import i18n from "@i18n";
import { services } from "@store";
import { workfluxApi } from "@apis";
import { useStore } from "@hooks";

import styles from "./styles.module.scss";
import Icon from "@custom/Icon";
import { serviceTask } from "@services/task";
import { DetailTask } from "components/detailTask";
import { Modal } from "components";

/**
 * @typedef Workflow
 * @type {import("@models/workflow").Workflow}
 */

/**
 *
 * @param {{
 *     workflow: Workflow;
 *     close: () => void;
 *     open: boolean
 * }} props
 */
const WorkflowStartup = ({ workflow, close, open }) => {
    const { auth, groups, theme: { theme } } = useStore();
    const [initialTask, setInitialTask] = useState(null)

    useEffect(() => {
        setInitialTask(null)
        return () => { }
    }, [workflow])


    const startExecutionFlow = () => {
        services.spinner.show();
        workfluxApi.executionFlow
            .create({ workflow })
            .finally(services.spinner.hide)
            .then(async (doc) => {

                // services.executionFlows.list({ company: auth.company.uid })
                // services.tasks.list({ user: auth.user.uid })

                let taskUid = doc?.workflowObject?.phases[0]?.actions[0]?.task

                if (!taskUid) {
                    const task = doc?.workflowObject?.phases[0]?.actions.find((task) => task?.task)

                    taskUid = task.task
                }

                const response = await serviceTask.find({ taskUid })

                if (response && response !== "invalid-param-taskUid") {
                    setInitialTask(response)
                } else {
                    setInitialTask(null)
                }

                close();

                services.message.show({
                    title: i18n("Solicitação criada com sucesso"),
                    buttons: [
                        {
                            onClick: services.message.hide,
                            children: i18n("Fechar"),
                        },
                    ],
                    variant: "success",
                });

            })
            .catch((e) => {
                if (!e.response || !e.response.data) {
                    services.message.show({
                        title: "Não foi possível criar o fluxo devido a um erro",
                        buttons: [
                            {
                                onClick: services.message.hide,
                                children: i18n("Fechar"),
                            },
                        ],
                        variant: "error",
                    });
                    return;
                }

                services.message.show({
                    title: "Não foi possível criar o fluxo devido a um erro",
                    description: (
                        <div>
                            {i18n(
                                `executionFlow.create.error.${e.response.data.id}`
                            )}
                        </div>
                    ),
                    buttons: [
                        {
                            onClick: services.message.hide,
                            children: i18n("Fechar"),
                        },
                    ],
                    variant: "error",
                });
            });
    };

    return (
        <>
            <Modal
                open={open}
                close={close}
                title={i18n("Preencha os dados para a execução")}
            >

                <section className={`${styles.WorkflowExecution} ${styles[theme]}`}>

                    <TextField
                        id="description"
                        onChange={(e) => {
                            workflow.description = e.target.value;
                        }}
                        variant="filled"
                        label={i18n("Titulo da solicitação")}
                        InputLabelProps={{
                            sx: {
                                color: theme === "dark" ? "#F0F0F0" : "", 
                            },
                        }}
                        sx={{
                            marginY: 2,
                            '& input': {
                                color: theme === "dark" ? "#FFF" : "", 
                            },

                        }}
                    />

                    <Tooltip
                        title={i18n(
                            "Ao marcar como urgente, este fluxo aparecerá primeiro nas listagens de fluxos"
                        )}
                    >
                        <FormControlLabel
                            label="Marcar como urgente"
                            control={
                                <Checkbox
                                    onChange={(e) => {
                                        workflow.urgent = e.target.checked;
                                    }}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            color: theme === "dark" ? "#F4f4F4" : "", 
                                        },
                                    }}
                                />
                            }
                        />
                    </Tooltip>

                    <Tooltip
                        title={i18n(
                            "Ao marcar Executar como anônimo os seus dados não serão exibidos"
                        )}
                    >
                        <FormControlLabel
                            label={i18n("Executar como anônimo (Não exibir meu dados)")}
                            control={
                                <Checkbox
                                    onChange={(e) => {
                                        workflow.hideRequester = e.target.checked;
                                    }}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            color: theme === "dark" ? "#F4f4F4" : "", 
                                        },
                                    }}
                                />
                            }
                        />
                    </Tooltip>

                    <Stack mt={4} flexDirection="row" gap={2} justifyContent="center">
                        <Button
                            onClick={startExecutionFlow}
                            variant="contained"
                            color="success"
                        >
                            {i18n("Confirmar")}
                        </Button>
                        <Button onClick={close} variant="contained" color="error">
                            {i18n("Cancelar")}
                        </Button>
                    </Stack>
                </section>
            </Modal>

            {initialTask && (
                <DetailTask taskProps={initialTask} />
            )}
        </>
    );
};

export default WorkflowStartup;
