import { signingCollection } from "@firebase-config/collections";
import { findSigning } from "./find";

/**
 * @typedef Signing
 * @type {import("@models/signing").Signing}
 */

/**
 * Function to update signings
 * @param  {string} uid
 * @param  {Partial<Signing>} updates
 * @returns {Promise<string | Signing>}
 */
export async function updateSigning(uid, updates) {
    try {
        await signingCollection.doc(uid).update({ ...updates });

        const signing = await findSigning({ signingUid: uid });
        return signing;
    } catch (error) {
        console.log("ERROR in updateSigning: >=>", error);
        return "error-find-signing";
    }
}
