import React from "react";
import { useStore } from "@hooks";
import { services, history } from "@store";
// @ts-ignore
import { AuthPage } from "zerocode";

const Authentication = ({ match }) => {
    const { auth } = useStore();

    /**
     * @param {React.ReactNode} title
     * @param {React.ReactNode} description
     */
    const showMessage = (title, description) => {
        services.message.show({ title, description });
    };

    return (
        <AuthPage
            app="workflux-portal"
            urlProps={match}
            auth={auth}
            loginWithEmail={services.auth.login}
            loginWithSSO={services.auth.sso}
            loginWithCertificate={services.auth.loginWithCertificate}
            loginWithGoogle={services.auth.loginWithGoogle}
            loginWithLinkedIn={services.auth.loginWithLinkedIn}
            logout={services.auth.logout}
            hideSpinner={services.pageSpinner.hide}
            showSpinner={services.pageSpinner.show}
            showMessage={showMessage}
            hideMessage={services.message.hide}
            goToPage={history.push}
        />
    );
};

export default Authentication;
