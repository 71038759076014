import miscRoutes from './miscRoutes';

/**
 * @typedef RouteProps
 * @type {{
 *    key: string;
 *    Component: (props: any) => React.ReactNode;
 *    path: string;
 *    adminOnly: boolean;
 *    unprotected: boolean;
 *    licenses: string[];
 *    testStage: boolean;
 *    taugorOnly: boolean;
 * }}
 */

/** @type {RouteProps[]} */
const routes = [
	...miscRoutes,
];

export default routes;
export { routes };
