"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _app["default"];
  }
});
var _app = _interopRequireDefault(require("firebase/app"));
require("firebase/storage");
require("firebase/firestore");
require("firebase/auth");
var firebaseConfig = {
  apiKey: 'AIzaSyAuthzfVjbbqpHU2Tlx3ty6PPkCe1zi4p0',
  authDomain: 'autodocsapp.firebaseapp.com',
  databaseURL: 'https://autodocsapp.firebaseio.com',
  projectId: 'autodocsapp',
  storageBucket: 'autodocsapp.appspot.com',
  messagingSenderId: '459426994430',
  appId: '1:459426994430:web:637b12c2e5605bf1c22daf'
};
var app = _app["default"].initializeApp(firebaseConfig, 'autodocs');
var _default = app;
exports["default"] = _default;