"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _yopenLogin = _interopRequireDefault(require("../@assets/images/yopen/auth/yopen-login.svg"));
var _yopenForgotPassword = _interopRequireDefault(require("../@assets/images/yopen/auth/yopen-forgotPassword.svg"));
var _yopenRegister = _interopRequireDefault(require("../@assets/images/yopen/auth/yopen-register.svg"));
var _yopenRecoveryEmailSent = _interopRequireDefault(require("../@assets/images/yopen/auth/yopen-recoveryEmailSent.svg"));
var _yopenRedefinePassword = _interopRequireDefault(require("../@assets/images/yopen/auth/yopen-redefinePassword.svg"));
var _appcenterLogin = _interopRequireDefault(require("../@assets/images/appcenter/auth/appcenter-login.svg"));
var _appcenterForgotPassword = _interopRequireDefault(require("../@assets/images/appcenter/auth/appcenter-forgotPassword.svg"));
var _appcenterRegister = _interopRequireDefault(require("../@assets/images/appcenter/auth/appcenter-register.svg"));
var _appcenterRecoveryEmailSent = _interopRequireDefault(require("../@assets/images/appcenter/auth/appcenter-recoveryEmailSent.svg"));
var _appcenterRedefinePassword = _interopRequireDefault(require("../@assets/images/appcenter/auth/appcenter-redefinePassword.svg"));
var _dokiaLogin = _interopRequireDefault(require("../@assets/images/dokplus/auth/dokia-login.svg"));
var _dokiaForgotPassword = _interopRequireDefault(require("../@assets/images/dokplus/auth/dokia-forgotPassword.svg"));
var _dokiaRegister = _interopRequireDefault(require("../@assets/images/dokplus/auth/dokia-register.svg"));
var _dokiaRecoveryEmailSent = _interopRequireDefault(require("../@assets/images/dokplus/auth/dokia-recoveryEmailSent.svg"));
var _dokiaRedefinePassword = _interopRequireDefault(require("../@assets/images/dokplus/auth/dokia-redefinePassword.svg"));
var _workfluxLogin = _interopRequireDefault(require("../@assets/images/workflux/auth/workflux-login.svg"));
var _workfluxForgotPassword = _interopRequireDefault(require("../@assets/images/workflux/auth/workflux-forgotPassword.svg"));
var _workfluxRegister = _interopRequireDefault(require("../@assets/images/workflux/auth/workflux-register.svg"));
var _workfluxRecoveryEmailSent = _interopRequireDefault(require("../@assets/images/workflux/auth/workflux-recoveryEmailSent.svg"));
var _workfluxRedefinePassword = _interopRequireDefault(require("../@assets/images/workflux/auth/workflux-redefinePassword.svg"));
//imports yopen

//imports appcenter

//imports dokplus

//imports workflux

var coverImages = {
  collectame: {
    login: _yopenLogin["default"],
    forgotPassword: _yopenForgotPassword["default"],
    register: _yopenRegister["default"],
    recoveryEmailSent: _yopenRecoveryEmailSent["default"],
    redefinePassword: _yopenRedefinePassword["default"]
  },
  'appcenter-customer': {
    login: _appcenterLogin["default"],
    forgotPassword: _appcenterForgotPassword["default"],
    register: _appcenterRegister["default"],
    recoveryEmailSent: _appcenterRecoveryEmailSent["default"],
    redefinePassword: _appcenterRedefinePassword["default"]
  },
  dokia: {
    login: _dokiaLogin["default"],
    forgotPassword: _dokiaForgotPassword["default"],
    register: _dokiaRegister["default"],
    recoveryEmailSent: _dokiaRecoveryEmailSent["default"],
    redefinePassword: _dokiaRedefinePassword["default"]
  },
  workflux: {
    login: _workfluxLogin["default"],
    forgotPassword: _workfluxForgotPassword["default"],
    register: _workfluxRegister["default"],
    recoveryEmailSent: _workfluxRecoveryEmailSent["default"],
    redefinePassword: _workfluxRedefinePassword["default"]
  },
  'workflux-portal': {
    login: _workfluxLogin["default"],
    forgotPassword: _workfluxForgotPassword["default"],
    register: _workfluxRegister["default"],
    recoveryEmailSent: _workfluxRecoveryEmailSent["default"],
    redefinePassword: _workfluxRedefinePassword["default"]
  }
};
var _default = coverImages;
exports["default"] = _default;