import { appcenter, workflux } from "@apis/endpoints";
import pageSpinner from "./pageSpinner";
// @ts-ignore
import { factory } from "zerocode";

const auth = factory.auth({
    app: "workflux",
    axiosCustomApis: [workflux, appcenter],
    spinnerService: pageSpinner,
});

export default auth;
