import React from "react";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Subtitle(props) {
    const { fieldConfig } = props;

    return <h4 className={styles.Subtitle}>{fieldConfig.name}</h4>;
}

export default Subtitle;
export { Subtitle };
