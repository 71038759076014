import React, { useEffect, useMemo, useState } from "react";

import { dokia } from "zerocode";
import { RenderEntityField } from "./RenderEntityField";

import styles from "./styles.module.scss";

const EntityFieldsForm = ({ entity, document, handleUpdateEntity }) => {
    const [fieldschange, setFieldsChange] = useState({});

    const allFieldsSorted = useMemo(() => {
        return Object.values(fieldschange).sort((a, b) => a.order - b.order);
    }, [fieldschange]);

    useEffect(() => {
        const newFieldValue = {};

        Object.values(entity.fields).forEach((field) => {
            newFieldValue[field.uid] = field;
        });

        setFieldsChange(newFieldValue);

        dokia.document.validate(document);
    }, [document, entity.fields]);

    const handleFieldChange = (fieldId, value) => {
        setFieldsChange((prevState) => {
            const updatedArray = Object.keys(prevState).map((key) => {
                const obj = prevState[key];
                if (obj.uid === fieldId) {
                    return {
                        ...obj,
                        value: value,
                    };
                }
                return obj;
            });

            const updatedFields = Object.fromEntries(
                updatedArray.map((obj) => [obj.uid, obj])
            );

            handleUpdateEntity({ fields: updatedFields });

            return updatedFields;
        });
    };

    return (
        <div>
            <h3 className={styles.fieldsTitle}>{entity.name}</h3>
            <div className={styles.fieldsList}>
                {allFieldsSorted.map((field, i) => (
                    <RenderEntityField
                        key={i}
                        field={field}
                        handleFieldChange={handleFieldChange}
                    />
                ))}
            </div>
        </div>
    );
};

export default EntityFieldsForm;
