import React from "react";

import i18n from "@i18n";
import Topbar from "@custom/Topbar";

import { services } from "@store";
import { useAsyncEffect, useStore } from "@hooks";

import styles from "./styles.module.scss";
import { TasksList } from "../components/tasksList";
import { FilterTasks } from "../components/filterTasks";
import { DetailTask } from "components/detailTask";


function InboxPage() {
    const { auth, tasks } = useStore();

    useAsyncEffect(async () => {
        if (
            !auth.company?.uid ||
            !auth.user?.uid ||
            !auth.isAuthenticated ||
            !!tasks.length
        )
            return;

        services.spinner.show();

        await services.tasks.list({
            company: auth.company.uid,
            user: auth.user.uid,
        });
        services.spinner.hide();
    }, [auth.company, auth.user]);

    return (

        <div className={styles.inboxContainer}>
            <Topbar />
            <h2>
                {i18n(
                    "Bem-vindo à sua caixa de entrada para controle de tarefas"
                )}
            </h2>

            <div className={styles.InboxContent}>
                <section className={styles.InboxAside}>
                    <FilterTasks />
                    <TasksList />
                </section>
                <DetailTask />
            </div>
        </div>

    );
}

export default InboxPage;
