import { executedDocumentCollection } from "@firebase-config/collections";

/**
 * @typedef ExecutedDocument
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * Function to update executed documents
 * @param  {string} uid
 * @param  {Partial<ExecutedDocument>} updates
 * @returns {Promise<string | ExecutedDocument>}
 */
export async function updateExecutedDocument(uid, updates) {
    try {
        await executedDocumentCollection.doc(uid).update({ ...updates });
    } catch (error) {
        console.log("ERROR in updateExecutedDocument: >=>", error);
        return "error-find-executedDocument";
    }
}
