// @ts-nocheck
import { taskCollection } from "@firebase-config/collections";
import { createTask } from "@models";

/**
 * @typedef Task
 * @type {import("@models/task").Task}
 */

/**
 * Function to list tasks pending of executionFlow
 * @param {{
 *      userUid: string;
 *      lastDocUid: string;
 *      limit: number;
 *      groupUid?: string,
 * }} params
 * @returns {Promise<string | Task[]>}
 */
export async function listTasks({ userUid, lastDocUid, limit = 20, groupUid }) {
    if (!userUid || typeof userUid !== "string") {
        return "invalid-param-userUid";
    }
    let query = taskCollection
        .where("agent", "==", userUid)
        .where("deleted", "==", false)
        .where("workflowObject.security", "==", "public")
        .orderBy("createdAt", "desc")
        .limit(limit);

    try {
        if (groupUid) {
            query = query.where("workflowObject.group.uid", "==", groupUid);
        }

        if (lastDocUid) {
            const doc = await taskCollection.doc(lastDocUid).get();
            query = query.startAfter(doc);
        }

        const { docs } = await query.get();

        const tasks = docs.map((doc) => doc.data());

        if (!tasks.length) return "not-found-tasks";

        return tasks.map(createTask);
    } catch (error) {
        console.log("ERROR in listTasks: >=>", error);
        return "error-list-tasks";
    }
}
