/**
 * @typedef FieldConfig
 * @type {{
 *     name: string;
 *     type: string;
 *     mask: string;
 *     options: string[] | boolean[];
 * }}
 */

/**
 * @param {string} fields
 * @returns {FieldConfig[]}
 */
function getFieldListFromString(fields) {
    if (!fields) return [];

    return fields
        .split(/(\n)/g)
        .filter((s) => s.replace(/\;/g, "").trim())
        .map((fieldconfig, i) => {
            let field, type, s, options, mask;
            if (fieldconfig.indexOf("@") > -1) {
                s = fieldconfig.split("@");
                field = s[0];
                type = s[1];
            } else {
                field = fieldconfig;
                type = "string";
            }
            if (type.indexOf("(") > -1) {
                s = type.split("(");
                type = s[0];
                if (["select", "table"].indexOf(type) > -1) {
                    options = s[1].replace(")", "").split("\\");
                } else if (type === "link") {
                    options = [s[1].replace(")", "")];
                }
            }

            if (type === "checkbox") {
                options = [true, false];
            }

            if (type.indexOf("mask#") > -1) {
                s = type.split("mask#");
                type = "";
                mask = s[1];
            }
            if (type === "cpf") {
                mask = "999.999.999-99";
                type = "cpf";
            }
            if (type === "cnpj") {
                mask = "99.999.999/9999-99";
                type = "cnpj";
            }
            return { name: field, type, mask, options };
        });
}

export default getFieldListFromString;
export { getFieldListFromString };
