"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _react = require("react");
/** @param {() => Promise<void>} callback */
function useAsyncEffect(callback) {
  var dependencies = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  (0, _react.useEffect)(function () {
    callback();
  }, (0, _toConsumableArray2["default"])(dependencies));
}
var _default = useAsyncEffect;
exports["default"] = _default;