import React, { useState } from "react";
import i18n from "@i18n";
import QuillRichTextEditor from "@custom/QuillRichTextEditor";
import styles from "./styles.module.scss";
import Icon from "@custom/Icon";
import { Tooltip } from "@mui/material";
import { RichTextModal } from "./RichTextModal";

/** @param {import("../index.js").FormComponentProps} props */
function RichText(props) {
    const [openModal, setOpenModal] = useState(false);
    const { formValues, fieldConfig, updateFormValues, formFilled } = props;

    const field = fieldConfig.name;
    const value = formValues[field] || "";

    /** @param {string} valueChange */
    const handleFormValueChange = (valueChange) => {
        updateFormValues(field, valueChange);
    };

    const expandField = () => {
        setOpenModal(true);
    };

    if (formFilled) {
        return (
            <>
                <p>
                    <strong>{field}*</strong>
                </p>
                <div
                    className={styles.RichTextDisplay}
                    dangerouslySetInnerHTML={{
                        __html: value,
                    }}
                />
            </>
        );
    }

    return (
        <div className={styles.RichText}>
            <div className={styles.RichTextTop}>
                <label className={styles.RichTextLabel}>{field}*</label>

                <Tooltip title="Expandir campo">
                    <div onClick={expandField}>
                        <Icon icon="faMaximize" />
                    </div>
                </Tooltip>
            </div>

            <QuillRichTextEditor
                key={`${openModal}`}
                style={{ width: "100%" }}
                theme="snow"
                onChange={handleFormValueChange}
                value={value}
                placeholder={i18n("Digite aqui...")}
            />

            <RichTextModal
                handleChange={handleFormValueChange}
                value={value}
                field={field}
                handleClose={() => setOpenModal(false)}
                open={openModal}
            />
        </div>
    );
}

export default RichText;
export { RichText };
