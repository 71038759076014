import { ipLocalApi } from "@apis";

/**
 * @typedef Localization
 * @type {import("@apis/ipLocalApi").Localization}
 */

const STORE_IP_LOCAL = 'STORE_IP_LOCAL';

const service = (dispatch) => ({
	fetch: async () => {
        const local = await ipLocalApi.getLocal();

		dispatch({
			type: STORE_IP_LOCAL,
            payload: {
                local
            }
		});
	},
});

/** @type {Partial<Localization>} */
const INIT_STATE = {};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case STORE_IP_LOCAL:
			return {
				...state,
				...action.payload.local
			};

		default:
			return { ...state };
	}
};

export default { reducer, service };
