"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _app["default"];
  }
});
exports.firebaseConfig = void 0;
var _app = _interopRequireDefault(require("firebase/app"));
require("firebase/storage");
require("firebase/firestore");
require("firebase/auth");
var firebaseConfig = {
  apiKey: 'AIzaSyAKhW8WDYSENlTngo-D_tZpvEa5WTcgHiE',
  authDomain: 'dokia-adesc.firebaseapp.com',
  databaseURL: 'https://dokia-adesc.firebaseio.com',
  projectId: 'dokia-adesc',
  storageBucket: 'dokia-adesc.appspot.com',
  messagingSenderId: '26405837095',
  appId: '1:26405837095:web:f324c060580edecf257102'
};
exports.firebaseConfig = firebaseConfig;
var app = _app["default"].initializeApp(firebaseConfig, 'dokia-adesc');
var _default = app;
exports["default"] = _default;