"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  DokiaComponents: true,
  LinkedInPage: true,
  factory: true,
  appcenter: true,
  appcenterFirebase: true,
  appcenterMainFirebase: true,
  appcenterStore: true,
  collectame: true,
  collectameFirebase: true,
  collectameSignatoryType: true,
  collectameSignatureType: true,
  workflux: true,
  workfluxFirebase: true,
  workfluxMainFirebase: true,
  autodocs: true,
  autodocsFirebase: true,
  autodocsMainFirebase: true,
  dokia: true,
  dokiaAdescFirebase: true,
  dokiaBetaFirebase: true,
  dokiaNewtonFirebase: true,
  mainFirebaseAdesc: true,
  mainFirebaseBeta: true,
  mainFirebaseNewton: true,
  store: true,
  services: true,
  getAppImageSrc: true,
  fixTimezone: true,
  getChunks: true,
  groupByItemProperty: true,
  objectHasProps: true,
  playSound: true,
  smallUid: true,
  toDate: true,
  uid: true,
  UltraBar: true,
  Input: true,
  Icon: true,
  Message: true,
  Sidebar: true,
  ContentEditable: true,
  Tabs: true,
  Chip: true,
  UserCard: true,
  GlobalLoader: true,
  FloatingMenu: true,
  Collapsible: true,
  Autocomplete: true,
  RichEditor: true,
  Scripts: true,
  ChoosePortal: true,
  AccountPage: true,
  AuthPage: true,
  PrivacyPolicyPage: true,
  TermsAndConditionsPage: true,
  QRCode: true
};
Object.defineProperty(exports, "AccountPage", {
  enumerable: true,
  get: function get() {
    return _Account["default"];
  }
});
Object.defineProperty(exports, "AuthPage", {
  enumerable: true,
  get: function get() {
    return _Auth["default"];
  }
});
Object.defineProperty(exports, "Autocomplete", {
  enumerable: true,
  get: function get() {
    return _Autocomplete["default"];
  }
});
Object.defineProperty(exports, "Chip", {
  enumerable: true,
  get: function get() {
    return _Chip["default"];
  }
});
Object.defineProperty(exports, "ChoosePortal", {
  enumerable: true,
  get: function get() {
    return _ChoosePortal["default"];
  }
});
Object.defineProperty(exports, "Collapsible", {
  enumerable: true,
  get: function get() {
    return _Collapsible["default"];
  }
});
Object.defineProperty(exports, "ContentEditable", {
  enumerable: true,
  get: function get() {
    return _ContentEditable["default"];
  }
});
exports.DokiaComponents = void 0;
Object.defineProperty(exports, "FloatingMenu", {
  enumerable: true,
  get: function get() {
    return _FloatingMenu["default"];
  }
});
Object.defineProperty(exports, "GlobalLoader", {
  enumerable: true,
  get: function get() {
    return _GlobalLoader["default"];
  }
});
Object.defineProperty(exports, "Icon", {
  enumerable: true,
  get: function get() {
    return _Icon["default"];
  }
});
Object.defineProperty(exports, "Input", {
  enumerable: true,
  get: function get() {
    return _Input["default"];
  }
});
exports.LinkedInPage = void 0;
Object.defineProperty(exports, "Message", {
  enumerable: true,
  get: function get() {
    return _Message["default"];
  }
});
Object.defineProperty(exports, "PrivacyPolicyPage", {
  enumerable: true,
  get: function get() {
    return _PrivacyPolicy["default"];
  }
});
Object.defineProperty(exports, "QRCode", {
  enumerable: true,
  get: function get() {
    return _QRCode["default"];
  }
});
Object.defineProperty(exports, "RichEditor", {
  enumerable: true,
  get: function get() {
    return _RichEditor["default"];
  }
});
Object.defineProperty(exports, "Scripts", {
  enumerable: true,
  get: function get() {
    return _Scripts["default"];
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function get() {
    return _Sidebar["default"];
  }
});
Object.defineProperty(exports, "Tabs", {
  enumerable: true,
  get: function get() {
    return _Tabs["default"];
  }
});
Object.defineProperty(exports, "TermsAndConditionsPage", {
  enumerable: true,
  get: function get() {
    return _TermsAndConditions["default"];
  }
});
Object.defineProperty(exports, "UltraBar", {
  enumerable: true,
  get: function get() {
    return _UltraBar["default"];
  }
});
Object.defineProperty(exports, "UserCard", {
  enumerable: true,
  get: function get() {
    return _UserCard["default"];
  }
});
Object.defineProperty(exports, "appcenter", {
  enumerable: true,
  get: function get() {
    return _index["default"];
  }
});
Object.defineProperty(exports, "appcenterFirebase", {
  enumerable: true,
  get: function get() {
    return _index.firebase;
  }
});
Object.defineProperty(exports, "appcenterMainFirebase", {
  enumerable: true,
  get: function get() {
    return _index.mainFirebase;
  }
});
Object.defineProperty(exports, "appcenterStore", {
  enumerable: true,
  get: function get() {
    return _index.store;
  }
});
Object.defineProperty(exports, "autodocs", {
  enumerable: true,
  get: function get() {
    return _autodocs["default"];
  }
});
Object.defineProperty(exports, "autodocsFirebase", {
  enumerable: true,
  get: function get() {
    return _autodocs.firebase;
  }
});
Object.defineProperty(exports, "autodocsMainFirebase", {
  enumerable: true,
  get: function get() {
    return _autodocs.mainFirebase;
  }
});
Object.defineProperty(exports, "collectame", {
  enumerable: true,
  get: function get() {
    return _collectame["default"];
  }
});
Object.defineProperty(exports, "collectameFirebase", {
  enumerable: true,
  get: function get() {
    return _collectame.firebase;
  }
});
Object.defineProperty(exports, "collectameSignatoryType", {
  enumerable: true,
  get: function get() {
    return _collectame.signatoryType;
  }
});
Object.defineProperty(exports, "collectameSignatureType", {
  enumerable: true,
  get: function get() {
    return _collectame.signatureType;
  }
});
Object.defineProperty(exports, "dokia", {
  enumerable: true,
  get: function get() {
    return _dokia["default"];
  }
});
Object.defineProperty(exports, "dokiaAdescFirebase", {
  enumerable: true,
  get: function get() {
    return _dokia.firebaseAdesc;
  }
});
Object.defineProperty(exports, "dokiaBetaFirebase", {
  enumerable: true,
  get: function get() {
    return _dokia.firebaseBeta;
  }
});
Object.defineProperty(exports, "dokiaNewtonFirebase", {
  enumerable: true,
  get: function get() {
    return _dokia.firebaseNewton;
  }
});
exports.factory = void 0;
Object.defineProperty(exports, "fixTimezone", {
  enumerable: true,
  get: function get() {
    return _common.fixTimezone;
  }
});
Object.defineProperty(exports, "getAppImageSrc", {
  enumerable: true,
  get: function get() {
    return _common.getAppImageSrc;
  }
});
Object.defineProperty(exports, "getChunks", {
  enumerable: true,
  get: function get() {
    return _common.getChunks;
  }
});
Object.defineProperty(exports, "groupByItemProperty", {
  enumerable: true,
  get: function get() {
    return _common.groupByItemProperty;
  }
});
Object.defineProperty(exports, "mainFirebaseAdesc", {
  enumerable: true,
  get: function get() {
    return _dokia.mainFirebaseAdesc;
  }
});
Object.defineProperty(exports, "mainFirebaseBeta", {
  enumerable: true,
  get: function get() {
    return _dokia.mainFirebaseBeta;
  }
});
Object.defineProperty(exports, "mainFirebaseNewton", {
  enumerable: true,
  get: function get() {
    return _dokia.mainFirebaseNewton;
  }
});
Object.defineProperty(exports, "objectHasProps", {
  enumerable: true,
  get: function get() {
    return _common.objectHasProps;
  }
});
Object.defineProperty(exports, "playSound", {
  enumerable: true,
  get: function get() {
    return _common.playSound;
  }
});
Object.defineProperty(exports, "services", {
  enumerable: true,
  get: function get() {
    return _store.services;
  }
});
Object.defineProperty(exports, "smallUid", {
  enumerable: true,
  get: function get() {
    return _common.smallUid;
  }
});
Object.defineProperty(exports, "store", {
  enumerable: true,
  get: function get() {
    return _store.store;
  }
});
Object.defineProperty(exports, "toDate", {
  enumerable: true,
  get: function get() {
    return _common.toDate;
  }
});
Object.defineProperty(exports, "uid", {
  enumerable: true,
  get: function get() {
    return _common.uid;
  }
});
Object.defineProperty(exports, "workflux", {
  enumerable: true,
  get: function get() {
    return _workflux["default"];
  }
});
Object.defineProperty(exports, "workfluxFirebase", {
  enumerable: true,
  get: function get() {
    return _workflux.firebase;
  }
});
Object.defineProperty(exports, "workfluxMainFirebase", {
  enumerable: true,
  get: function get() {
    return _workflux.mainFirebase;
  }
});
var _EntityForm = _interopRequireDefault(require("./@components/dokia/entity/EntityForm"));
require("./@assets/scss/themes/main.scss");
require("./@assets/scss/themes/dark.scss");
var _reactLinkedinLoginOauth = require("react-linkedin-login-oauth2");
var _index = _interopRequireWildcard(require("./@api/appcenter/index"));
var _license = require("./@api/appcenter/license");
Object.keys(_license).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _license[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _license[key];
    }
  });
});
var _permission = require("./@api/appcenter/permission");
Object.keys(_permission).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _permission[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _permission[key];
    }
  });
});
var _collectame = _interopRequireWildcard(require("./@api/collectame"));
var _workflux = _interopRequireWildcard(require("./@api/workflux"));
var _autodocs = _interopRequireWildcard(require("./@api/autodocs"));
var _dokia = _interopRequireWildcard(require("./@api/dokia"));
var _store = require("./@api/store");
var _common = require("./@api/common");
var _UltraBar = _interopRequireDefault(require("./@components/UltraBar.js"));
var _Input = _interopRequireDefault(require("./@components/Input.js"));
var _Icon = _interopRequireDefault(require("./@components/Icon.js"));
var _Message = _interopRequireDefault(require("./@components/Message.js"));
var _Sidebar = _interopRequireDefault(require("./@components/Sidebar.js"));
var _ContentEditable = _interopRequireDefault(require("./@components/ContentEditable.js"));
var _Tabs = _interopRequireDefault(require("./@components/Tabs.js"));
var _Chip = _interopRequireDefault(require("./@components/Chip.js"));
var _UserCard = _interopRequireDefault(require("./@components/UserCard.js"));
var _GlobalLoader = _interopRequireDefault(require("./@components/GlobalLoader.js"));
var _FloatingMenu = _interopRequireDefault(require("./@components/FloatingMenu.js"));
var _Collapsible = _interopRequireDefault(require("./@components/Collapsible.js"));
var _Autocomplete = _interopRequireDefault(require("./@components/Autocomplete.js"));
var _RichEditor = _interopRequireWildcard(require("./@components/RichEditor"));
Object.keys(_RichEditor).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _RichEditor[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _RichEditor[key];
    }
  });
});
var _Scripts = _interopRequireDefault(require("./@components/Scripts"));
var _ChoosePortal = _interopRequireDefault(require("./@components/ChoosePortal"));
var _Account = _interopRequireDefault(require("./@components/Account"));
var _Auth = _interopRequireDefault(require("./@components/Auth"));
var _PrivacyPolicy = _interopRequireDefault(require("./@components/PrivacyPolicy"));
var _TermsAndConditions = _interopRequireDefault(require("./@components/TermsAndConditions"));
var _QRCode = _interopRequireDefault(require("./@components/QRCode"));
var _auth = _interopRequireDefault(require("./@factory/auth"));
var _draggable = _interopRequireDefault(require("./@factory/draggable"));
var _hooks = require("./@hooks");
Object.keys(_hooks).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _hooks[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hooks[key];
    }
  });
});
var _apis = require("./@apis");
Object.keys(_apis).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _apis[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _apis[key];
    }
  });
});
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
// appcenter

// yopen

// workflux

// editfy

// dokplus

// generics

var DokiaComponents = {
  EntityForm: _EntityForm["default"]
};
exports.DokiaComponents = DokiaComponents;
var LinkedInPage = _reactLinkedinLoginOauth.LinkedInCallback;
exports.LinkedInPage = LinkedInPage;
var factory = {
  auth: _auth["default"],
  draggable: _draggable["default"]
};
exports.factory = factory;