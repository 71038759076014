"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var docMasks = {
  cnpj: "99.999.999/9999-99",
  cpf: "999.999.999-99"
};
var _default = docMasks;
exports["default"] = _default;