import React, { useMemo } from "react";
import { modules } from "@constants";

import { SidebarMobile } from "./modules/sidebarMobile";
import { SidebarWeb } from "./modules/sidebarWeb";

function SidebarNav() {
    const activeModule = useMemo(() => {
        const module = modules.reduce((active, mod) => {
            const isActive = window.location.href.includes(mod.path);
            return isActive ? mod : active;
        }, undefined);

        return module;
    }, [window.location.href]);

    return (

        <section>
            <SidebarMobile modules={modules} activeModule={activeModule} />
            <SidebarWeb modules={modules} activeModule={activeModule} />
        </section>
    );
}

export default SidebarNav;
