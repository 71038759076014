import React from "react";
import {
    Select as MuiSelect,
    FormControl,
    InputLabel,
    MenuItem,
} from "@mui/material";
import styles from "./styles.module.scss";

/** @param {import("../index.js").FormComponentProps} props */
function Select(props) {
    const { formValues, fieldConfig, updateFormValues, formFilled } = props;
    const { options = [], name: field } = fieldConfig;
    const value = formValues[field];

    /** @param {React.ChangeEvent<HTMLInputElement>} evt */
    const handleFormValueChange = (evt) => {
        updateFormValues(field, evt.target.value);
    };

    if (formFilled) {
        return (
            <>
                <p>
                    <strong>{field}</strong>
                </p>
                <p>{value}</p>
            </>
        );
    }

    return (
        <FormControl fullWidth className={styles.Select}>
            <InputLabel>{field}*</InputLabel>
            <MuiSelect
                variant="outlined"
                label={field}
                onChange={handleFormValueChange}
                value={value}
            >
                {options.map((option, i) => (
                    <MenuItem key={option + i} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
}

export default Select;
export { Select };
