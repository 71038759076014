"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function fixTimezone(d) {
  var date = new Date(d.getTime());
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
}
var _default = fixTimezone;
exports["default"] = _default;