import { toast } from "react-hot-toast";

const SET_DEFAULT_CONFIG = "SET_DEFAULT_CONFIG";
const SHOW_SUCCESS_TOAST = "SHOW_SUCCESS_TOAST";
const SHOW_ERROR_TOAST = "SHOW_ERROR_TOAST";
const SHOW_CUSTOM_TOAST = "SHOW_CUSTOM_TOAST";
const SHOW_LOADING_TOAST = "SHOW_LOADING_TOAST";

/**
 * @typedef ToastOptions 
 * @type {Partial<Pick<import("react-hot-toast").Toast, "id" | "icon" | "duration" | "ariaProps" | "className" | "style" | "position" | "iconTheme">>}
 */

const service = (dispatch) => ({
    /** @param {ToastOptions} options */
    config: (options) => {
        dispatch({
            type: SET_DEFAULT_CONFIG,
            payload: options
        });
    },
    /** 
     * @param {string | JSX.Element} message 
     * @param {ToastOptions=} options
     **/
    success: (message, options) => {
        dispatch({ type: SHOW_SUCCESS_TOAST });
        toast.success(message, {
            ...options,
            style: {
                ...options?.style,
                background: '#28a745',
                color: '#fff',
                marginTop: 50,
            },
        });
    },
    /** 
     * @param {string | JSX.Element} message 
     * @param {ToastOptions=} options
     **/
    error: (message, options) => {
        dispatch({ type: SHOW_ERROR_TOAST });
        toast.error(message, {
            ...options,
            style: {
                ...options?.style,
                backgroundColor: '#dc3545',
                color: '#fff',
                marginTop: 50,
            },
        });
    },
    /** 
     * @param {string | JSX.Element} component 
     * @param {ToastOptions=} options
     **/
    custom: (component, options) => {
        dispatch({ type: SHOW_CUSTOM_TOAST });
        toast.custom(component, options);
    },
    /** 
     * @param {string | JSX.Element} message 
     * @param {ToastOptions=} options
     **/
    loading: (message, options) => {
        dispatch({ type: SHOW_LOADING_TOAST });
        toast.loading(message, options);
    },
});

/** @type {ToastOptions} */
const INIT_STATE = {
    style: {}
};

/**
 * @param {ToastOptions} state 
 * @param {{
 *     type: string;
 *     payload: ToastOptions;
 * }} action 
 * @returns 
 */
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_SUCCESS_TOAST:
        case SHOW_ERROR_TOAST:
        case SHOW_LOADING_TOAST:
        case SHOW_CUSTOM_TOAST:
        default:
            return { ...state, ...action.payload };
    }
};

export default { reducer, service };
