"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var SHOW_MESSAGE = 'MESSAGE_SHOW';
var HIDE_MESSAGE = 'MESSAGE_HIDE';
var service = function service(dispatch) {
  return {
    show: function show(title, description, buttons) {
      dispatch({
        type: SHOW_MESSAGE,
        title: title,
        description: description,
        buttons: buttons
      });
    },
    hide: function hide() {
      dispatch({
        type: HIDE_MESSAGE
      });
    }
  };
};
var INIT_STATE = {
  visible: false,
  title: '',
  description: '',
  buttons: []
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INIT_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SHOW_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        visible: true,
        title: action.title,
        description: action.description,
        buttons: action.buttons
      });
    case HIDE_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        visible: false
      });
    default:
      return _objectSpread({}, state);
  }
};
var _default = {
  reducer: reducer,
  service: service
};
exports["default"] = _default;