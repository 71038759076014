const units = ["bytes", "kb", "mb", "gb", "tb"];

/**
 * @param {number} value
 * @param {"bytes" | "kb" | "mb" | "gb" | "tb"} unit
 */
function convertToBytes(value, unit) {
    let bytes = value;

    for (let i = 0; i < units.length; i++) {
        if (units[i] === unit) {
            break;
        }
        bytes *= 1000;
    }

    return bytes;
}

export { units, convertToBytes };
