import Icon from "@custom/Icon"
import { useStore } from "@hooks"
import i18n from "@i18n"
import { Button as ButtonMui, CircularProgress, IconButton } from "@mui/material"
import React from "react"

/**
 * 
 * @param {{
 *  loading?: boolean
 *  title?: string
 *  color?: "error" | "success" | "warning" | "info" | "primary" | "secondary" | "default"
 *  iconLeft?: import("react").ReactNode
 *  iconRight?: import("react").ReactNode
 *  buttonClose?: boolean
 *  fullWidth?: boolean
 *  buttonIcon?: boolean
 *  icon?: import("react").ReactNode
 * } & import("react").ButtonHTMLAttributes<HTMLButtonElement>} props 
 * @returns 
 */

export function Button({
  buttonIcon = false,
  buttonClose = false,
  icon,
  fullWidth = false,
  iconLeft,
  iconRight,
  color = "primary",
  loading,
  title,
  ...rest
}) {

  const { theme: { theme } } = useStore()


  if (buttonClose) {

    return (
      <IconButton
        color="error"
        size="small"
        style={{
          borderRadius: 4,
          backgroundColor: '#ff3d00',
          color: '#fff',
          width: 30
        }}
        {...rest}
      >
        <Icon icon="faX" />
      </IconButton>
    )
  }


  if (buttonIcon) {
    return (
      <IconButton
        size="small"
        sx={{
          color: theme === "dark" ? "#FFF" : ""
        }}
        {...rest}
      >
        {icon}
      </IconButton>
    )
  }

  return (
    <ButtonMui
      size="small"
      variant="contained"
      fullWidth={fullWidth}
      color={color === "default" ? "primary" : color}
      startIcon={iconLeft}
      endIcon={iconRight}
      sx={{
        m: 0,
        "& .MuiButton-startIcon, & .MuiButton-endIcon": {
          margin: "auto",
        },
        "&.Mui-disabled": {
          backgroundColor: theme == "dark" ? "#ffffff30" : "",
          color: theme == "dark" ? "white" : "",
        }
      }}
      style={{

      }}
      {...rest}
    >

      {loading ? (<CircularProgress size={20} />) : (title && i18n(title))}

    </ButtonMui>

  )
}