import React, { useState } from "react";

import { workfluxApi } from "@apis";
import { history, services } from "@store";

import { useStore } from "@hooks";
import { Modal, TextArea, Button } from "components/ui";

export function ModalCancelFlow({ open, close, flowUid, taskUid }) {
    const { auth: { user } } = useStore()

    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCancelFlow = async () => {
        if (message === "") {
            setErrorMessage(true);
            setTimeout(() => {
                setErrorMessage(false);
            }, 500);
            return;
        }

        services.spinner.show()
        try {

            await workfluxApi.executionFlow.cancel({
                id: flowUid,
                cancelMessage: message,
                user: user
            });

            if (taskUid) {

                await services.tasks.updateTask({
                    taskUid,
                });
            }

            history.push("/inbox");
            close();
        } catch (error) {
            console.log("ERROR", error);
        }

        services.spinner.hide()

    };

    const Footer = () => {
        return (
            <>
                <Button
                    color="error"
                    onClick={close}
                    title="voltar"
                />

                <Button
                    onClick={handleCancelFlow}
                    title="confirmar cancelamento"
                />
            </>
        )
    }

    return (
        <Modal
            open={open}
            close={close}
            title="Cancelamento de fluxo"
            footer={<Footer />}
        >
            <TextArea
                setValue={setMessage}
                value={message}
                error={errorMessage}
                placeholder="Motivo do cancelamento."
            />
        </Modal>
    );
}
