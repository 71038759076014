"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "classNames", {
  enumerable: true,
  get: function get() {
    return _classNames["default"];
  }
});
Object.defineProperty(exports, "convertToBytes", {
  enumerable: true,
  get: function get() {
    return _convertToBytes["default"];
  }
});
Object.defineProperty(exports, "customSizeUid", {
  enumerable: true,
  get: function get() {
    return _customSizeUid["default"];
  }
});
Object.defineProperty(exports, "displayLicenseAmount", {
  enumerable: true,
  get: function get() {
    return _displayLicenseAmount["default"];
  }
});
Object.defineProperty(exports, "fixTimezone", {
  enumerable: true,
  get: function get() {
    return _fixTimezone["default"];
  }
});
Object.defineProperty(exports, "formatBytes", {
  enumerable: true,
  get: function get() {
    return _formatBytes["default"];
  }
});
Object.defineProperty(exports, "getBrowser", {
  enumerable: true,
  get: function get() {
    return _getBrowser["default"];
  }
});
exports.getDomain = void 0;
Object.defineProperty(exports, "getQueryParams", {
  enumerable: true,
  get: function get() {
    return _getQueryParams["default"];
  }
});
exports.getRememberMeToken = void 0;
Object.defineProperty(exports, "isLocal", {
  enumerable: true,
  get: function get() {
    return _isLocal["default"];
  }
});
Object.defineProperty(exports, "jsonToUrlQueries", {
  enumerable: true,
  get: function get() {
    return _jsonToUrlQueries["default"];
  }
});
Object.defineProperty(exports, "onlyNumbers", {
  enumerable: true,
  get: function get() {
    return _onlyNumbers["default"];
  }
});
Object.defineProperty(exports, "openInWhatsapp", {
  enumerable: true,
  get: function get() {
    return _openInWhatsapp["default"];
  }
});
exports.setRememberMeToken = exports.removeRememberMeToken = void 0;
Object.defineProperty(exports, "toDate", {
  enumerable: true,
  get: function get() {
    return _toDate["default"];
  }
});
Object.defineProperty(exports, "validator", {
  enumerable: true,
  get: function get() {
    return _validator["default"];
  }
});
var _classNames = _interopRequireDefault(require("./classNames"));
var _toDate = _interopRequireDefault(require("./toDate"));
var _validator = _interopRequireDefault(require("./validator"));
var _customSizeUid = _interopRequireDefault(require("./customSizeUid"));
var _getBrowser = _interopRequireDefault(require("./getBrowser"));
var _isLocal = _interopRequireDefault(require("./isLocal"));
var _fixTimezone = _interopRequireDefault(require("./fixTimezone"));
var _getQueryParams = _interopRequireDefault(require("./getQueryParams"));
var _convertToBytes = _interopRequireDefault(require("./convertToBytes"));
var _formatBytes = _interopRequireDefault(require("./formatBytes"));
var _displayLicenseAmount = _interopRequireDefault(require("./displayLicenseAmount"));
var _openInWhatsapp = _interopRequireDefault(require("./openInWhatsapp"));
var _onlyNumbers = _interopRequireDefault(require("./onlyNumbers"));
var _jsonToUrlQueries = _interopRequireDefault(require("./jsonToUrlQueries"));
var rememberMeKey = 'remember-me-sso-token';

/** @param {string} app */
var getRememberMeToken = function getRememberMeToken(app) {
  return localStorage.getItem("".concat(app, "-").concat(rememberMeKey));
};

/** @param {string} app */
exports.getRememberMeToken = getRememberMeToken;
var removeRememberMeToken = function removeRememberMeToken(app) {
  return localStorage.removeItem("".concat(app, "-").concat(rememberMeKey));
};

/** @param {string} app */
exports.removeRememberMeToken = removeRememberMeToken;
var setRememberMeToken = function setRememberMeToken(app) {
  var token = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return localStorage.setItem("".concat(app, "-").concat(rememberMeKey), token);
};

/** @returns {string} */
exports.setRememberMeToken = setRememberMeToken;
var getDomain = function getDomain() {
  var _window, _window$location;
  return ((_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.hostname) || '';
};
exports.getDomain = getDomain;