//imports yopen
import loginYopen from '../@assets/images/yopen/auth/yopen-login.svg'
import forgotPasswordYopen from '../@assets/images/yopen/auth/yopen-forgotPassword.svg'
import registerYopen from '../@assets/images/yopen/auth/yopen-register.svg'
import recoveryEmailSentYopen from '../@assets/images/yopen/auth/yopen-recoveryEmailSent.svg'
import redefinePasswordYopen from '../@assets/images/yopen/auth/yopen-redefinePassword.svg'

//imports appcenter
import loginAppcenter from '../@assets/images/appcenter/auth/appcenter-login.svg'
import forgotPasswordAppcenter from '../@assets/images/appcenter/auth/appcenter-forgotPassword.svg'
import registerAppcenter from '../@assets/images/appcenter/auth/appcenter-register.svg'
import recoveryEmailSentAppcenter from '../@assets/images/appcenter/auth/appcenter-recoveryEmailSent.svg'
import redefinePasswordAppcenter from '../@assets/images/appcenter/auth/appcenter-redefinePassword.svg'

//imports dokplus
import loginDokPlus from '../@assets/images/dokplus/auth/dokia-login.svg'
import forgotPasswordDokPlus from '../@assets/images/dokplus/auth/dokia-forgotPassword.svg'
import registerDokPlus from '../@assets/images/dokplus/auth/dokia-register.svg'
import recoveryEmailSentDokPlus from '../@assets/images/dokplus/auth/dokia-recoveryEmailSent.svg'
import redefinePasswordDokPlus from '../@assets/images/dokplus/auth/dokia-redefinePassword.svg'

//imports workflux
import loginWorkflux from '../@assets/images/workflux/auth/workflux-login.svg'
import forgotPasswordWorkflux from '../@assets/images/workflux/auth/workflux-forgotPassword.svg'
import registerWorkflux from '../@assets/images/workflux/auth/workflux-register.svg'
import recoveryEmailSentWorkflux from '../@assets/images/workflux/auth/workflux-recoveryEmailSent.svg'
import redefinePasswordWorkflux from '../@assets/images/workflux/auth/workflux-redefinePassword.svg'

const coverImages = {
	collectame: {
		login: loginYopen,
		forgotPassword: forgotPasswordYopen,
		register: registerYopen,
		recoveryEmailSent: recoveryEmailSentYopen,
		redefinePassword: redefinePasswordYopen
	},
	'appcenter-customer': {
		login: loginAppcenter,
		forgotPassword: forgotPasswordAppcenter,
		register: registerAppcenter,
		recoveryEmailSent: recoveryEmailSentAppcenter,
		redefinePassword: redefinePasswordAppcenter
	},
	dokia: {
		login: loginDokPlus,
		forgotPassword: forgotPasswordDokPlus,
		register: registerDokPlus,
		recoveryEmailSent: recoveryEmailSentDokPlus,
		redefinePassword: redefinePasswordDokPlus
	},
	workflux: {
		login: loginWorkflux,
		forgotPassword: forgotPasswordWorkflux,
		register: registerWorkflux,
		recoveryEmailSent: recoveryEmailSentWorkflux,
		redefinePassword: redefinePasswordWorkflux
	},
	'workflux-portal': {
		login: loginWorkflux,
		forgotPassword: forgotPasswordWorkflux,
		register: registerWorkflux,
		recoveryEmailSent: recoveryEmailSentWorkflux,
		redefinePassword: redefinePasswordWorkflux
	}
}

export default coverImages
