"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.units = exports["default"] = void 0;
var units = ['bytes', 'kb', 'mb', 'gb', 'tb'];

/**
 * @param {number} value
 * @param {"bytes" | "kb" | "mb" | "gb" | "tb"} unit
 */
exports.units = units;
function convertToBytes(value, unit) {
  var bytes = value;
  for (var i = 0; i < units.length; i++) {
    if (units[i] === unit) {
      break;
    }
    bytes *= 1024;
  }
  return bytes;
}
var _default = convertToBytes;
exports["default"] = _default;