"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultUser = exports.defaultCustomer = exports["default"] = void 0;
var _functions = require("../@functions");
var _endpoints = require("./endpoints");
/**
 * @typedef SSOToken
 * @type {{
 *     uid: string;
 *     createdAt: Date;
 *     createdBy: string;
 *     application: string;
 * }}
 */

/**
 * @typedef CNPJData
 * @type {import("@apis/receitaApi.js").CNPJData}
 */

/**
 * @typedef CEPData
 * @type {import("@apis/cepApi.js").CEPData}
 */

/**
 * @typedef CustomerType
 * @type {"company" | "autonomous"}
 */

var defaultUser = {
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  phoneArea: '',
  phone: '',
  taxDocument: '',
  birthdate: ''
};
exports.defaultUser = defaultUser;
var defaultCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  /** @type {CustomerType} */
  type: 'autonomous',
  identificationNumber: '',
  cep: '',
  /** @type {CEPData} */
  local: {
    cep: '',
    bairro: '',
    cidade: '',
    logradouro: '',
    numero: '',
    estado_info: {
      area_km2: '',
      codigo_ibge: '',
      nome: ''
    },
    cidade_info: {
      area_km2: '',
      codigo_ibge: ''
    },
    estado: ''
  },
  /** @type {CNPJData} */
  company: {
    status: '',
    ultima_atualizacao: '',
    cnpj: '',
    tipo: '',
    porte: '',
    nome: '',
    fantasia: '',
    abertura: '',
    atividade_principal: [],
    atividades_secundarias: [],
    natureza_juridica: '',
    logradouro: '',
    numero: '',
    complemento: '',
    cep: '',
    bairro: '',
    municipio: '',
    uf: '',
    email: '',
    telefone: '',
    efr: '',
    situacao: '',
    data_situacao: '',
    motivo_situacao: '',
    situacao_especial: '',
    data_situacao_especial: '',
    capital_social: '',
    qsa: [],
    billing: {
      free: true,
      database: true
    }
  }
};

/**
 * @typedef Customer
 * @type {typeof defaultCustomer}
 */

/**
 * @typedef User
 * @type {typeof defaultUser}
 */
exports.defaultCustomer = defaultCustomer;
var appcenterApi = {
  auth: {
    /**
     * @typedef {import("axios").AxiosResponse<SSOToken>} SSOTokenResponse
     */
    /**
     * @param {string} userUid
     * @param {string} companyUid
     * @param {string=} app
     * @returns {Promise<SSOTokenResponse>}
     */
    createSSOToken: function createSSOToken(userUid, companyUid) {
      var app = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'collectame';
      return _endpoints.appcenter.post("/authentication/sso/".concat(companyUid, "/").concat(app, "/").concat(userUid));
    },
    /**
     * @typedef {import("axios").AxiosResponse<SSOToken>} SSOTokenFromTokenResponse
     */
    /**
     * @param {string} token
     * @returns {Promise<SSOTokenFromTokenResponse>}
     */
    createSSOTokenFromToken: function createSSOTokenFromToken(token) {
      return _endpoints.appcenter.post("/authentication/sso/from/token/".concat(token));
    },
    /**
     * @typedef {import("axios").AxiosResponse<{
     *     token: string;
     *     user: User;
     * }>} SSOUserRecoveryResponse
     */
    /**
     * @param {string} ssoToken
     * @returns {Promise<SSOUserRecoveryResponse>}
     */
    getUserFromSSOToken: function getUserFromSSOToken(ssoToken) {
      return _endpoints.appcenter.post("/authentication/sso/".concat(ssoToken));
    },
    /**
     * @typedef {import("axios").AxiosResponse<boolean>} PasswordRecoveryResponse
     */
    /**
     * @param {string} user - It can be a CPF, an email or a CNPJ
     * @returns {Promise<PasswordRecoveryResponse>}
     */
    recoverPassword: function recoverPassword(user) {
      var app = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'collectame';
      return _endpoints.appcenter.post('/authentication/recover/password', {
        user: user,
        app: app
      });
    },
    /**
     * @typedef {import("axios").AxiosResponse<boolean>} PasswordChangeResponse
     */
    /**
     * @param {string} user - It can be a CPF, an email or a CNPJ
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise<PasswordChangeResponse>}
     */
    changePassword: function changePassword(user, oldPassword, newPassword) {
      return _endpoints.appcenter.post('/authentication/user/save-password', {
        user: user,
        oldPassword: oldPassword,
        password: newPassword
      });
    },
    /**
     * @param {string} recoveryToken
     * @param {string} newPassword
     * @returns {Promise<PasswordChangeResponse>}
     */
    changePasswordWithRecoveryToken: function changePasswordWithRecoveryToken(recoveryToken, newPassword) {
      return _endpoints.appcenter.post('/authentication/user/save-password', {
        recoveryToken: recoveryToken,
        password: newPassword
      });
    },
    twoFa: {
      /**
       * @typedef {import("axios").AxiosResponse<string>} TwoFaCodeResponse
       */
      /**
       * @param {{ uid: string }} user
       * @returns {Promise<TwoFaCodeResponse>}
       */
      generateCode: function generateCode(user) {
        return _endpoints.appcenter.post('/authentication/2fa/code', {
          user: user
        });
      },
      /**
       * @param {{ uid: string }} user
       * @param {string} code
       */
      verifyCode: function verifyCode(user, code) {
        return _endpoints.appcenter.post('/authentication/2fa/verify', {
          user: user,
          code: code
        });
      }
    }
  },
  customer: {
    /**
     * @typedef {import("axios").AxiosResponse<boolean>} CustomerExistsResponse
     */
    /**
     * @param {string} identificationNumber - It can be a CPF, an email or a CNPJ
     * @returns {Promise<CustomerExistsResponse>}
     */
    exists: function exists(identificationNumber) {
      return _endpoints.appcenter.post('/customer/exists', {
        identificationNumber: identificationNumber
      });
    },
    /** @param {Customer} user */
    create: function create(user) {
      var _user$phone$match$, _user$phone$match, _user$phone$match$2, _user$phone$match2;
      var docType = user.type === 'autonomous' ? 'cpf' : 'cnpj';
      var name = "".concat(user.firstName, " ").concat(user.lastName);
      var code = "".concat(user.company.nome.split(' ').filter(function (word) {
        return !!word.replace(/[^a-zA-Z]+/g, '');
      }).map(function (word) {
        return word[0];
      }).join(''), "-").concat(Math.ceil(Math.random() * 1000000000).toString(24));
      var address = {
        postalCode: user.cep,
        street: user.local.logradouro,
        streetNumber: '',
        complementary: '',
        district: user.local.bairro,
        city: user.local.cidade,
        state: user.local.estado,
        country: 'Brasil'
      };
      var company = {
        uid: (0, _functions.customSizeUid)(),
        code: code,
        isSelfEmployed: user.type === 'autonomous',
        identificationNumber: user.identificationNumber,
        name: user.company.nome || name,
        description: '',
        fantasyName: user.company.fantasia || name,
        primaryPhone: user.phone,
        secondaryPhone: '',
        primaryEmail: user.email,
        secondaryEmail: '',
        website: '',
        paymentOk: false,
        demoMode: '',
        owner: '',
        contacts: [{
          type: 'finances',
          name: name,
          email: user.email,
          phoneNumber: user.phone,
          address: address,
          website: ''
        }],
        address: address,
        billing: {},
        parent: '',
        groups: [],
        apps: []
      };
      var owner = {
        uid: (0, _functions.customSizeUid)(),
        superadmin: false,
        companyadmin: true,
        user: user.email,
        taxDocument: user.identificationNumber,
        name: name,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneCountry: (_user$phone$match$ = (_user$phone$match = user.phone.match(/^\+(?:\d{1,3})(?=[\s-/)(])/)) === null || _user$phone$match === void 0 ? void 0 : _user$phone$match[0]) !== null && _user$phone$match$ !== void 0 ? _user$phone$match$ : '55',
        phoneArea: (_user$phone$match$2 = (_user$phone$match2 = user.phone.match(/\((\d{2,3}?)\)/)) === null || _user$phone$match2 === void 0 ? void 0 : _user$phone$match2[1]) !== null && _user$phone$match$2 !== void 0 ? _user$phone$match$2 : '',
        phone: user.phone.split(/[\s)]+/).pop().replace(/\D/g, ''),
        email: user.email,
        provider: 'email',
        emailVerified: false,
        addresses: [{
          postalCode: user.cep,
          street: user.local.logradouro,
          streetNumber: '',
          complementary: '',
          district: user.local.bairro,
          city: user.local.cidade,
          state: user.local.estado,
          country: 'Brasil'
        }],
        companies: [],
        groups: [],
        birthdate: '',
        password: '',
        salt: (0, _functions.customSizeUid)().replace(/\//, '')
      };
      console.log('phone ==>', owner.phone);
      console.log('Area ==>', owner.phoneArea);
      console.log('Country ==>', owner.phoneCountry);
      var customer = {
        documents: [{
          type: docType,
          number: user.identificationNumber
        }],
        type: user.type === 'company' ? 'corporation' : 'individual',
        name: name,
        street: user.local.logradouro,
        complementary: '',
        street_number: '',
        neighborhood: user.local.bairro,
        city: user.local.cidade,
        state: user.local.estado,
        zipcode: user.cep,
        country: 'br',
        phone_numbers: [user.phone],
        external_id: company.uid
      };
      var group = {
        uid: (0, _functions.customSizeUid)(),
        name: 'Administração Geral',
        description: 'Grupo com permissões máximas nesta empresa',
        tag: [],
        permissions: [],
        company: company.uid,
        members: [owner.uid]
      };
      owner.groups.push(group.uid);
      owner.companies.push(company.uid);
      company.groups.push(group.uid);
      company.owner = owner.uid;
      var subscription = {
        customer: customer,
        company: company,
        owner: owner,
        group: group
      };
      return _endpoints.appcenter.post('/customer/create', {
        subscription: subscription
      });
    }
  },
  company: {
    users: {
      /**
       * @param {string} company
       * @param {typeof defaultUser} user
       */
      save: function save(company, user) {
        return _endpoints.appcenter.post("/company/".concat(company, "/users/save"), {
          user: user
        }, {
          headers: {
            'system-token': _endpoints.SYSTEM_TOKEN
          }
        });
      },
      /**
       * @param {string} company
       * @param {typeof defaultUser | typeof defaultUser[]} users
       */
      disableUsers: function disableUsers(company, users) {
        return _endpoints.appcenter.post("/company/".concat(company, "/users/disable"), {
          users: users
        });
      },
      /**
       * @param {string} company
       * @param {typeof defaultUser | typeof defaultUser[] } users
       */
      enableUsers: function enableUsers(company, users) {
        return _endpoints.appcenter.post("/company/".concat(company, "/users/enable"), {
          users: users
        });
      }
    }
  },
  user: {
    /**
     * @typedef {import("axios").AxiosResponse<boolean>} UserExistsResponse
     */
    /**
     * @param {string} user - It can be a CPF, an email or a CNPJ
     * @returns {Promise<UserExistsResponse>}
     */
    exists: function exists(user) {
      return _endpoints.appcenter.post('/user/exists', {
        user: user
      });
    },
    /**
     * @typedef {import("axios").AxiosResponse<any>} UserResponse
     */

    /**
     * @param {string} userUid - User uid
     * @returns {Promise<UserResponse>}
     */
    get: function get(userUid) {
      return _endpoints.appcenter.get("/user/".concat(userUid));
    },
    /**
     * @param {string} email - User email
     * @returns {Promise<UserResponse>}
     */
    getByEmail: function getByEmail(email) {
      return _endpoints.appcenter.post('/user/get-by-email', {
        user: email
      });
    }
  }
};
var _default = appcenterApi;
exports["default"] = _default;