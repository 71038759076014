"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bodyEvents = void 0;
var bodyEventTypes = ['mousedown', 'mouseup', 'mousemove', 'mouseover', 'mouseout', 'mouseleave'];
var bodyEventsStore = {};
var bodyEvents = {
  on: {},
  once: {}
};
exports.bodyEvents = bodyEvents;
Object.values(bodyEventTypes).map(function (type) {
  bodyEventsStore[type] = [];
  bodyEvents.on[type] = function (callback) {
    bodyEventsStore[type].push({
      callback: callback,
      once: false
    });
  };
  bodyEvents.once[type] = function (callback) {
    bodyEventsStore[type].push({
      callback: callback,
      once: true,
      finished: false
    });
  };
  document.body.addEventListener(type, function (e) {
    bodyEventsStore[type].map(function (execution) {
      if (execution.finished) return;
      execution.callback(e);
      if (execution.once) {
        execution.finished = true;
      }
    });
    bodyEventsStore[type].slice().reverse().map(function (execution, i) {
      if (execution.finished) {
        bodyEventsStore[type].splice(i, 1);
      }
    });
  });
});