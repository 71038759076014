import { serviceExecutionFlow } from "@services/executionFlow";

const LIST_COMPANY_EXECUTION_FLOWS = "LIST_COMPANY_EXECUTION_FLOWS";
const LIST_MORE_COMPANY_EXECUTION_FLOWS = "LIST_MORE_COMPANY_EXECUTION_FLOWS";
const COMPANY_EXECUTION_FLOWS_CHANGE = "COMPANY_EXECUTION_FLOWS_CHANGE";
const ADD_EXECUTION_FLOWS = "ADD_EXECUTION_FLOWS";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * @typedef Params
 * @type {import("./index.js").Params}
 */

/**
 * @typedef ListType
 * @type {import("@services/executionFlow/listByType.js").ListType}
 */

const service = (dispatch) => ({
    // /** @param {Params} params */
    // list: async ({ company, user, group, lastDoc, limit, userGroups, companyAreaUid }) => {
    //     const result = await serviceExecutionFlow.list({
    //         companyUid: company,
    //         userUid: user,
    //         groupUid: group,
    //         lastDocUid: lastDoc,
    //         limit,
    //         userGroups,
    //         companyAreaUid
    //     });
    //     if (typeof result === "string") return;

    //     const list = result.sort((a, b) => {
    //         const aTime = a.createdAt.getTime();
    //         const bTime = b.createdAt.getTime();
    //         return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
    //     });

    //     serviceExecutionFlow.watch({
    //         list,
    //         callback: (executionFlowList) => {
    //             dispatch({
    //                 type: COMPANY_EXECUTION_FLOWS_CHANGE,
    //                 payload: { list: executionFlowList },
    //             });
    //         },
    //     });

    //     dispatch({
    //         type: LIST_COMPANY_EXECUTION_FLOWS,
    //         payload: { company, list },
    //     });
    // },

    /**
     * @param {Params & {
     *    type?: ListType;
     *    companyAreaUid: string ;
     *    typeWorkflow?: any
     * }} params
     */
    listMore: async ({ company, user, group, type, lastDoc, limit, companyAreaUid, userGroups, typeWorkflow }) => {
        const result = await serviceExecutionFlow.list({
            companyUid: company,
            userUid: user,
            groupUid: group,
            type,
            lastDocUid: lastDoc,
            limit,
            companyAreaUid,
            userGroups,
            typeWorkflow
        });
        if (typeof result === "string") return;

        const list = result.sort((a, b) => {
            const aTime = a.createdAt.getTime();
            const bTime = b.createdAt.getTime();
            return aTime < bTime ? 1 : aTime > bTime ? -1 : 0;
        });

        serviceExecutionFlow.watch({
            list,
            callback: (executionFlowList) => {
                dispatch({
                    type: COMPANY_EXECUTION_FLOWS_CHANGE,
                    payload: { list: executionFlowList },
                });
            },
        });

        dispatch({
            type: LIST_MORE_COMPANY_EXECUTION_FLOWS,
            payload: { company, list },
        });
    },

    /** @param {ExecutionFlow[]} executionFlows */
    addToList: (executionFlows) => {
        dispatch({
            type: ADD_EXECUTION_FLOWS,
            payload: { list: executionFlows },
        });
    },
});

const INIT_STATE = {
    company: "",
    list: [],
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_COMPANY_EXECUTION_FLOWS: {
            return {
                ...state,
                company: action.payload.company,
                list: action.payload.list,
            };
        }
        case ADD_EXECUTION_FLOWS:
        case LIST_MORE_COMPANY_EXECUTION_FLOWS: {
            const allFlows = [...state.list, ...action.payload.list];

            return {
                ...state,
                company: action.payload.company,
                list: allFlows.reduce((list, flow) => {
                    const alreadyAdded = !!list.find(
                        (item) => item.uid === flow.uid
                    );
                    if (alreadyAdded) return list;
                    list.push(flow);
                    return list;
                }, []),
            };
        }
        case COMPANY_EXECUTION_FLOWS_CHANGE: {
            return {
                ...state,
                list: state.list.map((flow) => {
                    const updated = action.payload.list.find(
                        (item) => item.uid === flow.uid
                    );

                    return updated || flow;
                }),
            };
        }
        default:
            return { ...state };
    }
};

export default { reducer, service };
