import { executionFlowCollection } from "@firebase-config/collections";
import { createExecutionFlow } from "@models";

/**
 * @typedef ExecutionFlow
 * @type {import("@models/executionFlow").ExecutionFlow}
 */

/**
 * @typedef ListType
 * @type {'responsible' | 'creator' | 'participate' | 'archived' | 'iCanSee'}
 */

const possiblesStatus = ["completed", "not-completed", "canceled", "archived"];

/**
 * Function to list all executionFlow of company
 * @param {{
 *      status?: 'completed' | 'not-completed' | 'canceled' | 'archived';
 *      companyUid: string;
 *      userUid?: string;
 *      groupUid?: string;
 *      type?: ListType;
 *      lastDocUid?: string;
 *      limit?: number; 
 *      groupsParticipant?: string[]
 *      userGroups?: any
 *      companyAreaUid: string,
 *      typeWorkflow?: string
 * }} params
 * @returns {Promise<ExecutionFlow[] | string>}
 */
export async function listExecutionFlow({
    companyUid,
    userUid,
    groupUid,
    status,
    type,
    lastDocUid,
    limit = 20,
    userGroups,
    companyAreaUid,
    typeWorkflow
}) {

    if (!companyUid || typeof companyUid !== "string") {
        return "invalid-param-companyUid";
    }
    if (groupUid && typeof groupUid !== "string") {
        return "invalid-param-groupUid";
    }
    if (status && !possiblesStatus.includes(status)) {
        return "invalid-param-status";
    }

    let query = executionFlowCollection
        .where("company", "==", companyUid)
        .where("deleted", "==", false)
        .where("workflowObject.division", "==", companyAreaUid)
        .where("workflowObject.security", "==", "public")
        .orderBy("createdAt", "desc")
        .limit(limit);

    let queryGroupResponsible = query

    try {

        if (typeWorkflow) {
            queryGroupResponsible = queryGroupResponsible.where("workflow", "==", typeWorkflow)
            query = query.where("workflow", "==", typeWorkflow)
        }

        switch (type) {

            case "iCanSee":
                query = query
                break;

            case "responsible":
                query = query.where("agent", "==", userUid);
                queryGroupResponsible = queryGroupResponsible.where("agentObject.groups", "array-contains-any", userGroups.map((group) => group.uid));
                break;

            case "creator":
                query = query.where("createdBy", "==", userUid);
                break;

            case "participate":
                query = query.where("participants", "array-contains", userUid);
                break;

            case "archived":
                query = query.where("archived", "==", false);
                break;

            default:

                break;
        }

        if (lastDocUid) {
            const doc = await executionFlowCollection.doc(lastDocUid).get();
            query = query.startAfter(doc);
            queryGroupResponsible = queryGroupResponsible.startAfter(doc)
        }

        const { docs } = await query.get();

        let allListExecutionFlow;

        if (type === "responsible") {
            const docsResponsible = await queryGroupResponsible.get()

            allListExecutionFlow = [...docs.map((doc) => doc.data()), ...docsResponsible.docs.map(doc => doc.data())]
        } else {
            allListExecutionFlow = docs.map((doc) => doc.data())
        }

        return allListExecutionFlow.map(createExecutionFlow);
    } catch (error) {
        return "error-list-executionFlow";
    }
}
