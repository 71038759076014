import { findTask } from "./find";
import { listTasks } from "./list";
import { searchTasks } from "./search";
import { watchTasks } from "./watch";

export const serviceTask = {
    find: findTask,
    list: listTasks,
    watch: watchTasks,
    search: searchTasks,
};
