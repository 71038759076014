"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _formatBytes = _interopRequireDefault(require("./formatBytes"));
/**
 * @param {License} license
 * @returns {string}
 */
function displayLicenseAmount(license) {
  if (license.amount === 'unlimited') license.amount = 'Ilimitado';
  if (['monthly-bandwidth', 'storage'].includes(license.type)) {
    if (!license.unit) license.unit = 'bytes';
    var used = (0, _formatBytes["default"])(license.used, license.unit);
    var amount = typeof license.amount !== 'number' ? license.amount : (0, _formatBytes["default"])(license.amount, license.unit);
    return "".concat(used, "/").concat(amount);
  } else if (license.singleUse) {
    return 'Habilitado';
  } else {
    return "".concat(license.used, "/").concat(license.amount);
  }
}
var _default = displayLicenseAmount;
exports["default"] = _default;