import React from "react";
import { Route } from "react-router";

import { useStore } from "@hooks";
import ProtectedComponent from "@custom/ProtectedComponent";

import styles from "./styles.module.scss";

// @ts-ignore
const RouteWrapper = (props) => <Route {...props} />;

/**
 * @typedef RouteProps
 * @type {import("../@constants/routes").RouteProps}
 */

/**
 * @param {Omit<RouteProps, "key"> & { routeKey: string }} props
 * @returns {JSX.Element}
 */
function CustomRoute({
    routeKey: key,
    Component,
    path,
    unprotected,
    adminOnly,
    licenses,
    testStage,
    taugorOnly,
}) {
    const { auth } = useStore();

    return (
        <RouteWrapper
            exact={true}
            key={key}
            path={path}
            render={(props) => (
                <div
                    className={styles.CustomRoute}
                    data-route-key={key}
                    data-route-path={path}
                    data-logged-in={!!auth.isAuthenticated}
                >
                    <ProtectedComponent
                        {...props}
                        Screen={Component}
                        isProtected={!unprotected}
                        adminOnly={adminOnly}
                        licenses={licenses}
                        testStage={testStage}
                        taugorOnly={taugorOnly}
                    />
                </div>
            )}
        />
    );
}

export default CustomRoute;
