import { toDate } from "@functions";
import { createWorkflow } from "./workflow";

const taskModel = {
    uid: "",
    /** @type {import("./executionFlow").Status} */
    status: "not-completed",
    company: "",
    currentPhase: "",
    action: {
        agent: "",
        agentEmail: "",
        agentName: "",
        isMultiple: false,
        /**
         * @type {{
         *     uid: string;
         *     agent: string;
         *     executionFlow: string;
         *     company: string;
         *     phase: string;
         *     workflow: string;
         *     approvalReason: string;
         *     approvedBy: string;
         *     approvedAt: Date;
         *     reprovalReason: string;
         *     reprovedAt: Date;
         *     reprovedBy: string;
         *     deleted: boolean;
         *     createdAt: Date;
         *     createdBy: string;
         * } | undefined}
         */
        executedApproval: undefined,
        /** @type {string | undefined} */
        formFields: undefined,
    },
    agent: "",
    executionFlow: "",
    /** @type {string[]} */
    participants: [],
    workflow: "",
    /** @type {import("./workflow").Workflow} */
    workflowObject: createWorkflow(),
    phase: "",
    deleted: false,
    createdAt: new Date(),
    createdBy: "",
};

/**
 * @typedef Task
 * @type {typeof taskModel}
 */

function createTask(data) {
    if (!data) data = {};

    if (!data.action) {
        data.action = {};
    }

    return {
        ...taskModel,
        ...data,
        action: {
            ...taskModel.action,
            ...data.action,
            executedApproval: data.action.executedApproval
                ? {
                      ...data.action.executedApproval,
                      approvedAt: toDate(
                          data.action.executedApproval.approvedAt
                      ),
                      reprovedAt: toDate(
                          data.action.executedApproval.reprovedAt
                      ),
                      createdAt: toDate(data.action.executedApproval.createdAt),
                  }
                : undefined,
        },
        deleted: !!data.deleted,
        createdAt: toDate(data.createdAt),
    };
}

export { taskModel, createTask };
