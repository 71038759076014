"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  appcenterApi: true,
  cepApi: true,
  receitaApi: true,
  dokdriveApi: true,
  signatureMsApi: true
};
Object.defineProperty(exports, "appcenterApi", {
  enumerable: true,
  get: function get() {
    return _appcenterApi["default"];
  }
});
Object.defineProperty(exports, "cepApi", {
  enumerable: true,
  get: function get() {
    return _cepApi["default"];
  }
});
Object.defineProperty(exports, "dokdriveApi", {
  enumerable: true,
  get: function get() {
    return _dokdriveApi["default"];
  }
});
Object.defineProperty(exports, "receitaApi", {
  enumerable: true,
  get: function get() {
    return _receitaApi["default"];
  }
});
Object.defineProperty(exports, "signatureMsApi", {
  enumerable: true,
  get: function get() {
    return _signatureMsApi["default"];
  }
});
var _appcenterApi = _interopRequireDefault(require("./appcenterApi"));
var _cepApi = _interopRequireDefault(require("./cepApi"));
var _receitaApi = _interopRequireDefault(require("./receitaApi"));
var _dokdriveApi = _interopRequireDefault(require("./dokdriveApi"));
var _signatureMsApi = _interopRequireDefault(require("./signatureMsApi"));
var _endpoints = require("./endpoints");
Object.keys(_endpoints).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _endpoints[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _endpoints[key];
    }
  });
});