import { createExecutedDocument } from "@models";
import { executedDocumentCollection } from "@firebase-config/collections";

/**
 * @typedef ExecutedDocument
 * @type {import("@models/executedDocument").ExecutedDocument}
 */

/**
 * Function to find executedDocument by uid
 * @param {{
 *      executedDocumentUid: string;
 * }} params
 * @returns {Promise<ExecutedDocument | string>}
 */
export async function findExecutedDocument({ executedDocumentUid }) {
    if (!executedDocumentUid || typeof executedDocumentUid !== "string") {
        return "invalid-param-executedDocumentUid";
    }

    try {
        const doc = await executedDocumentCollection
            .doc(executedDocumentUid)
            .get();
        const data = doc.data();

        if (!data?.uid) return "not-found-executionFlow";

        return createExecutedDocument(data);
    } catch (error) {
        console.log("ERROR in findExecutedDocument: >=>", error);
        return "error-find-executedDocument";
    }
}
