"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
/**
 * @param {() => void=} callback
 * @returns {void}
 */
function useUnmount() {
  var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
    console.log("=");
  };
  var callbackRef = (0, _react.useRef)(callback);
  callbackRef.current = callback;
  (0, _react.useEffect)(function () {
    return function () {
      return callbackRef.current();
    };
  }, []);
}
var _default = useUnmount;
exports["default"] = _default;