const RESET_STORE = "RESET_STORE_REDUX";

const service = (dispatch) => ({
    resetState: () => {
        dispatch({
            type: RESET_STORE,
            show: true,
        });
    },
});

export default { service };
