"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _app["default"];
  }
});
var _app = _interopRequireDefault(require("firebase/app"));
require("firebase/storage");
require("firebase/firestore");
require("firebase/auth");
var firebaseConfig = {
  apiKey: 'AIzaSyAcJhDYBFaK2sFeHn85pZWWZWjN630iWL0',
  authDomain: 'bpmn-taugor.firebaseapp.com',
  databaseURL: 'https://bpmn-taugor.firebaseio.com',
  projectId: 'bpmn-taugor',
  storageBucket: 'bpmn-taugor.appspot.com',
  messagingSenderId: '722116564677',
  appId: '1:722116564677:web:0da5519b8989d5811302ac',
  measurementId: 'G-5W5GZQYM6K'
};
var app = _app["default"].initializeApp(firebaseConfig, 'workflux');
var _default = app;
exports["default"] = _default;