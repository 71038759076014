const CHANGE_THEME = "CHANGE_THEME";
const service = (dispatch) => ({

  changeTheme: (theme) => {
    dispatch({
        type: CHANGE_THEME,
        payload: { theme: theme },
    })
},
});

const INIT_STATE = {
  theme: "light"
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return { ...state, theme: action.payload.theme }
    default:
      return { ...state };
  }
};

export default { reducer, service };
