import React, { forwardRef } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from "@mui/material";

import i18n from "@i18n";
import { services } from "@store";

import styles from "./styles.module.scss";

/**
 * @typedef TransitionProps
 * @type {import("@mui/material/transitions").TransitionProps}
 */

const Transition = forwardRef(
    /**
     * @param {TransitionProps & {
     *     children: React.ReactElement<any, any>;
     * }} props
     * @param {React.Ref<unknown>} ref
     */
    function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    }
);

/**
 * @typedef ButtonProps
 * @type {import("@mui/material/Button").ButtonProps}
 */

/**
 * @typedef MessageProps
 * @type {{
 *     visible: boolean;
 *     title?: React.ReactNode;
 *     description?: React.ReactNode;
 *     buttons?: ButtonProps[];
 *     hide?: () => void;
 *     variant?: "success" | "error" | "warning" | "info";
 * }}
 */

/** @param {MessageProps} props */
function Message({ visible, title, description, buttons = [], hide, variant }) {
    const handleHide = () => {
        if (!hide) {
            services.message.hide();
        } else {
            hide();
        }
    };

    return (
        <Dialog
            keepMounted
            open={visible}
            TransitionComponent={Transition}
            onClose={handleHide}
            PaperProps={{
                className: styles.Message,
                "data-variant": variant,
            }}
            sx={{
                zIndex: 9999
            }}
        >
            {title && (
                <DialogTitle>
                    {typeof title === "string" ? i18n(title) : title}
                </DialogTitle>
            )}
            <DialogContent>
                {description && (
                    <DialogContentText>
                        {typeof description === "string"
                            ? i18n(description)
                            : description}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                {buttons.map((buttonProps) => (
                    <Button {...buttonProps} />
                ))}
            </DialogActions>
        </Dialog>
    );
}

export default Message;
